import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from 'reactstrap';
import './style.scss';
const errorMessages = {
    PLAN_EXPIRED: "Your plan has expired. Please renew your plan to continue.",
    NO_ACTIVE_PLAN: "You currently have no active plan. Please select a plan to activate.",
    CREDITS_EXPIRED: "Your credits have expired. Please recharge to gain access.",
    CREDITS_EXHAUSTED: "Your credits are exhausted. Please recharge or contact support.",
    INSUFFICIENT_CREDITS: "You have insufficient credits to complete this action. Please recharge."
};

const errorHeaders = {
    PLAN_EXPIRED: "Plan Expired",
    NO_ACTIVE_PLAN: "No Active Plan",
    CREDITS_EXPIRED: "Credits Expired",
    CREDITS_EXHAUSTED: "Credits Exhausted",
    INSUFFICIENT_CREDITS: "Insufficient Credits"
};

const showExplorePlansButton = ["PLAN_EXPIRED", "NO_ACTIVE_PLAN", "CREDITS_EXPIRED", "CREDITS_EXHAUSTED", "INSUFFICIENT_CREDITS"];

export const CreditPopup = ({ isOpen, toggle, message }) => {
    const navigate = useNavigate();
    const headerText = errorHeaders[message] || "Alert";
    const bodyText = errorMessages[message] || "An error occurred. Please contact support.";
    const shouldShowExploreButton = showExplorePlansButton.includes(message);

    if (!errorMessages[message]) {
        return null; // Do not render the modal if the error code is unrecognized
    }


    return (
        <Modal isOpen={isOpen} toggle={toggle} clasname="credit-modal">
            <ModalHeader style={{ justifyContent: 'center' }}>{headerText}</ModalHeader>
            <ModalBody style={{ textAlign: 'center', fontSize: '20px', color: 'black' }}>
                {bodyText}
            </ModalBody>
            <ModalFooter>
                <div>
                    {shouldShowExploreButton && (
                        <Button color="primary" onClick={() => {
                            navigate('/products-and-plans');
                            toggle();
                        }}
                            style={{ marginRight: '5px' }}
                        >
                            Explore Plans
                        </Button>
                    )}

                    <Button color="primary" onClick={toggle}>
                        Close
                    </Button>
                </div>

            </ModalFooter>
        </Modal>
    );
};