import { useState, useEffect } from "react";
import "./index.scss";
import React from 'react';
import { Table, Button, UncontrolledTooltip } from 'reactstrap';
import { PieChart, Download, ThumbsDown, ThumbsUp } from "react-feather";
import CodeEditorPopup from "../CodeEditor";
import { postUserFeedbackHandler } from "../../../services/dbConnect";
import { ClipLoader } from "react-spinners";

const WizBotQueryResponse = ({ response, session, handleSQLMessage }) => {
  const message = response;

  const [feedbackMessage, setFeedbackMessage] = useState('Was this useful?');
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [rSelected, setRSelected] = useState(null);
  const [visible, setVisible] = useState(true);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  // State variables to store extracted paragraph and table data
  const [paragraphData, setParagraphData] = useState("");
  const [tableData, setTableData] = useState({ headers: [], rows: [] });
  const [isTable, setIsTable] = useState(false);

  // Feedback Button handler
  const handleButtonClick = (response) => {
    setRSelected(response);
    setFeedbackMessage(response === 1 ? 'Thank you for your feedback!' : 'We appreciate your feedback!');
    const payload = { session, feedback: response === 1 ? "True" : "False" };
    setFeedbackLoader(true)
    postUserFeedbackHandler(payload)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      });
    setTimeout(() => setVisible(false), 2000);
  };

  useEffect(() => {
    if (rSelected !== null) {
      console.log(`User response: ${rSelected === 1 ? 'Useful' : 'Not useful'}`);
    }
  }, [rSelected]);

  // Parse the markdown table into structured data
  const parseMarkdownTable = (message) => {
    const tableRegex = /\|(.+?)\|/g;
    const rows = message.match(tableRegex);
    if (rows) {
      const headers = rows[0].split('|').map((header) => header.trim()).filter(Boolean);
      const dataRows = rows.slice(1).map((row) =>
        row.split('|').map((cell) => cell.trim()).filter(Boolean)
      );
      return { headers, dataRows };
    }
    return { headers: [], dataRows: [] };
  };

  // Convert markdown message to HTML and separate table and paragraph data
  const extractDataFromMarkdown = (src) => {
    const tableRegex = /\n(( *\|.*?\| *\n)+)/g;
    const paragraphsRegex = /(?=^|>|\n)\s*\n+([^<]+?)\n+\s*(?=\n|<|$)/g;

    let tableMatch = src.match(tableRegex);
    let paragraphMatches = src.match(paragraphsRegex);

    // Handle table data
    if (tableMatch) {
      const { headers, dataRows } = parseMarkdownTable(tableMatch[0]);
      setTableData({ headers, rows: dataRows });
      setIsTable(true);
    } else {
      setIsTable(false);
    }

    // Handle paragraph data
    if (paragraphMatches) {
      setParagraphData(paragraphMatches.join('\n').trim());
    }
  };
  // Run extraction on message update
  useEffect(() => {
    if (message) {
      extractDataFromMarkdown(message);
    }
  }, [message]);


  const closePopup = () => {
    setPopupOpen(false);
    setPopupData(null);
  };

  //Function for csv download
  const handleCSVDownload = () => {
    const { headers, rows } = tableData;

    if (rows.length === 0 || headers.length === 0) {
      return;
    }

    // Convert the headers and rows into CSV format
    const csvContent = [
      headers.join(','), // Join headers with commas
      ...rows.map(row => row.join(',')) // Join each row with commas
    ].join('\n'); // New line after each row

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a download link and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'tableData.csv');
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  // Render the table
  const renderTable = () => {
    const { headers, rows } = tableData;

    if (rows.length === 0 || headers.length === 0) return null;

    return (
      <>
        <div style={{ textAlign: 'end', fontSize: '14px' }}>
          {rows.length} rows returned / {headers.length} columns
        </div>
        <div style={{ maxHeight: '400px', overflowY: 'scroll', overflowX: 'auto' }}>
          <Table striped>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', whiteSpace: 'nowrap' }}>
              <tr>
                {headers.map((col, index) => (
                  <th key={index}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="response-actions">
          <div className="response-actions__cta">
            {/* <Button outline color="primary" id="view-chart">
              <PieChart />
            </Button>
            <UncontrolledTooltip
              placement='bottom'
              target='view-chart'
            >
              View Chart
            </UncontrolledTooltip> */}

            <Button outline color="primary" id="download" onClick={handleCSVDownload}>
              <Download />
            </Button>
            <UncontrolledTooltip
              placement='bottom'
              target='download'
            >
              Download as CSV
            </UncontrolledTooltip>
          </div>

          {visible && (
            <div className="response-actions__feedback">
              <span style={{ marginRight: '10px', fontSize: '12px' }}>{feedbackMessage}</span>
              {
                feedbackLoader ?
                  <span><ClipLoader size={16} color="#44ace4"/></span>
                  :
                  <>
                    <Button color="primary" outline onClick={() => handleButtonClick(1)} active={rSelected === 1}>
                      👍
                    </Button>
                    <Button color="primary" outline onClick={() => handleButtonClick(2)} active={rSelected === 2}>
                      👎
                    </Button>
                  </>
              }

            </div>
          )}
        </div>
      </>
    );
  };

  // Main render logic based on whether it's a table or plain text
  return (
    <div>
      {/* Render paragraph data */}
      {paragraphData && <div className="message">{paragraphData}</div>}

      {/* Render table if present */}
      {isTable && renderTable()}


      {isPopupOpen && (
        <CodeEditorPopup
          isOpen={isPopupOpen}
          toggle={closePopup}
          data={popupData}
          handleSQLMessage={handleSQLMessage}
        />
      )}
    </div>
  );
};

export default WizBotQueryResponse;
