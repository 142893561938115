import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import {
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import { showErrorToast, showSuccessToast, truncateStringToNPlace } from "../../../utility/helper";
import { uploadChatbotDataFile } from "../../../services/chatBot";

const WebsiteModal = ({websiteModalShow, toggle, handleUrl}) => {
    // Fetch data from proprs
    const { session } = useParams();
    // Data variable
    const [chatbot_Id, setChatbotId] = useState(null);
    const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
    const [fileList, setFileList] = useState([]);
    const [file, setFile] = useState(null);
    const [sessionId, setSessionId] = useState(session);
    const [inputUrl, setInputUrl] = useState('');

    // Loaders
    const [websiteLoader, setWebsiteLoader] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);

    const handleUrlInputChange = (event) => {
        const url = event.target.value;
        setInputUrl(url);
        const isValid = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
        setIsValidUrl(isValid);
    };

    const handleWebsiteUrlUpload = () => {
        if (!isValidUrl) {
            setIsValidUrl(false);
            showErrorToast('Invalid URL');
            return;
        }

        handleUrl(inputUrl);
        toggle();

        // if (!chatbot_Id || chatbot_Id === 'new-bot') {
        //     showErrorToast('Chatbot profile not created yet');
        //     return;
        // }
        

        // setWebsiteLoader(true);
        // const websiteName = getDomainNameFromWebsiteUrl(inputUrl);
        // const formData = new FormData();
        // formData.append('name', websiteName);
        // formData.append('chatbot', chatbot_Id);
        // formData.append('url', inputUrl);
        // formData.append('document_type', 'WEBSITE_URL');
        // formData.append('size', 0);

        // // addNewBotLoaderMessage('Please wait while I load the website data');
        // uploadChatbotDataFile(formData)
        //     .then(response => {
        //         setMessagesList(messagesList.slice(0, messagesList.length - 1));
        //         if (response.status == statusCode.HTTP_201_CREATED) {
        //             setFileList([...fileList, { ...response.data }]);
        //             showSuccessToast('File uploaded successfully');
        //             setInputUrl(null);
        //             showSuccessToast('Website data uploaded successfully');
        //             // addNewBotMessage('Website data uploaded successfully. You can now start querying on this document.');
        //         } else {
        //             showErrorToast('Error uploading website data');
        //             // addNewBotMessage('Error uploading website data. Please try again');
        //         }
        //         setWebsiteLoader(false);
        //     })
        //     .catch((error) => {
        //         setMessagesList(messagesList.slice(0, messagesList.length - 1));
        //         showErrorToast('Error uploading website data');
        //         // addNewBotMessage('Error uploading website data. Please try again');
        //         setWebsiteLoader(false);
        //     });
    }

    return (
        <Modal style={{ zIndex: '100' }} isOpen={websiteModalShow}>
            <ModalHeader>{`Enter Website URL`}</ModalHeader>
            <ModalBody>
                <Input type="text" value={inputUrl} onChange={handleUrlInputChange} />
                {!isValidUrl && <div style={{ color: 'red', marginTop: '10px' }}>Invalid URL!</div>}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleWebsiteUrlUpload}>Proceed</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )

}

export default WebsiteModal;