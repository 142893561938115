import { Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
// import {data} from './checkData'
import moment from 'moment';


// Function to format numbers into "K", "L", or "Cr"
const formatNumber = (value) => {
    if (Math.abs(value) >= 1e7) {
        return `${(value / 1e7).toFixed(2)} Cr`; // 1 Crore = 10 Million (1e7)
    } else if (Math.abs(value) >= 1e5) {
        return `${(value / 1e5).toFixed(2)} L`; // 1 Lakh = 100 Thousand (1e5)
    } else if (Math.abs(value) >= 1e3) {
        return `${(value / 1e3).toFixed(2)} K`; // 1 Thousand (1e3)
    }
    return value; // Less than 1 thousand
};

const ForecastGraph = ({ data }) => {
    // Destructure data to get company_name, daily_net_income, forecast, and simulations
    const { daily_net_income, forecast, simulations } = data.analytics;

    // Minor adjustment for the data
    // Push the last data point of daily net income in starting of forecast data by pushing it in the forecast array
    forecast.unshift({
        index: daily_net_income[daily_net_income.length - 1].date,
        forecast: daily_net_income[daily_net_income.length - 1].net_income
    });
    

    // Process the daily net income data
    const actualSeries = {
        name: 'Net Cashflow',
        data: daily_net_income.map(item => ({
            x: moment(item.date).format('YYYY-MM-DD'), // Use moment to format the date for x-axis
            y: item.net_income
        })),
        type: 'line',
        stroke: {
            dashArray: 0  // Solid line
        }
    };

    // Process the forecast data
    const forecastSeries = {
        name: 'Forecast',
        data: forecast.map(item => ({
            x: moment(item.index).format('YYYY-MM-DD'), // Use moment to format the date for x-axis
            y: item.forecast
        })),
        type: 'line',
        stroke: {
            dashArray: 5  // Dotted line
        }
    };

    // Process the simulations data
    const simulationSeries = simulations? simulations.map(simulation => ({
        name: simulation.name,
        data: simulation.forecast.map(item => ({
            x: moment(item.index).format('YYYY-MM-DD'), // Use moment to format the date for x-axis
            y: item.forecast
        })),
        type: 'line',
        stroke: {
            dashArray: 8  // Dashed line
        }
    })): []

    // Combine all series data dynamically
    const series = [actualSeries, forecastSeries, ...simulationSeries];

    // Chart options
    const options = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: true  // Show toolbar for chart interactions
            }
        },
        stroke: {
            width: [3, 3, 3], // Line widths for actual, forecast, and simulation
            curve: 'smooth',
            dashArray: [0, 5, 8], // Solid, dashed, and dotted lines
            colors: ['#008FFB', '#00E396', '#FEB019'] // Custom colors
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                format: 'dd MMM yyyy' // Date format for tooltip
            },
            y: {
                formatter: function(value) {
                    return `₹${formatNumber(value)}`; // Format y-axis values as currency
                }
            }
        },
        xaxis: {
            type: 'datetime', // X-axis will be dates
            title: {
                text: 'Date',
                style: {
                    fontSize: '14px',
                    color: '#666'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Net Cashflow', // Title for Y-axis
                style: {
                    fontSize: '14px',
                    color: '#666'
                }
            },
            labels: {
                formatter: function(value) {
                    return `₹${formatNumber(value)}`; // Format y-axis labels as currency
                }
            }
        },
        title: {
            text: `${data.company_name} - Cashflow Forecast and Simulations`,
            align: 'left',
            style: {
                fontSize: "16px",
                color: '#666'
            }
        },
        legend: {
            show: true, // Show legend to differentiate between actual, forecast, and simulations
            position: 'top', // Legend position
            horizontalAlign: 'right' // Legend alignment
        },
        fill: {
            type: 'solid'
        }
    };

    return (
        <Card>
            <CardBody>
                <div id="chart">
                    <ReactApexChart options={options} series={series} type="line" height={350} />
                </div>
            </CardBody>
        </Card>
    );
};

export default ForecastGraph;
