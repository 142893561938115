import React, { useState, useEffect } from 'react';
import { ArrowLeft } from "react-feather"
import { useNavigate } from 'react-router-dom'
import {   
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button 
} from 'reactstrap';
import './index.scss'
import { showErrorToast } from '../../../utility/helper';

const scopes = {
    "Zoho CRM": {
        "Modules": "ZohoCRM.modules.READ",
        "Settings": "ZohoCRM.settings.READ",
        "Users": "ZohoCRM.users.READ",
        "Organizations": "ZohoCRM.org.READ"
    },
    "Zoho Books": {
        "Invoices": "ZohoBooks.invoices.READ",
        "Estimates": "ZohoBooks.estimates.READ",
        "Credit Notes": "ZohoBooks.creditnotes.READ",
        "Bills": "ZohoBooks.bills.READ",
        "Vendors": "ZohoBooks.vendors.READ",
        "Contacts": "ZohoBooks.contacts.READ",
        "Items": "ZohoBooks.items.READ",
        "Account Settings": "ZohoBooks.accountsettings.READ"
    }
};

const ZohoAuth = ({onClose}) => {
    const [clientId, setClientId] = useState('1000.QE7XXKA8AHEP55JX6B2U6F6FA7P97N');
    const [alertModalShow, setAlertModalShow] = useState(true);
    const [redirectUri, setRedirectUri] = useState('https://app.insightai.in/zoho-access');
    const [state, setState] = useState('testing');
    const [scope, setScope] = useState('');

    const handleScopeChange = (event, scopeValue) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setScope(prevScope => [...prevScope, scopeValue]);
        } else {
            setScope(prevScope => prevScope.filter(item => item !== scopeValue));
        }
    };

    const prepareScope = () => {
        if (scope.length === 0) {
            showErrorToast('Please select at least one access');
            return;
        }else{
            const scopeString = scope.join(',');
            return scopeString;
        }
    }

    // Extract parameters from the URL and set up redirect
    const setupAuthUrl = () => {
        
        if(scope.length === 0){
            showErrorToast('Please select at least one access');
            return
        }
        
        if (!clientId) {
            showErrorToast('Client ID is missing');
            return;
        }

        // Redirect if client ID is fetched
        if (clientId) {
            const scopeString = prepareScope();
            const url = new URL('https://accounts.zoho.com/oauth/v2/auth');
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('client_id', clientId);
            url.searchParams.append('redirect_uri', redirectUri);
            url.searchParams.append('state', state);
            url.searchParams.append('scope', scopeString);
            url.searchParams.append('access_type', 'offline');
            console.log(url.toString());

            // Redirect to the constructed URL
            window.location.href = url.toString();
            onClose()
        }
    
    }

    const renderPromptModal = () => {
        if(alertModalShow){
            return (
                <Modal style={{ zIndex: '100' }} isOpen={alertModalShow}>
                    <ModalHeader>Please select the access</ModalHeader>
                    <ModalBody style={{paddingLeft: '20px'}}>
                        {Object.entries(scopes).map(([category, subScopes]) => (
                            <div key={category}>
                                <h4 className='custom-heading'>{category}</h4>
                                {Object.entries(subScopes).map(([subCategory, scopeValue]) => (
                                    <div key={scopeValue} className='parameter-container'>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={scope.includes(scopeValue)}
                                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                                onChange={(event) => handleScopeChange(event, scopeValue)}
                                            />
                                            {subCategory}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={setupAuthUrl} color="primary">Proceed</Button>{' '}
                        <Button onClick={onClose} color="secondary">Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    return (
        <>
            {renderPromptModal()}
        </>
    );
};

export default ZohoAuth;
