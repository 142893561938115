import Types from '../types';

const initialState = {
    modalOpen: false,
    errMessage: null
};

export default function creditReducer(state = initialState, action) {
    switch (action.type) {
        case Types.CREDIT.EXHAUST:
            return {
                ...state,
                modalOpen: true,
                errMessage: action.payload
            };
        case Types.CREDIT.CLOSE:
            return {
                ...state,
                modalOpen: false,
            };

        default:
            return state;
    }
}