// ** React Imports
import { Fragment, useState, useEffect } from 'react'

//React Toast
import toast from "react-hot-toast";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  CardTitle,
  CardHeader,
  Table
} from 'reactstrap'

// ** Demo Components
import PricingCard from '@src/views/pages/Templated/pricing/PricingCards'

// ** Third Party Components
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// ** Styles
import '@styles/base/pages/page-pricing.scss'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'
import '../style.scss'
import { async } from '@firebase/util'

//Baseurl
import { getBaseUrl } from '../../../../utility/Utils'
import { faL } from '@fortawesome/free-solid-svg-icons'
const MySwal = withReactContent(Swal)

import TransactionIcon from "../../../../assets/icons/transaction.png"

//Moment
import moment from 'moment';

//react-loader-spinner
import { Oval } from 'react-loader-spinner';

const BillingCurrentPlan = () => {
  // ** States
  const [show, setShow] = useState(false)
  const [data, setData] = useState(null)
  const [duration, setDuration] = useState('monthly');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [userPlan, setUserPlan] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [planDetails, setPlanDetails] = useState({});
  const [credits, setCredits] = useState();

  const [daysLeft, setDaysLeft] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  //loader and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);



  // useEffect(() => {
  //   axios.get('/pricing/data').then(res => {
  //     const dataArr = []

  //     Object.entries(res.data).forEach(([key, val]) => {
  //       if (key !== 'qandA') {
  //         dataArr.push(val)
  //         setData([...dataArr])
  //       }
  //     })
  //   })
  // }, [])
  localStorage.setItem('is_plan_activated', true);

  // useEffect(async () => {
  //   const paymentTransactionId = localStorage.getItem('paymentTransactionId');
  //   try {
  //     setLoading(true)
  //     const response = await axios.get(`${getBaseUrl()}billing/transaction/current_status?merchant_id=${paymentTransactionId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('token')}`
  //         }
  //       }
  //     );

  //     // console.log(response, 'response')
  //     if (response?.data?.data) {
  //       const { user_plan, payment_details, order_details, credits_available, plan_details } = response.data.data;
  //       setPaymentSuccess(true);

  //       if (user_plan) {
  //         //Progress bar calculation
  //         const today = new Date().getTime();
  //         const start = new Date(user_plan.start_date).getTime();
  //         const end = new Date(user_plan.end_date).getTime();

  //         const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  //         const remainingDays = Math.ceil((end - today) / (1000 * 60 * 60 * 24));

  //         setDaysLeft(remainingDays);
  //         setCurrentDay(totalDays - remainingDays);
  //         setProgressValue(Math.ceil(((totalDays - remainingDays) / totalDays) * 100));
  //         //userplan
  //         setUserPlan(user_plan);
  //       }

  //       if (payment_details) {
  //         setPaymentDetails(payment_details);
  //       }

  //       if (order_details) {
  //         setOrderDetails(order_details);
  //       }

  //       if (credits_available) {
  //         setCredits(credits_available);
  //         localStorage.setItem('credit_available', credits_available);
  //         localStorage.setItem('is_plan_activated', true);
  //       }

  //       if (plan_details) {
  //         setPlanDetails(plan_details);
  //       }
  //     }
  //   } catch (err) {
  //     // toast.error('No payment details found');
  //     // localStorage.setItem('credit_available', 30000);
  //     // localStorage.setItem('is_plan_activated', false);
  //     setError(err)
  //   } finally {
  //     setLoading(false)
  //   }
  // }, []);

  const totalCredits = 10000;  // Total available credits
  const usedCredits = 9000;    // Credits used

  const getCreditUsageDetails = (used, total) => {
    const percentageUsed = (used / total) * 100;
    let progressColor = 'primary';
    // if (percentageUsed >= 100) {
    //     progressColor = 'danger';
    // } else if (percentageUsed >= 80) {
    //     progressColor = 'warning';
    // }
    return {
      percentageUsed: percentageUsed.toFixed(0),
      progressColor
    };
  };
  const { percentageUsed, progressColor } = getCreditUsageDetails(usedCredits, totalCredits);


  const formatDate = (date) => {
    const getdate = new Date(date);
    const day = String(getdate.getDate()).padStart(2, '0');
    const month = String(getdate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = getdate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const onChange = e => {
    if (e.target.checked) {
      setDuration('yearly')
    } else {
      setDuration('monthly')
    }
  }

  const handleConfirmCancel = () => {
    return MySwal.fire({
      title: '',
      text: 'Are you sure you would like to cancel your subscription?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        MySwal.fire({
          icon: 'success',
          title: 'Unsubscribed!',
          text: 'Your subscription cancelled successfully.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: 'Cancelled',
          text: 'Unsubscription Cancelled!!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    })
  }
  //Loader
  if (loading) {
    return (
      <div className='loader-wrapper'>
        <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8" data-testid="oval-svg">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2" data-testid="oval-secondary-group">
              <circle stroke-opacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" stroke-width="2"></circle>
              <path d="M20 0c0-9.94-8.06-20-20-20">
                <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
              </path>
            </g>
          </g>
        </svg>

      </div>
    )
  }
  //Error
  if (error) {
    return (
      <div>
        <h1 className='h1  text-center' style={{ marginBlock: '30vh' }}>No Record exist</h1>
      </div>
    )
  }
  return (
    <Fragment>
      <div>
        <CardHeader className='border-bottom'>
          <CardTitle tag='h4'>Current Plan</CardTitle>
        </CardHeader>
        <CardBody className='my-2 py-25'>
          <Row>
            <Col md='12'>
              <div className='plans-info'>
                <Table bordered className='plans-info__detail'>
                  <tbody>
                    <tr>
                      <td><strong>Plan</strong></td>
                      <td>Silver</td>
                    </tr>
                    <tr>
                      <td><strong>Credits Used</strong></td>
                      <td>{usedCredits}</td>
                    </tr>
                    <tr>
                      <td><strong>Credits Remaining</strong></td>
                      <td>{totalCredits - usedCredits}</td>
                    </tr>
                    <tr>
                      <td colSpan='2'>
                        <Progress color={progressColor} value={percentageUsed} style={{ height: '1rem', width: '100%' }}>
                          {percentageUsed}%
                        </Progress>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {/* <div className='mb-2 pb-50'>
                <h5>Active until June 30, 2023</h5>
                <span>We will send you a notification 10 days before subscription expiration</span>
              </div> */}
              {/* <div className='mb-2 mb-md-1'>
                <h5>
                  $199 Per Month{' '}
                  <Badge color='light-primary' className='ms-50'>
                    Popular
                  </Badge>
                </h5>
                <span>Standard plan for small to medium businesses</span>
              </div> */}
            </Col>
            
            {/* <Col xs={12}>
              <Button color='primary' className='me-1 mt-1' onClick={() => setShow(true)}>
                Upgrade Plan
              </Button>
              <Button outline color='danger' className='mt-1' onClick={handleConfirmCancel}>
                Cancel Subscription
              </Button>
            </Col> */}
          </Row>
        </CardBody>
      </div>

      {/* {paymentSuccess ?
        <> */}
          {/* History */}

          <h4 className="pricing-header">Billing History</h4>
          <div className='history-wrapper'>

            <ul className='history-wrapper__list'>
              <li>
                <div className='history-wrapper__list-info'>
                  <img src={TransactionIcon} />
                  <span>Billing/Transaction Name</span>
                </div>
                <span className='history-wrapper__date'>24 Oct</span>

              </li>

              <li>
                <div className='history-wrapper__list-info'>
                  <img src={TransactionIcon} />
                  <span>Billing/Transaction Name</span>
                </div>
                <span className='history-wrapper__date'>24 Oct</span>

              </li>
            </ul>
          </div>
        {/* </>
        : ''} */}


      {/* <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-xl'>
        <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <h1 className='text-center mb-1'>Subscription Plan</h1>
          <p className='text-center mb-3'>
            All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your
            needs.
          </p>
          <div className='d-flex align-items-center justify-content-center mb-5 pb-50'>
            <h6 className='me-50 mb-0'>Monthly</h6>
            <div className='form-switch'>
              <Input id='plan-switch' type='switch' checked={duration === 'yearly'} onChange={onChange} />
            </div>
            <h6 className='ms-50 mb-0'>Annually</h6>
          </div>
          <PricingCard bordered data={data} duration={duration} />
          <div className='text-center'>
            <p>Still not convinced? Start with a 14-day FREE trial!</p>
            <Button color='primary' onClick={() => setShow(!show)}>
              Start your trial
            </Button>
          </div>
        </ModalBody>
      </Modal> */}
    </Fragment>
  )
}

export default BillingCurrentPlan
