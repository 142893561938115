import React, { useEffect, useState } from 'react';
import { Card, Image, Spinner } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import ZohoLogo from '../../../assets/logo/zoho_logo.png';
import './index.scss'; // Assuming you create a separate CSS file for styling
import { fetchCompanyDataHandler } from '../../../services/cashflowMonitoring';
import { statusCode } from '../../../utility/constants/utilObject';
const SampleZohoResponse = "https://cpbgov26nd.loclx.io/access?state=testing&code=1000.96015b0a18040d068e1b79f9bafd98ce.c405decff9d869c260218c91b3cf301d&location=in&accounts-server=https%3A%2F%2Faccounts.zoho.in"

const ZohoAccess = () => {
    const location = useLocation();
    const [loadType, setLoadType] = useState();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    // const [credentials, setCredentials] = useState(null);
    const [credentials, setCredentials] = useState(
        {access_token: "new token"}
    );

    const [clientId] = useState('1000.QE7XXKA8AHEP55JX6B2U6F6FA7P97N'); // Your Client ID
    const [client_secret] = useState('b9b676fdca45b0d5c4681afa6b76ac8f2296386caf');
    // const [redirectUri] = useState('https://app.insightai.in/zoho-access'); // Your Redirect URI
    const [redirectUri] = useState('https://grand-prawn-purely.ngrok-free.app/zoho-access'); // Your Redirect URI


    // useEffect(() => {
    //     // Zoho Data Fetch
    //     const state = queryParams.get('state');
    //     const code = queryParams.get('code');
    //     const locationValue = queryParams.get('location');
    //     const accountsServer = queryParams.get('accounts-server');


    //     if (accountsServer != null && accountsServer.includes('zoho')) {
    //         console.log('Zoho Data Fetched');
    //         setLoadType('zoho')

    //         const data = new URLSearchParams();
    //         data.append('code', code);  // The authorization code you got from the URL
    //         data.append('client_id', clientId);  // Your app's client ID
    //         data.append('client_secret', client_secret);  // Your app's client secret
    //         data.append('redirect_uri', redirectUri);  // Your app's redirect URI
    //         data.append('grant_type', 'authorization_code');

    //         fetch('https://accounts.zoho.in/oauth/v2/token', {
    //             method: 'POST',
    //             headers: {
    //               'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             body: data,
    //           })
    //             .then(response => response.json())
    //             .then(result => {
    //             //   console.log('Result:', result);
    //               setCredentials(result);
    //             })
    //             .catch(error => {
    //               console.error('Error:', error);
    //             });              
    //     }

    // }, []);

    const loadingContainer = () => {
        console.log('Zoho Working');
        return (
            <div style={{ textAlign: 'center' }}>
                <img src={ZohoLogo} alt="Zoho Logo" style={{ width: '200px', height: 'auto' }} />
                <div style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    marginTop: '30px',
                    marginBottom: '40px',
                    fontWeight: '600',

                }}>Thanks for connecting, please wait while we complete the setup. Please do not refresh the page.</div>
                <Spinner style={{ marginRight: '10px' }} color="secondary" size={"md"} type='grow' />
                <Spinner style={{ marginRight: '10px' }} color="secondary" size={"md"} type='grow' />
                <Spinner style={{ marginRight: '10px' }} color="secondary" size={"md"} type='grow' />
            </div>
        )
    }

    // const redirectToSession = () => {
    //     //need to call fetch company data API
    //     // const timeout = setTimeout(() => {
    //     //     // window.location.href = '/document-intelligence/new-session'
    //     //     window.location.href = '/cashflow-analysis/company-selection'
    //     //     clearTimeout(timeout);
    //     // }, 3000);
    // }

    console.log('Credentials', credentials);

    useEffect(() => {
        // Only call redirectToSession if credentials exist
        if (credentials && credentials.access_token) {
            // const payload = {
            //     book: loadType,
            //     access_token: credentials.access_token,
            //     refresh_token: credentials.refresh_token,
            //     scope:credentials.scope,
            //     api_domain:credentials.api_domain,
            //     token_type: credentials.token_type,
            //     expires_in: credentials.expires_in,
            // }

            const payload = {
                book: 'ZOHO',
                access_token: 'access_token',
                refresh_token: 'credentials.refresh_token',
                scope: 'credentials.scope',
                api_domain: 'credentials.api_domain',
                token_type: 'Bearer',
                expires_in: '3600',
            }

            fetchCompanyDataHandler(payload)
            .then(response =>{
                console.log("Response: ", response);
                if(response.status == statusCode.HTTP_200_OK){
                    const data = response.data
                    navigate('/cashflow-analysis/company-selection', {
                        state: { data }, 
                    });
                }
            })
            .catch(error =>{
                console.log('error', error)
            })
        }
    }, [credentials]);

    return (
        <div>
            <Card className="blogPreviewContainer">
                <div className='vh-85 padding_0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loadingContainer()}
                </div>
            </Card>
            {/* {redirectToSession()} */}
        </div>
    );
};

export default ZohoAccess;
