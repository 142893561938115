import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TYPE,
  LOCAL_STORE_KEY,
  DEVELOPMENT_TYPE
} from "./constants/localStoreKeys";
import { colors } from "./constants/colors";
import { BLOG_REDUX_ACCESS_KEY, TEMPLATE_TYPE } from "./constants/reduxKeys";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel, faTable, faFileImage, faGlobe, faFileWord } from '@fortawesome/free-solid-svg-icons'

export const renderDocumentIcon = (documentType) => {
  switch (documentType) {
      case 'PDF':
          return <span style={{ color: "#E57373" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFilePdf} /></span>
      case 'XLSX':
          return <span style={{ color: "#4DB6AC" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileExcel} /></span>
      case 'DOCX':
          return <span style={{ color: "#0091EA" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileWord} /></span>
      case 'DOC':
          return <span style={{ color: "#0091EA" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileWord} /></span>
      case 'XLS':
          return <span style={{ color: "#4DB6AC" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileExcel} /></span>
      case 'PNG':
          return <span style={{ color: "#80DEEA" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} /></span>
      case 'JPEG':
          return <span style={{ color: "#80DEEA" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} /></span>
      case 'WEBP':
          return <span style={{ color: "#80DEEA" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} /></span>
      case 'WEBSITE_URL':
          return <span style={{ color: "#90A4AE" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faGlobe} /></span>
      default:
          return <span style={{ color: "#E57373" }}><FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFilePdf} /></span>
  }
}

const monthArray = [
  "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];

export const getUserNameFromEmail = (email) => {
  return email.replaceAll("@", "").replaceAll(".", "").replaceAll("-", "");
};

export const blogTitleFormat = (title) => {
  if (!title) {
    return;
  }
  const trimmed = title.trim();
  return trimmed[0].toUpperCase() + trimmed.substr(1);
};

const ifUpperCase = (textString) => {
  return textString == textString.toUpperCase();
};

export const toTitleCase = (title) => {
  if (!title) {
    return;
  }
  const trimmed = title.trim();
  return trimmed.replace(/\w\S*/g, (txt) => {
    let stringFrom2ndChar = txt.substr(1);
    if (!ifUpperCase(txt.substr(1))) {
      stringFrom2ndChar = txt.substr(1).toLowerCase();
    }
    return txt.charAt(0).toUpperCase() + stringFrom2ndChar;
  });
};

export const editBlogTitleFormat = (title) => {
  if (!title) {
    return;
  }
  const trimmed = title;
  return trimmed[0].toUpperCase() + trimmed.substr(1);
};

export const isValidEmail = (email) => {
  return String(email).toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const scrollableDivStyle = {
  // maxHeight: '600px',     // Set a fixed height for the div
  overflowY: "auto",     // Enable vertical scrolling when content overflows
  border: "1px solid #ccc", // Optional: Add a border for visualization

  /* Optional: Style the scrollbar */
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1" // Optional: Style the scrollbar thumb and track
};

export const isValidPassword = (password) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
  // Explanation of the regex:
  // - (?=.*[A-Za-z]): At least one letter
  // - (?=.*\d): At least one digit
  // - (?=.*[@$!%*?&]): At least one special character
  // - [A-Za-z\d@$!%*?&]{7,}: Total length must be 7 or more characters

  return regex.test(password);
};

export const toastStyle = {
  duration: 700,
  success: {
    style: {
      height: "70px",
      padding: "16px",
      color: "#FFFFFF",
      background: "#66BB6A"
    },
    iconTheme: {
      primary: "#66BB6A",
      secondary: "#FFFAEE"
    }
  },
  error: {
    style: {
      height: "70px",
      padding: "16px",
      color: "#FFFFFF",
      background: "#E57373"
    },
    iconTheme: {
      primary: "#713200",
      secondary: "#FFFAEE"
    }
  }
};

export const convertJsonToHTML = (title, contentArray) => {
  let htmlString = "";
  if (title) {
    htmlString = "<h2><strong>" + toTitleCase(title) + "</strong></h2>";
  }
  htmlString += "<h6></h6>";
  if (contentArray) {
    contentArray.forEach((item, index) => {
      if (item.section) {
        htmlString += "<b><h4>" + convertToSentenceCase(item.section) + "</h4></b>" + "<div></div>";
      }
      if (typeof (item.content) == "object") {
        if (item.content) {
          item.content.forEach((contentItem, contentIndex) => {
            let formattedItemContent = contentItem.replaceAll("\n", "<br>");
            htmlString += "<div><p>" + formattedItemContent + "</p></div>" + "<h6></h6>";
          });
        }
      } else if (typeof (item.content) == "string") {
        let formattedItemContent = item.content.replaceAll("\n", "<br>");
        htmlString += "<div><p>" + formattedItemContent + "</p></div>" + "<h6></h6>";
      }
    });
  }
  return htmlString;
};

export const getWordCount = (title, contentArray) => {
  let htmlString = title;
  if (contentArray) {
    contentArray.forEach((item, index) => {
      htmlString += " " + item.section;
      if (typeof (item.content) == "object") {
        if (item.content) {
          item.content.forEach((contentItem, contentIndex) => {
            htmlString += contentItem;
          });
        }
      } else if (typeof (item.content) == "string") {
        htmlString += " " + item.content;
      }
    });
  }
  return htmlString.split(" ").length;
};

export const getWordCountForString = (content) => {
  return content.split(" ").length;
};

export const formatHTMLContentFromDraftJS = (content) => {
  return content.replaceAll("\\n", "").substr(1, content.length - 1);
};

export const formatNumbers = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const filterIdFromNativeUrl = (url) => {
  if (!url) {
    const urlPieces = window.location.href.split("/");
    return urlPieces.at(-1);
  } else {
    const urlPieces = url.split("/");
    return urlPieces.at(-1);
  }
};

export const defaultPrompt = {
  section: {
    temp: 0.7,
    fp: 1,
    tp: 1,
    pp: 0,
    max: 256,
    num: 0
  },
  section_point: {
    temp: 0.7,
    fp: 1,
    tp: 1,
    pp: 0,
    max: 256,
    num: 3
  },
  section_content: {
    temp: 0.7,
    fp: 2,
    tp: 1,
    pp: 0,
    max: 140,
    num: 0
  }
};

export const showSuccessToast = (message) => {
  toast.success(message, { ...toastStyle.success });
};

export const showErrorToast = (message) => {
  toast.error(message, { ...toastStyle.error });
};

export const readableDate = (dateObject) => {
  if (dateObject) {
    return (
      monthArray[new Date(dateObject).getMonth()] + " "
      + new Date(dateObject).getDate() + ", "
      + new Date(dateObject).getFullYear()
    );
  }
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const getTimestampInMiliSeconds = () => {
  return Math.floor(Date.now());
};

export const getReadingTimeFromCount = (number) => {
  if (!number) {
    return;
  } else if (number <= 125) {
    // return '0.4 min'
    return "< 1 min";
  } else if (number <= 250) {
    // return '0.8 min'
    return "< 1 min";
  } else if (number <= 500) {
    // return '1.7 min'
    return "< 2 min";
  } else if (number <= 600) {
    return "2 min";
  } else if (number <= 750) {
    // return '2.5 min'
    return "< 5 min";
  } else if (number <= 800) {
    // return '2.7 min'
    return "< 5 min";
  } else if (number <= 1000) {
    // return '3.3 min'
    return "< 5 min";
  } else if (number <= 1500) {
    return "5 min";
  } else if (number <= 2000) {
    // return '6.7 min'
    return "7 min";
  } else if (number <= 2500) {
    // return '8.3 min'
    return "8 min";
  } else if (number <= 3000) {
    return "10 min";
  } else if (number <= 3500) {
    // return '11.7 min'
    return "12 min";
  } else if (number <= 4000) {
    // return '13.3 min'
    return "13 min";
  }
};

export const getReadabilityGrade = (readability) => {
  if (!readability) {
    return "College";
  } else {
    switch (readability) {
      case readability <= 10:
        return "Professional";
      case readability <= 30:
        return "College graduate";
      case readability <= 50:
        return "College";
      case readability <= 60:
        return "10th to 12th grade";
      case readability <= 70:
        return "8th & 9th grade";
      case readability <= 80:
        return "7th grade";
      case readability <= 90:
        return "6th grade";
      case readability > 90:
        return "5th grade";
      default:
        return "College";
    }
  }
};

export const isAdmin = () => {
  return localStorage.getItem(LOCAL_STORE_KEY.USER_MODE) == ACCESS_TYPE.ADMIN;
};

export const isLocal = () => {
  return window.location.origin.includes('localhost');
};

export const onLogout = () => {
  // Remove Auth Details
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
  localStorage.removeItem("email");
  localStorage.removeItem("refresh");
  localStorage.removeItem("image_url");
  localStorage.removeItem("company_id");
  localStorage.removeItem("company_name");
  localStorage.removeItem("plan");
  localStorage.removeItem("role");
  localStorage.removeItem("logo");
  localStorage.removeItem("is_email_verified");
  localStorage.removeItem("is_password_set");
  localStorage.removeItem("credit_available");
  localStorage.removeItem("status");
  localStorage.removeItem("plan");
  localStorage.removeItem("role");
  localStorage.removeItem("is_email_verified");
  localStorage.removeItem("is_password_set");
  localStorage.removeItem("credit_available");
  localStorage.removeItem('shopify');
  localStorage.removeItem('bot_config');
  localStorage.removeItem('bot_id');
  localStorage.removeItem('dashboard');
  localStorage.removeItem('pricing');
};

export const getCurrentDate = () => {
  return new Date().toLocaleString('en-US', {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const clearGeneratorLocalStorage = () => {
  // Remove Auth Details
  localStorage.removeItem("generator_page");
  localStorage.removeItem("blog_overview");
  localStorage.removeItem("blog_section_points");
  localStorage.removeItem("blog_section");
  localStorage.removeItem("blog_title");
  localStorage.removeItem("formatting_state");
  localStorage.removeItem("content_type");
  localStorage.removeItem("blog_section_content_prompt");
  localStorage.removeItem("blog_section_points_prompt");

  // Remove review items
  localStorage.removeItem("preview_id");
  localStorage.removeItem("preview_content");
  localStorage.removeItem("formatting_state");
  localStorage.removeItem("preview_title");
  localStorage.removeItem("preview_image");
};

export const formatKeywordsInput = (keyword) => {
  keyword = keyword.trim();
  if (keyword[keyword.length - 1] == ",") {
    keyword = keyword.substring(0, keyword.length - 1);
  }
  return keyword;
};

export const convertToSentenceCase = (baseString) => {
  return baseString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function(c) {
    return c.toUpperCase();
  });
};

export const truncateStringToNPlace = (textString, n) => {
  if (textString) {
    if (!n) {
      n = 10;
    }
    if (textString.length < n) {
      return textString;
    } else {
      return textString.substring(0, n) + "..";
    }
  }
};

export const loadContentToGenerationPage = (documentObject, documentId) => {
  localStorage.setItem("blog_overview", documentObject[BLOG_REDUX_ACCESS_KEY.INPUT]);
  localStorage.setItem("blog_section", documentObject[BLOG_REDUX_ACCESS_KEY.OUTLINE]);
  localStorage.setItem("blog_section_points", documentObject[BLOG_REDUX_ACCESS_KEY.SECTION_POINT]);
  localStorage.setItem("blog_section_content", documentObject[BLOG_REDUX_ACCESS_KEY.CONTENT]);
  localStorage.setItem("content_type", documentObject[BLOG_REDUX_ACCESS_KEY.CONTENT_TYPE]);
  if (documentObject[BLOG_REDUX_ACCESS_KEY.TITLE]) {
    localStorage.setItem("blog_title", documentObject[BLOG_REDUX_ACCESS_KEY.TITLE]);
  } else {
    const blogOverview = JSON.parse(documentObject[BLOG_REDUX_ACCESS_KEY.INPUT]);
    localStorage.setItem("blog_title", blogOverview.primary_text);
  }

  localStorage.setItem("generator_page", documentObject[BLOG_REDUX_ACCESS_KEY.LAST_GENERATION_PAGE]);
  localStorage.setItem("working_doc_id", documentId);
};

export const renderLogoText = () => {
  return (
    <h2 className="brand-text text-primary ms-1">
      <span style={{ color: colors.logoDarkBlue }}>Pro</span><span style={{ color: colors.logoPink }}>Content</span>
    </h2>
  );
};

export const getQueryVariables = () => {
  const queryObject = {};
  let queries = window.location.search.substring(1).split("&");
  queries.forEach((queryItem) => {
    let pair = queryItem.split("=");
    queryObject[pair[0]] = pair[1];
  });
  return queryObject;
};

export const removePrefixHashes = (wordStrng) => {
  // Remove the prefix hashes from the word
  let word = wordStrng;
  while (word[0] == "#") {
    word = word.substring(1);
  }
  return word;
};

export const getCodeFromWindowURL = url => {
  return (new URL(url)).searchParams.get("code");
};

export const preventWebLoading = () => {
  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  // if(!isMobile){ window.location.replace('https://beta.centerstageai.com/') }
};

export const checkIfMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches;
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true
    //timeZoneName: 'short',
  };
  return date.toLocaleString(undefined, options);
};

export const saveDataToLocalStorage = (response) => {
  response.id && localStorage.setItem("id", response.id);
  response.first_name && localStorage.setItem("first_name", response.first_name);
  response.mobile && localStorage.setItem("mobile", response.mobile);
  response.last_name && localStorage.setItem("last_name", response.last_name);
  response.email && localStorage.setItem("email", response.email);
  response.refresh && localStorage.setItem("refresh", response.refresh);
  response.access && localStorage.setItem("token", response.access);
  response["company_id"] && localStorage.setItem("company_id", response["company_id"]);
  response["company_name"] && localStorage.setItem("company_name", response["company_name"]);
  response["company_slug"] && localStorage.setItem("company_slug", response["company_slug"]);
  response.image_url && localStorage.setItem("image_url", response.image_url);
  response.status && localStorage.setItem("status", response.status);
  response.plan && localStorage.setItem("plan", response.plan);
  response.role && localStorage.setItem("role", response.role);
  response["is_email_verified"] && localStorage.setItem("is_email_verified", response["is_email_verified"]);
  response["is_password_set"] && localStorage.setItem("is_password_set", response["is_password_set"]);
  response["credit_available"] && localStorage.setItem("credit_available", response["credit_available"]);
};

export const isMobileType = {
  Windows: function() {
    return /IEMobile/i.test(navigator.userAgent);
  },
  Android: function() {
    return /Android/i.test(navigator.userAgent);
  },
  BlackBerry: function() {
    return /BlackBerry/i.test(navigator.userAgent);
  },
  iOS: function() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
  }
};


//This function retrieves the query parameters from the current URL,
// splits them into key-value pairs, and saves each pair to local storage.
// You can call saveQueryParamsToLocalStorage to execute this operation whenever needed.


export function saveQueryParamsToLocalStorage(queryParams) {
  return new Promise((resolve, reject) => {
    // Check if there are any query parameters
    if (queryParams.length > 0) {
      // Remove the leading '?' character
      const queryString = queryParams.slice(1);

      // Split the query string into key-value pairs
      const paramPairs = queryString.split("&");

      // Track the number of saved key-value pairs
      let savedCount = 0;
      let bot = false;
      let bot_id = null;
      let pricing = false;

      // Function to save a key-value pair and check if all are saved
      const savePair = (pair) => {
        const [key, value] = pair.split("=");
        if (key.toString() === "bot-config" && value.toString() === '1') {
          bot = true;
        }

        if (key.toString() === "bot_id") {
          bot_id = value;
        }

        if(key.toString() === "pricing" && value.toString() === '1') {
          pricing = true;
        }

        localStorage.setItem(key, decodeURIComponent(value));
        savedCount++;

        // Check if all key-value pairs are saved
        if (savedCount === paramPairs.length) {
          resolve({ bot: bot, bot_id: bot_id, savedCount: savedCount, pricing: pricing });
        }
      };

      // Iterate through the key-value pairs
      paramPairs.forEach(savePair);
    } else {
      // No query parameters to save
      reject("No query parameters to save");
    }
  });
}


export const formatText = (text) => {
  return(
    text
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  );
}


export const generateAlphanumId = (prefix, length=8) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  if(prefix){ return `${prefix}-${result}`}
  return result;
}


export const generateInitialsAvatar = (firstName, lastName) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const size = 100;

  canvas.width = size;
  canvas.height = size;

  // Draw background
  context.fillStyle = "#0C6980"; // Example background color
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw initials
  context.fillStyle = "#ecf0f1"; // Example text color
  context.font = `${size / 2}px Arial`;
  context.textAlign = "center";
  context.textBaseline = "middle";
  const initials = `${firstName? firstName.charAt(0): 'A'}${lastName? lastName.charAt(0): ''}`.toUpperCase();
  context.fillText(initials, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL();
};

