import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ChartsModal from '.'; // Import the separated modal component
import './style.scss'
const ChartInChat = ({chartsData}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentChart, setCurrentChart] = useState(null);

    // Toggle modal and set the chart to be displayed
    const toggleModal = (chartType = null) => {
        setModalOpen(!modalOpen);
        if (chartType) {
            setCurrentChart(chartsData[chartType]);
        }
    };

    return (
        <div className="chart-in-chat">
            {/* Column Chart */}

            {
                (chartsData && Object.keys(chartsData?.columnChart).length != 0) &&

                <div className="chart-in-chat__chart column" style={{ width: '100%' }} id="column-chart" onClick={() => toggleModal('columnChart')}>
                    <span className='hover-text'>Click here to View</span>
                    <ReactApexChart
                        options={chartsData.columnChart.options}
                        series={chartsData.columnChart.series}
                        type="bar"
                        height={350}
                    />
                </div>
            }

            {/* Pie Chart */}
            {
                (chartsData && Object.keys(chartsData?.pieChart).length != 0) &&
                <div className="chart-in-chat__chart pie" onClick={() => toggleModal('pieChart')}>
                    <span className='hover-text'>Click here to View</span>

                    <ReactApexChart
                        options={chartsData.pieChart.options}
                        series={chartsData.pieChart.series}
                        type="pie"
                        width={380}
                    />
                </div>
            }
            {/* Bar Chart */}

            {
                (chartsData && Object.keys(chartsData?.barChart).length != 0) &&
                <div className="chart-in-chat__chart bar" onClick={() => toggleModal('barChart')}>
                    <span className='hover-text'>Click here to View</span>

                    <ReactApexChart
                        options={chartsData.barChart.options}
                        series={chartsData.barChart.series}
                        type="bar"
                        height={350}
                    />
                </div>
            }
            {/* Separated Modal Component */}
            <ChartsModal
                isOpen={modalOpen}
                toggle={toggleModal}
                chartData={currentChart}
            />
        </div>
    );
};

export default ChartInChat;
