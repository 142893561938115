import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input, Tooltip, Alert } from "reactstrap";

const SimulationModel = ({ isOpen, setIsOpen, addSimulationTab, receivedData }) => {
    console.log(receivedData.customer_concentration, 'receivedData')
    const initialSimulationData = {
        simulationName: 'Current Simulation',
        customerConcentrationRisk: receivedData?.customer_concentration || 0,
        supplierConcentrationRisk: receivedData?.supplier_concentration || 0,
        growthSustainabilityRisk: receivedData?.revenue_growth_rate || 0,
        profitabilityRisk: receivedData?.net_profit_margin || 0,
        marginCompressionRisk:receivedData?.gross_margin_decline ||  0,
        cashFlowRisk: receivedData?.operating_cash_flow_to_debt || 0
    };

    const [simulationData, setSimulationData] = useState(initialSimulationData);
    const [isFormValid, setIsFormValid] = useState(true);
    // State for managing tooltip visibility
    const [tooltipOpen, setTooltipOpen] = useState({
        customerConcentrationRisk: false,
        supplierConcentrationRisk: false,
        growthSustainabilityRisk: false,
        profitabilityRisk: false,
        marginCompressionRisk: false,
        cashFlowRisk: false
    });

    // Toggle tooltip visibility
    const toggleTooltip = (id) => {
        setTooltipOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
    // Handle form input changes
    const handleInputChange = ({ target: { name, value, type } }) => {
        setSimulationData(prevData => ({
            ...prevData,
            [name]: type === 'range' ? Number(value) : value 
        }));
    };


    // Validate if all fields are filled
    const validateForm = () => simulationData.simulationName.trim() !== '';

    // Handle form submission
    const handleProceed = () => {
        if (validateForm()) {
            setIsFormValid(true);
            addSimulationTab(simulationData);  // Call parent to add tab
            setSimulationData(initialSimulationData);
            setIsOpen(false);
        } else {
            setIsFormValid(false);
        }
    };

    // Close modal handler
    const closeModal = () => {
        setIsFormValid(true);  // Reset form validation state when closing
        // setSimulationData(initialSimulationData);  // Reset form data
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={closeModal} className="simulation-modal">
            <ModalBody>
                <h3 style={{ textAlign: 'center', marginBottom: '40px' }}>Add Simulations</h3>

                {/* Display error if form is not valid */}
                {!isFormValid && <Alert color="danger">Please fill in the simulation name.</Alert>}

                <Form>
                    {/* Simulation Name */}
                    <FormGroup row>
                        <Label for="simulationName" sm={5}>Simulation Name:</Label>
                        <Col sm={7}>
                            <Input
                                id="simulationName"
                                name="simulationName"
                                placeholder="Enter Simulation Name"
                                type="text"
                                value={simulationData.simulationName}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </FormGroup>

                    {/* Render range input fields */}
                    {/* Render range input fields with tooltips */}
                    {[
                        { id: 'customerConcentrationRisk', label: 'Revenue from 1 customer' },
                        { id: 'supplierConcentrationRisk', label: 'Purchase from 1 supplier' },
                        { id: 'growthSustainabilityRisk', label: 'Revenue Growth Rate' },
                        { id: 'profitabilityRisk', label: 'Net Profit Margin' },
                        { id: 'marginCompressionRisk', label: 'Gross Margin Decline' },
                        { id: 'cashFlowRisk', label: 'Operating Cash Flow/Total Debt' }
                    ].map(({ id, label }) => (
                        <FormGroup row key={id}>
                            <Label for={id} sm={5}>{label}:</Label>
                            <Col sm={7} className="position-relative">
                                <Input
                                    id={id}
                                    name={id}
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="10"
                                    value={simulationData[id]}
                                    onChange={handleInputChange}
                                    onMouseEnter={() => toggleTooltip(id)} // Show tooltip on hover
                                    onMouseLeave={() => toggleTooltip(id)} // Hide tooltip on mouse leave
                                />
                                {/* Tooltip for range value */}
                                <Tooltip
                                    isOpen={tooltipOpen[id]}
                                    target={id}
                                    toggle={() => toggleTooltip(id)}
                                    placement="top"
                                >
                                    {simulationData[id]}%
                                </Tooltip>

                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', marginTop: '5px' }}>
                                    <span>{`${simulationData[id]}%` || '0%'}</span> {/* Minimum value */}
                                    <span>100%</span> {/* Maximum value */}
                                </div>
                            </Col>
                        </FormGroup>
                    ))}
                </Form>
            </ModalBody>

            {/* Modal Footer with actions */}
            <ModalFooter>
                <Button color="primary" onClick={handleProceed}>
                    Proceed
                </Button>
                <Button color="secondary" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SimulationModel;
