import Types from "../types";

export const CreditAction = (message) => {
    return (dispatch) => {
        dispatch({ type: Types.CREDIT.EXHAUST,
            payload: message
         });
    };
};

export const ClosePopupAction = () => {
    return (dispatch) => {
        dispatch({ type: Types.CREDIT.CLOSE });
    };
};