import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
    planDetails: "billing/plan",
    creatOrderId: "billing/payment/create_order/?plan={0}",
    paymentStatus: "billing/payment/check_payment_status/?payment_id={0}",
    paymentCapture: "billing/payment/payment_capture/?order_id={0}&payment_id={1}"
}

export const planRequest = () => {
    return REQUESTS.get(API_ROUTES.planDetails, localStorage.getItem('token'),)
};
export const createOrderHandler = (planId) => {
    return REQUESTS.get(API_ROUTES.creatOrderId.format(planId), localStorage.getItem('token'),)
};

export const paymentStatusHandler = (paymentId) => {
    return REQUESTS.get(API_ROUTES.paymentStatus.format(paymentId), localStorage.getItem('token'),)
};

export const paymentCaptureHandler = (order_id, payment_id, payload) => {
    return REQUESTS.post(API_ROUTES.paymentCapture.format(order_id, payment_id), localStorage.getItem('token'),payload)
};

// export const billiRequest = (payload) => {
//     return REQUESTS.post(API_ROUTES.billingDetail, localStorage.getItem('token'), payload)
// };

