const Types = {
    USER: {
        SUCCESS: 'USER/SUCCESS',
    },
    COMMON: {
        LOADER: 'COMMON/LOADER',
    },
    ORGANISATION: {
        LOADER: 'ORGANISATION/LOADER',
        SUCCESS: 'ORGANISATION/SUCCESS',
        DATA_CLEAR: 'ORGANISATION/DATA_CLEAR',
        GET_DATA: 'ORGANISATION/GET_DATA',
        UPDATE_DATA: 'ORGANISATION/UPDATE_DATA',
    },
    BOT: {
        LOADER: 'BOT/LOADER',
        SUCCESS: 'BOT/SUCCESS',
        SCRIPT: 'BOT/SCRIPT',
        SCRIPT_CLEAR: 'BOT/SCRIPT_CLEAR',
        GET_BOT: 'BOT/GET_BOT',
        UPDATE_BOT: 'BOT/UPDATE_BOT',
    },
    BOT_LEAD: {
        SUCCESS: 'BOT_LEAD/SUCCESS',
        GET_BOT_LEAD: 'BOT_LEAD/GET_BOT_LEAD',
        SET_DATA: 'BOT_LEAD/SET_DATA',
        DATA_CLEAR: 'BOT_LEAD/DATA_CLEAR'
    },
    QUERY_LOGS: {
        SUCCESS: 'QUERY_LOGS/SUCCESS',
        GET_QUERY_LOGS: 'QUERY_LOGS/GET_QUERY_LOGS',
        DATA_CLEAR: 'QUERY_LOGS/DATA_CLEAR',
        SET_DATA: 'QUERY_LOGS/SET_DATA',
        TOGGLE_LOADER: 'QUERY_LOGS/TOGGLE_LOADER'
    },
    DOCUMENT: {
        SUCCESS: 'DOCUMENT_SUCCESS',
        ADD_NEW: 'DOCUMENT_ADD_NEW',
      },
    GENERATED_DOCUMENTS: "GENERATED_DOCUMENTS",
    PREVIEW_DOCUMENT: { UPDATE: 'PREVIEW_DOCUMENT_UPDATE' },
    DOCUMENT_ANALYSIS: { UPDATE: 'DOCUMENT_ANALYSIS_UPDATE' },
    SHORT_CONTENT_GENERATED: 'SHORT_CONTENT_GENERATED',
    TREND_CONTENT_GENERATED: 'TREND_CONTENT_GENERATED',
    TREND_KEYWORD_GENERATED: 'TREND_KEYWORD_GENERATED',
    THIRD_PARTY_AUTH: 'THIRD_PARTY_AUTH',
    CALANDER_POST_LIST: 'CALANDER_POST_LIST',
    NAVBAR_CONTENT: 'NAVBAR_CONTENT',
    NAVBAR_CONTENT_BTN_CLICK_STATUS: 'NAVBAR_CONTENT_BTN_CLICK_STATUS',
    LOGOUT: 'LOGOUT',
    PRICE_PLAN: {
        FULFILLED: 'PRICE_PLAN_FULFILLED',
        PENDING: 'PRICE_PLAN_PENDING'
    },
    BILLING: 'BILLING_POST',
    COUPON: {
        COUPON_APPLY: 'COUPON_APPLY',
    },
    SHOW_PROCESS: 'SHOW_PROCESS',
    SET_STATEMENT_PROCESS_STATE: 'SET_STATEMENT_PROCESS',
    BANKS_LIST: {
        PENDING: 'BANKS_LIST_PENDING',
        FULFILLED: 'BANKS_LIST_FULFILLED',
        ERROR: 'BANKS_LIST_ERROR'
    },
    BANKS_STATEMENT: {
        PENDING: 'BANKS_STATEMENT_PENDING',
        FULFILLED: 'BANKS_STATEMENT_FULFILLED',
        ERROR: 'BANKS_STATEMENT_ERROR'
    },
    UPLOAD_HISTORY: {
        PENDING: 'UPLOAD_HISTORY_PENDING',
        FULFILLED: 'UPLOAD_HISTORY_FULFILLED',
        ERROR: 'UPLOAD_HISTORY_ERROR'
    },

    SET_BANK_SELECTED: 'SET_BANK_SELECTED',
    SET_FIRST_BANK_SELECTED: 'SET_FIRST_BANK_SELECTED',
    CREATE_CHATBOT: {
        PENDING: 'CREATE_CHATBOT_PENDING',
        FULFILLED: 'CREATE_CHATBOT_FULFILLED',
        ERROR: 'CREATE_CHATBOT_ERROR',
    },
    CREDIT :{
        EXHAUST: 'EXHAUST',
        CLOSE: 'CLOSE',
    },
    // WizDoc Action type
    WIZDOC: {
        SESSION_LOAD: 'WIZDOC/LOAD',
        SESSION_DATA: 'WIZDOC/SUCCESS',

        COVERSATION_LOAD: 'WIZDOC/COVERSATION_LOAD',
        COVERSATION_DATA: 'WIZDOC/COVERSATION_SUCCESS',

        DOCUMENT_LOAD: 'WIZDOC/DOCUMENT_LOAD',
        DOCUMENT_DATA: 'WIZDOC/DOCUMENT_SUCCESS',
    },

};

export default Types;
