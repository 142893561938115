import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
    fetchCompanyData: "analytics/forecasting/fetch_company_data/",
    fetchBookData: "analytics/forecasting/fecth_book_data/",
    runCombinedForecast: "analytics/forecasting/run_combined_forecast/",
    runSimulatioForecast: "analytics/forecasting/run_simulation_forecast/",
    queryCashFlow: "analytics/forecasting/query_vector_data/"

}

export const fetchCompanyDataHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.fetchCompanyData, localStorage.getItem('token'), payload)
};

export const fetchBookDataHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.fetchBookData, localStorage.getItem('token'), payload)
};

export const runCombinedForecastHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.runCombinedForecast, localStorage.getItem('token'), payload)
}

export const runSimulatioForecastHandler = (payload) => {
    return REQUESTS.postJson(API_ROUTES.runSimulatioForecast, localStorage.getItem('token'), payload)
}

export const queryCashFlowHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.queryCashFlow, localStorage.getItem('token'), payload)
}

// export const fetchSessionDbSchemaHandler = (sessionId) => {
//     return REQUESTS.get(API_ROUTES.fetchSessionDbSchema.format(sessionId), localStorage.getItem('token'))
// }
