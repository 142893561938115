import { useState, useEffect } from "react";
import CustomTabs from "../../../components/CustomTabs";
import { BarLoader } from "react-spinners";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import invoiceData from '../Analytics/testData/invoices.json';
import expensesData from '../Analytics/testData/expenses.json';
import purchaseOrdersData from '../Analytics/testData/purchase_orders.json';
import salesOrderData from '../Analytics/testData/sales_orders.json';
import billsData from '../Analytics/testData/bills.json';
import cashFlowData from '../Analytics/testData/cashflow.json';

import { formatText } from "../../../../utility/helper";

const ForecastTab = ({ receivedData, activeSimulation }) => {
    const [multiLoading, setMultiloading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isForecast, setIsForecast] = useState(false)


    const itemsPerPage = 10;

    // Local JSON data mapping
    const dataMapping = {
        0: invoiceData,
        1: billsData,
        2: salesOrderData,
        3: purchaseOrdersData,
        4: expensesData,
        5: cashFlowData
    };
    const currentData = dataMapping[activeTab]?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Calculate the number of pages
    const totalPages = Math.ceil(dataMapping[activeTab]?.length / itemsPerPage);

    const renderRiskFactors = () => {
        let riskFactors = {};
        if (activeSimulation.name == 'Forecast Dashboard') {
            riskFactors = { ...receivedData.calculated_risks }
        } else {
            const simulationName = activeSimulation.name;
            console.log("Simulation Name Selected: ", simulationName)
            console.log("Received Data: ", receivedData.analytics)
            receivedData?.analytics?.simulations?.forEach(simulation => {
                if (simulation.name == simulationName) {
                    riskFactors = { ...simulation.calculated_risks }
                }
            })
        }
        return (
            <div className="summary-blocks">
                <div className="summary-block">
                    <h2>{riskFactors?.customer_concentration}%</h2>
                    {/* <h2>{summaryData.circularTxns}</h2> */}
                    <p>Revenue from 1 customer </p>
                </div>
                <div className="summary-block">
                    {/* <h2>{summaryData.insightAIScore}</h2> */}
                    <h2>{riskFactors?.supplier_concentration}%</h2>
                    <p>Purchase from 1 supplier</p>
                </div>
                <div className="summary-block">
                    <h2>{riskFactors?.revenue_growth_rate}%</h2>
                    {/* <h2>{summaryData.creditScore}</h2> */}
                    <p>Revenue Growth Rate</p>
                </div>

                <div className="summary-block">
                    <h2>{riskFactors?.net_profit_margin}%</h2>
                    <p>Net Profit Margin </p>
                </div>

                <div className="summary-block">
                    <h2>{riskFactors?.gross_margin_decline}%</h2>
                    <p>Gross Margin Decline </p>
                </div>
                <div className="summary-block">
                    <h2>{riskFactors?.operating_cash_flow_to_debt}%</h2>
                    <p>Operating Cash Flow/Total Debt</p>
                </div>

            </div>
        )
    }

    // Handle page change
    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const renderNoDataPage = () => {
        return (
            <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                    <div>No data available</div>
                </div>
            </div>
        )
    }
    const renderPageLoader = () => {
        return (
            <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                    <div>Loading data...</div>
                    <BarLoader width={200} color={"#039BE5"} />
                </div>
            </div>
        )
    }

    const renderPanelContainer = () => {
        if (multiLoading) {
            return (
                <div style={{ marginTop: "100px" }}>
                    <MultiStepLoader stepNumber={activeStep} />
                </div>
            )
        } else {
            if (currentData.length === 0) {
                return renderNoDataPage();
            } else {
                return (
                    <>
                        <div className="transaction-table-wrapper" style={{ marginTop: '20px' }}>
                            <table className="transaction-table">
                                <thead>
                                    <tr>
                                        {Object.keys(currentData[0]).map((key) => (
                                            <th key={key}>{formatText(key)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((item, index) => (
                                        <tr key={index}>
                                            {Object.values(item).map((value, i) => (
                                                <td key={i}>{typeof value === 'string' ? formatText(value) : value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <div className="pagination-wrapper">
                            <Pagination>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <PaginationItem key={i} active={i === currentPage}>
                                        <PaginationLink onClick={() => handlePageClick(i)}>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                            </Pagination>
                        </div>
                    </>
                )
            }
        }
    }

    return (
        <div className="forecast-tab">

            <div className="template-container-header" style={{ marginBottom: '15px' }}>
                {`Risk Factors ${isForecast ? ' (Updated)' : ''}`}
            </div>
            {renderRiskFactors()}

            <div className="template-container-header" style={{ marginBottom: '15px' }}>
                Analyzed Data
            </div>
            <div className="forecast-tab__table">
                <div className='tab-container' style={{ marginTop: '20px' }}>
                    {
                        !multiLoading &&
                        <CustomTabs
                        tabs={["Invoices", "Bills", "Sales Orders", "Purchase Orders", "Expenses", "Cashflow"]}
                        tabStatus={[true, true, true, true, true, true, true]}
                        onTabSelect={setActiveTab}
                        isLoading={[false, false, false, false, false, false, false]}
                        isCompleted={[true, false, false, false, false, true, false]}                        
                        />
                    }
                </div>
                <div className='page-container no-scroll-bar'
                    style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        paddingTop: '0px',
                        height: '80vh',
                    }}
                >
                    {renderPanelContainer()}
                </div>
            </div>
        </div>
    )
}

export default ForecastTab;