import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const ChartsModal = ({ isOpen, toggle, chartData }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} fullscreen>
            <ModalHeader toggle={toggle}>Chart Modal</ModalHeader>
            <ModalBody>
                {chartData ? (
                    <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type={chartData.options.chart.type}
                        height={350}
                        width={chartData.options.chart.width || '100%'}
                    />
                ) : (
                    'No chart data available'
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ChartsModal;
