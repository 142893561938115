import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from 'reactstrap';
import PageHeader from "../../../../@core/components/PageHeader";
import CustomeTable from "../../../components/CustomTable";
import { showErrorToast, showSuccessToast } from "../../../../utility/helper";
import { statusCode } from "../../../../utility/constants/utilObject";
import { listAllLendingStatementsHandler } from "../../../../services/underwriting";

const LendingStatementHistory = () => {

    // Data loaders
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const allowedColumns = ['serialNo', 'name', 'created_at'];

    useEffect(() => {
        fetchAlData();
    }, []);
    
    const fetchAlData = () => {
        setLoading(true);
        listAllLendingStatementsHandler()
        .then((response) => {
            setLoading(false);
            if(response.status == statusCode.HTTP_200_OK){
                setData(response.data.data.reverse());
                showSuccessToast("Loaded all Data successfully.")
            }else{
                setData([]);
                showErrorToast("Failed to load Data.")
            }
        })
        .catch((error) => {
            setLoading(false);
            setData([]);
            showErrorToast("Failed to load Data.")
        })
        
    }

    return (
        <div>
            <PageHeader
                pageName={"AI Underwriting cases"}
                backButtonEnabled={true}
            />
            <Card className="chatBotContainer">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                        width: '100%',
                        marginBottom: '15px'
                    }}>
                        <h3>Underwriting Cases</h3>
                    </div>

                    <CustomeTable
                        data={data}
                        loading={loading}
                        allowedColumns={allowedColumns}
                        singleRedirectionLink='/ai-underwriting/'
                        errResponse='No Statements Found.'
                        loadingText='Loading your data...'
                        redirectTitle="Click here to start a new analysis."
                    />
                </div>
            </Card>
        </div>
    )
}

export default LendingStatementHistory;