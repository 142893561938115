import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatText } from "../../../utility/helper";
import { ClipLoader } from "react-spinners";

const CustomeTable = ({ loading, data, allowedColumns, loadingText, errResponse, redirectionLink, redirectTitle, singleRedirectionLink }) => {
    const navigate = useNavigate();

    // Manually set the columns in the desired order
    const tableColumns = ['serialNo', ...allowedColumns.filter(col => col !== 'serialNo')];

    const handleRowClick = (row) => {
        if(singleRedirectionLink && row){ navigate(singleRedirectionLink + row.id) }
    };

    const renderTableRows = () => {
        if (loading) {
            return (
                <tr>
                    <td colSpan={tableColumns.length} style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: '17px', lineHeight: '1' }}>{loadingText || ''}</span> <ClipLoader size={16} color="primary" />
                    </td>
                </tr>
            );
        } else if (data.length === 0) {
            return (
                <tr>
                    <td colSpan={tableColumns.length} style={{ textAlign: 'center' }}>
                        {errResponse || ''}
                        <br />
                        {
                            redirectTitle && redirectionLink && 
                            <Link
                                to={redirectionLink || ''}
                                style={{
                                    marginTop: '5px',
                                    width: '150px',
                                    height: '100px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    textDecoration: 'underline'
                                }}> {redirectTitle || ''}
                            </Link>
                        }
                    </td>
                </tr>
            );
        } else {
            return data.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item)} style={{cursor:'pointer'}}>
                    {tableColumns.map((col, i) => (
                        <td key={i} style={col === 'serialNo' ? { width: '50px', textAlign: 'center' } : {}}>
                            {col === 'serialNo' ? index + 1 :
                                col === 'created_at' || col === 'updated_at' ? moment(item[col]).format('DD MMM, YYYY') : item[col]}
                        </td>
                    ))}
                </tr>
            ));
        }
    };

    return (
        <div>
            <Table bordered hover responsive style={{ backgroundColor: 'white' }}>
                <thead style={{ backgroundColor: 'black', color: '#455A64', position:'sticky' }}>
                    <tr>
                    {tableColumns.map((item, i) => (
                        <th key={i} style={item === 'serialNo' ? { width: '50px', fontSize: '14px', textAlign: 'center' } : {fontSize: '14px'}}>
                            {item === 'serialNo' ? 'S.No' : formatText(item)}
                        </th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        </div>
    );
};

export default CustomeTable;
