import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from "reactstrap";
import { fetchOrganisationData } from "../../../../redux/actions";
import PageHeader from "../../../../@core/components/PageHeader";
import CustomTabs from "../../../components/CustomTabs";
import { connect } from "react-redux";
import MultiStepLoader from "../../../components/MultiStepLoader";
import { statusCode } from "../../../../utility/constants/utilObject";
import { BarLoader } from "react-spinners";
import {
  uploadStatementHandler,
  fetchSingleTrialBalanceAnalysisHandler,
  fetchStatementAnalysisHandler
} from "../../../../services/ledger";
import RenderMD from "../../../components/MarkdownTypingEditor/indexRaw";
import Pusher, { Channel } from "pusher-js";
import { generateAlphanumId } from "../../../../utility/helper";
import { showErrorToast } from "../../../../utility/helper";
import SidebarDashboard from '../../../Dashboard/Sidebar'
import "./index.scss";

const STATEMENT_ANALYSIS_CATEGORIES = {
  "TRIAL_BALANCE": "TRIAL_BALANCE",
  "ANALYSIS": "ANALYSIS",
}


const TrialBalanceAnalysis = (props) => {
  const { state } = useLocation();
  const { document } = useParams();
  const navigate = useNavigate();
  const file = state && state.file ? state.file : null;

  // Data Variables
  const [multiLoading, setMultiloading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [socketId, setSocketId] = useState(null);
  const [statementId, setStatementId] = useState(document ? document : null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Statement analysis dataset
  const [trialBalanceData, setTrialBalanceData] = useState(null);
  const [trialAnalysisData, setTrialAnalysisData] = useState(null);

  // Pusher Variables
  const [pusher, setPusher] = useState(null);
  const [channel, setChannel] = useState(null);
  const [analyisStatus, setAnalysisStatus] = useState(null);

  const [trialBalanceStatus, setTrialBalanceStatus] = useState(null);
  const [trialAnalysisStatus, setTrialAnalysisStatus] = useState(null);
  const [isLoading, setIsLoading] = useState({
    'Trial Balance': false,
    'Analysis': false
  });


  // Loader variable
  const [activeStep, setActiveStep] = useState(0);
  const reloadWithId = (id) => {
    navigate(`/trial-balance-analytics/${id}`)
  }

  useEffect(() => {
    if (document == 'new-analysis') {
      if (!file) {
        showErrorToast('No file found');
      } else {
        setMultiloading(true)
        const socketId = generateAlphanumId('insightai', 10);
        setSocketId(socketId);
        initiateSockerConnection(socketId);

        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('document', file.file);
        formData.append('data_type', 'TRIAL_BALANCE');
        formData.append('document_type', 'PDF');

        uploadStatementHandler(formData, socketId)
          .then((response) => {
            if (response.status == statusCode.HTTP_201_CREATED) {
              setStatementId(response.data.id);
              reloadWithId(response.data.id);
              nextStep();
            } else {
              showErrorToast('Error uploading file');
              setMultiloading(false);
            }
          }).catch((err) => {
            showErrorToast('Error uploading file');
            setMultiloading(false);
          });
      }
    } else {
      setMultiloading(false);
      fetchPastStatamentAnalysis(document);
    }
  }, []);

  const getStatementId = (message) => {
    const messageSplit = message.split(':');
    if (messageSplit.length > 1) {
      return messageSplit[1];
    }
  }

  const fetchPastStatamentAnalysis = (statementId) => {
    setIsLoading(prevState => ({
      ...prevState,
      'Trial Balance': true,
      'Analysis': true
    }));

    fetchStatementAnalysisHandler(statementId)
      .then((response) => {
        if (response.status == statusCode.HTTP_200_OK) {
          if (response.data.trial_balance) {
            setTrialBalanceData(response.data.trial_balance);
            setIsLoading(prevState => ({
              ...prevState,
              'Trial Balance': false,
            }));
          } else {
            setTrialBalanceData('NO_DATA');
            setIsLoading(prevState => ({
              ...prevState,
              'Trial Balance': false,
            }));
          }

          if (response.data.analysis) {
            setTrialAnalysisData(response.data.analysis);
            setIsLoading(prevState => ({
              ...prevState,
              'Analysis': false
            }));
          } else {
            setTrialAnalysisData('NO_DATA');
            setIsLoading(prevState => ({
              ...prevState,
              'Analysis': false
            }));
          }
        } else {
          showErrorToast('Error fetching analysis');
          setIsLoading(prevState => ({
            ...prevState,
            'Trial Balance': false,
            'Analysis': false
          }));
        }
      }).catch((err) => {
        showErrorToast('Error fetching analysis');
        setIsLoading(prevState => ({
          ...prevState,
          'Trial Balance': false,
          'Analysis': false
        }));
      });
  }

  const fetchStatementAnalysis = (statementId, analysisType) => {
    if (!statementId) {
      showErrorToast('No statement found');
      return;
    }

    setIsLoading(prevState => ({
      ...prevState,
      'Trial Balance': true,
      'Analysis': true
    }));

    fetchSingleTrialBalanceAnalysisHandler(statementId)
      .then((response) => {
        if (response.status == statusCode.HTTP_200_OK) {
          if (analysisType == STATEMENT_ANALYSIS_CATEGORIES.TRIAL_BALANCE) {
            setTrialBalanceData(response.data.data);
            setIsLoading(prevState => ({
              ...prevState,
              'Trial Balance': false,
            }));
          } else if (analysisType == STATEMENT_ANALYSIS_CATEGORIES.ANALYSIS) {
            setTrialAnalysisData(response.data.data);
            setIsLoading(prevState => ({
              ...prevState,
              'Trial Balance': false,
              'Analysis': false
            }));
          }
        } else {
          showErrorToast('Error fetching analysis');
          setIsLoading(prevState => ({
            ...prevState,
            'Trial Balance': false,
            'Analysis': false
          }));
        }
        closeSocketConnection();
      }).catch((err) => {
        showErrorToast('Error fetching analysis');
        setIsLoading(prevState => ({
          ...prevState,
          'Trial Balance': false,
          'Analysis': false
        }));

        closeSocketConnection();

      });
  }

  const initiateSockerConnection = (socketId) => {
    const pusherInstance = new Pusher('56fd8b1ba5df4b243794', { cluster: 'ap2' });
    const channelInstance = pusherInstance.subscribe(socketId);
    channelInstance.bind('analysis', (data) => {
      console.log('analysis', data);
      channelInstance.unbind('analysis');
      setAnalysisStatus(true);
      nextStep();
    });
    channelInstance.bind('trial_balance_data', (data) => {
      console.log("trial_balance_data", data);
      setTrialBalanceStatus(true);

      nextStep();
      fetchStatementAnalysis(getStatementId(data), STATEMENT_ANALYSIS_CATEGORIES.TRIAL_BALANCE);
      channelInstance.unbind('trial_balance_data');
      const closeTimeout = setTimeout(() => {
        setMultiloading(false);
        closeSocketConnection();
        clearTimeout(closeTimeout);
      }, 500);
    });
    channelInstance.bind('trial_balance_analytics', (data) => {
      console.log("trial_balance_analytics", data);
      setTrialAnalysisStatus(true);
      nextStep();
      fetchStatementAnalysis(getStatementId(data), STATEMENT_ANALYSIS_CATEGORIES.ANALYSIS);
      channelInstance.unbind('trial_balance_analytics');
      const closeTimeout = setTimeout(() => {
        setMultiloading(false);
        closeSocketConnection();
        clearTimeout(closeTimeout);
      }, 500);
    });
    setPusher(pusherInstance);
    setChannel(channelInstance);
  }

  const closeSocketConnection = () => {
    if (analyisStatus && trialAnalysisStatus && trialBalanceStatus) {
      try {
        pusher.unsubscribe(socketId);
        pusher.disconnect();
      } catch (err) {
        console.log(err);
      }
    }
  }

  const nextStep = () => { setActiveStep(prevStep => (prevStep < 2 ? prevStep + 1 : prevStep)) };

  const renderPageLoader = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>Loading data...</div>
          <BarLoader width={200} color={"#039BE5"} />
        </div>
      </div>
    )
  }

  const renderNoDataPage = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>No data available</div>
        </div>
      </div>
    )
  }

  const renderPanelContainer = () => {
    if (multiLoading) {
      return (
        <div style={{ marginTop: "200px" }}>
          <MultiStepLoader stepNumber={activeStep} />
        </div>
      )
    } else {
      switch (activeTab) {
        case 0:
          if (trialBalanceData && trialBalanceData == 'NO_DATA') {
            return renderNoDataPage();
          } else if (trialBalanceData) {
            return (<RenderMD src={trialBalanceData} />)
          } else {
            return renderPageLoader();
          }
        case 1:
          if (trialAnalysisData && trialAnalysisData == 'NO_DATA') {
            return renderNoDataPage();
          } else if (trialAnalysisData) {
            return (<RenderMD src={trialAnalysisData} />)
          } else {
            return renderPageLoader();
          }
      }
    }
  }


  const handleExcelDownload = () => {
    
  }

  return (
    <Card>
      <PageHeader
        pageName={"Trial Balance"}
        backButtonEnabled={true}
      />
      <div className='container-fluid vh-85'>
        <div className='page-header' style={{ border: '0px' }}>
          <div className='tab-container'>
            {
              !multiLoading &&
              <CustomTabs
                tabs={["Trial Balance", "Analysis"]}
                onTabSelect={setActiveTab}
                isLoading={isLoading}
              />
            }
          </div>
          {
            !multiLoading &&
            <div className='search-container' style={{ textAlign: 'end' }}>
              <Button color="success" onClick={handleExcelDownload} style={{ marginRight: '6px' }}>Download As Excel</Button>
              <Button color="primary" onClick={() => { setSidebarOpen(true) }}>Chat</Button>
            </div>
          }
        </div>
        <div className='page-container no-scroll-bar'
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '0px',
            height: '65vh',
          }}
        >
          {renderPanelContainer()}
        </div>
      </div>
      <SidebarDashboard 
          open={sidebarOpen} 
          title="Trial Balance Analytics" 
          documentId={document} 
          toggleSidebar={() => { setSidebarOpen(false) }} />
    </Card>
  );
}

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(TrialBalanceAnalysis);