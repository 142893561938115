    // Sample charts data
    export const chartsData = {
        columnChart: {
            // series: [
            //     {
            //         name: 'Net Profit',
            //         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            //     },
            //     {
            //         name: 'Revenue',
            //         data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            //     },
            //     {
            //         name: 'Free Cash Flow',
            //         data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            //     }
            // ],
            // options: {
            //     chart: {
            //         type: 'bar',
            //         height: 550
            //     },
            //     plotOptions: {
            //         bar: {
            //             horizontal: false,
            //             columnWidth: '55%',
            //             endingShape: 'rounded'
            //         }
            //     },
            //     dataLabels: {
            //         enabled: false
            //     },
            //     stroke: {
            //         show: true,
            //         width: 2,
            //         colors: ['transparent']
            //     },
            //     xaxis: {
            //         categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
            //     },
            //     yaxis: {
            //         title: {
            //             text: '$ (thousands)'
            //         }
            //     }
            // }
        },
        pieChart: {
            // series: [44, 55, 13, 43, 22],
            // options: {
            //     chart: {
            //         width: 580,
            //         type: 'pie'
            //     },
            //     labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            //     responsive: [
            //         {
            //             breakpoint: 480,
            //             options: {
            //                 chart: {
            //                     width: 200
            //                 },
            //                 legend: {
            //                     position: 'bottom'
            //                 }
            //             }
            //         }
            //     ]
            // }
        },
        barChart: {
            series: [
                {
                    data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
                }
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 550
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true
                    }
                },
                xaxis: {
                    categories: [
                        'South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy',
                        'France', 'Japan', 'United States', 'China', 'Germany'
                    ]
                }
            }
        }
    };