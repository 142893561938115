// ** React Imports
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'

// ** Third Party Components
import classnames from 'classnames'

// import { CreditPopup } from '../../views/components/CreditModal/CreditPopup'
// import { CreditAction, ClosePopupAction } from '../../redux/actions/credit'
import { connect } from 'react-redux'

const BlankLayout = () => {
  // ** States
  const [isMounted, setIsMounted] = useState(false)

  // ** Hooks
  const { skin } = useSkin()

  useEffect(() => {
    setIsMounted(true)
    // CreditAction();
    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <div
      className={classnames('blank-page', {
        'dark-layout': skin === 'dark'
      })}
    >
      <div className='app-content content'>
        <div className='content-wrapper'>
          <div className='content-body'>
            <Outlet />


            {/* <CreditPopup isOpen={modalOpen} toggle={ClosePopupAction}/> */}

          </div>
        </div>
      </div>
    </div>
  )
}


// const mapStateToProps = ({creditReducer}) => ({
//   modalOpen: creditReducer.modalOpen
// });

// const mapDispatchToProps = {
//   CreditAction,
//   ClosePopupAction,
// };


export default BlankLayout;
