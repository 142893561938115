const kycData = [
    {
      description: 'HDFC - XXXXXX1123 - Current',
      accountHolder: 'Bivek Kumar',
      accountNumber: 'XXXXXX1123',
      bankName: 'HDFC',
      accountType: 'Current',
      email: 'bivekkumar101@gmail.com',
      phoneNumber: '-',
      statementFrom: '01/12/2022',
      statementTo: '31/12/2022',
      txnStartDate: '01/12/2020',
      txnEndDate: '01/01/2021',
    },
];

const transactionData = [
    {
      particulars: "01/12/2020 REV_INSTAALERT CHGS OCT22_011122-MIR2333 279136218",
      chequeRefNbr: "MIR2333279136218",
      counterparty: "",
      debit: "",
      credit: "0.94",
      balance: "96,395.21",
      computedBalance: "96,395.21",
      category: "Charges Reversed",
      tags: "Bank Charges, Return",
      upiApp: ""
    },
    {
      particulars: "01/12/2020 REV_INSTAALERT CHGS OCT22_011122-MIR2333 279136218",
      chequeRefNbr: "MIR2333279136218",
      counterparty: "",
      debit: "",
      credit: "0.94",
      balance: "96,396.15",
      computedBalance: "96,396.15",
      category: "Charges Reversed",
      tags: "Bank Charges, Return",
      upiApp: ""
    },
    {
      particulars: "02/12/2020 UPI-PRASAD GAJANAN GAD-PRASADGAD59@OKSBI -SBIN0005554-233604977850-UPI",
      chequeRefNbr: "0000233604977850",
      counterparty: "Prasad Gajanan Gad, Prasadgad59",
      debit: "48.00",
      credit: "",
      balance: "96,348.15",
      computedBalance: "96,348.15",
      category: "O/W Funds Transfer / UPI",
      tags: "UPI, UPI/P2P",
      upiApp: "Google Pay"
    },
    {
      particulars: "02/12/2020 UPI-KALPA DEVIDAS GOVENK-KALPAGOVENKAR@OKICICI-ICIC0001983-233620278580-UPI",
      chequeRefNbr: "0000233620278580",
      counterparty: "Kalpa Devidas Govenk, Kalpagovenkar",
      debit: "290.00",
      credit: "",
      balance: "96,058.15",
      computedBalance: "96,058.15",
      category: "O/W Funds Transfer / UPI",
      tags: "UPI, UPI/P2P",
      upiApp: "Google Pay"
    },
    {
      particulars: "03/12/2020 UPI-AMAZON PAY-AMAZONUPI@APL-UTIB0000100-233791881263-YOU ARE PAYING FOR",
      chequeRefNbr: "0000233791881263",
      counterparty: "Amazon, Amazonupi",
      debit: "549.00",
      credit: "",
      balance: "95,509.15",
      computedBalance: "95,509.15",
      category: "Consumer Products",
      tags: "UPI/P2M, UPI",
      upiApp: "Amazon Pay"
    },
    {
      particulars: "05/12/2020 UPI-PRASHANT PRAKASH CHA-PRASHANTCHALKE0123@OKSBI-BARB0KANAKV-233999774086-UPI",
      chequeRefNbr: "0000233999774086",
      counterparty: "Prashant Prakash Cha, Prashantchalke0, Barb0Kanakv",
      debit: "1,247.00",
      credit: "",
      balance: "94,262.15",
      computedBalance: "94,262.15",
      category: "Food & Dining",
      tags: "UPI/P2M, UPI",
      upiApp: "Google Pay"
    },
    {
      particulars: "06/12/2020 UPI-MYNTRA MMT-ANIKET@OKHDFCBANK-HDFC0000080-234100198345-UPI",
      chequeRefNbr: "0000234100198345",
      counterparty: "Myntra, Aniket",
      debit: "2,300.00",
      credit: "",
      balance: "91,962.15",
      computedBalance: "91,962.15",
      category: "Shopping",
      tags: "UPI/P2M, UPI",
      upiApp: "PhonePe"
    },
    {
      particulars: "08/12/2020 UPI-GROCERY STORE-GROCERY@OKICICI-ICIC0001098-234561201876-UPI",
      chequeRefNbr: "0000234561201876",
      counterparty: "Grocery Store, Grocery",
      debit: "1,150.00",
      credit: "",
      balance: "90,812.15",
      computedBalance: "90,812.15",
      category: "Groceries",
      tags: "UPI/P2M, UPI",
      upiApp: "Paytm"
    },
    {
      particulars: "10/12/2020 ATM Withdrawal -SBI ATM-00120391",
      chequeRefNbr: "0000120391",
      counterparty: "SBI ATM",
      debit: "5,000.00",
      credit: "",
      balance: "85,812.15",
      computedBalance: "85,812.15",
      category: "Cash Withdrawal",
      tags: "ATM",
      upiApp: ""
    },
    {
      particulars: "12/12/2020 NEFT Transfer to Ramesh-123456789",
      chequeRefNbr: "NEFT123456789",
      counterparty: "Ramesh Kumar",
      debit: "8,500.00",
      credit: "",
      balance: "77,312.15",
      computedBalance: "77,312.15",
      category: "Funds Transfer",
      tags: "NEFT, Transfer",
      upiApp: ""
    },
    {
      particulars: "14/12/2020 Credit Interest - Saving Account",
      chequeRefNbr: "INT20201214",
      counterparty: "Bank",
      debit: "",
      credit: "120.50",
      balance: "77,432.65",
      computedBalance: "77,432.65",
      category: "Interest Earned",
      tags: "Bank Interest",
      upiApp: ""
    },
    {
      particulars: "15/12/2020 UPI-ZOMATO PAY-ZOMATO@OKHDFCBANK-HDFC0000193-234899208611-UPI",
      chequeRefNbr: "0000234899208611",
      counterparty: "Zomato, Zomato",
      debit: "450.00",
      credit: "",
      balance: "76,982.65",
      computedBalance: "76,982.65",
      category: "Food & Dining",
      tags: "UPI/P2M, UPI",
      upiApp: "Google Pay"
    },
    {
      particulars: "17/12/2020 UPI-BIG BAZAAR-BIGBAZAAR@OKSBI-SBIN0007890-234990123456-UPI",
      chequeRefNbr: "0000234990123456",
      counterparty: "Big Bazaar, Bigbazaar",
      debit: "3,200.00",
      credit: "",
      balance: "73,782.65",
      computedBalance: "73,782.65",
      category: "Shopping",
      tags: "UPI/P2M, UPI",
      upiApp: "Paytm"
    },
    {
      particulars: "18/12/2020 UPI-MOVIE TICKET-PVR@OKICICI-ICIC0002045-235009876543-UPI",
      chequeRefNbr: "0000235009876543",
      counterparty: "PVR Cinemas, PVR",
      debit: "750.00",
      credit: "",
      balance: "73,032.65",
      computedBalance: "73,032.65",
      category: "Entertainment",
      tags: "UPI/P2M, UPI",
      upiApp: "Amazon Pay"
    },
    {
      particulars: "20/12/2020 UPI-FUEL STATION-HPCL@OKHDFCBANK-HDFC0000276-235199876532-UPI",
      chequeRefNbr: "0000235199876532",
      counterparty: "HPCL, Fuel Station",
      debit: "2,500.00",
      credit: "",
      balance: "70,532.65",
      computedBalance: "70,532.65",
      category: "Fuel",
      tags: "UPI/P2M, UPI",
      upiApp: "PhonePe"
    }
  ];


//   AML Data
  const suspiciousActivities = [
    { name: 'Big deposit followed by withdrawals on same or next day', count: 0 },
    { name: 'Multiple deposits followed by big withdrawal on same or next day', count: 0 },
    { name: 'Multiple Cash/ ATM deposits on same day', count: 0 },
    { name: 'High value spending', count: 2 },
    { name: 'International wire transfers', count: 1 },
  ];
  
  const monthlyData1 = [
    { month: 'Jul', deposits: 120000, withdrawals: 90000 },
    { month: 'Aug', deposits: 150000, withdrawals: 130000 },
    { month: 'Sep', deposits: 110000, withdrawals: 100000 },
    { month: 'Oct', deposits: 140000, withdrawals: 120000 },
    { month: 'Nov', deposits: 170000, withdrawals: 160000 },
    { month: 'Dec', deposits: 130000, withdrawals: 140000 },
  ];
  
  const paymentInstrumentsData = {
    deposits: [120000, 15000, 30000, 20000, 80000, 110000, 20000, 10000],
    withdrawals: [100000, 25000, 50000, 15000, 70000, 120000, 15000, 5000],
  };

  // Credti data
  const creditData = {
    creditScore: 750,
    defaultRisk: 10,
    totalLoans: 5,
    recentInquiries: 2,
    creditUtilization: 45,
    loanTypes: [
      { name: 'Business Loan', amount: 200000 },
      { name: 'Personal Loan', amount: 50000 },
      { name: 'Credit Line', amount: 80000 },
    ],
    repaymentHistory: [
      { month: 'Jul', amount: 30000 },
      { month: 'Aug', amount: 25000 },
      { month: 'Sep', amount: 20000 },
      { month: 'Oct', amount: 35000 },
      { month: 'Nov', amount: 30000 },
      { month: 'Dec', amount: 40000 },
    ],
  };

// sumaary data
const summaryData = {
    insightAIScore: 83.3,
    creditScore: 785,
    circularTxns: 0,
    bouncedCheques: 0,
    transactions: 46,
    txnPeriod: '01/12/2020 - 01/01/2021',
    monthlyAvgBalance: 136621.7,
  };
  
  const kycData1 = {
    'Account Holders': 'Xyz Information Technologies',
    'Account Number': 'xxxxxx1123',
    'Bank Name': 'HDFC',
    'Account Type': 'Savings',
    'Email': 'ROSETTEPEREIRA@REDIFFMAIL.COM',
    'Phone Number': '-',
    'Statement From': '01/12/2022',
    'Statement To': '31/12/2022',
    'Txn Start Date': '01/12/2020',
    'Txn End Date': '01/01/2021',
  };

  const irregularitiesData = [
    { activity: 'Big deposit followed by withdrawals on same or next day', incidences: 0 },
    { activity: 'Multiple deposits followed by big withdrawal on same or next day', incidences: 0 },
    { activity: 'Multiple Cash/ ATM deposits on same day', incidences: 0 },
    { activity: 'High value spending', incidences: 2 },
    { activity: 'International wire transfers', incidences: 0 },
    { activity: 'ATM withdrawals above 20,000', incidences: 0 },
  ];
  
  const monthlyBalanceData = [
    { month: 'Jul', avgBalance: 120000 },
    { month: 'Aug', avgBalance: 150000 },
    { month: 'Sep', avgBalance: 110000 },
    { month: 'Oct', avgBalance: 140000 },
    { month: 'Nov', avgBalance: 170000 },
    { month: 'Dec', avgBalance: 130000 },
  ];
  
  const monthlyTransactionData = [
    { month: 'Jul', deposits: 50000, withdrawals: 30000 },
    { month: 'Aug', deposits: 60000, withdrawals: 35000 },
    { month: 'Sep', deposits: 55000, withdrawals: 40000 },
    { month: 'Oct', deposits: 70000, withdrawals: 45000 },
    { month: 'Nov', deposits: 65000, withdrawals: 42000 },
    { month: 'Dec', deposits: 60000, withdrawals: 47000 },
  ];

export { 
    kycData, transactionData, suspiciousActivities, monthlyData1, paymentInstrumentsData, creditData,
    summaryData, kycData1, irregularitiesData, monthlyBalanceData, monthlyTransactionData
};