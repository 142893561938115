// ** Reducers Imports
import { combineReducers } from 'redux';
import navbar from './navbar'
import layout from './layout'
import shortPostContent from './reducers/shortPostContent';
import thirdPartyAuth from './reducers/thirdPartyAuth';
import navbarContent from './reducers/navbarContent';
import calenderPosts from './reducers/calenderPosts';
import document from "./reducers/document";
import botReducer from './reducers/bot';
import organisationReducer from './reducers/organisation';
import botLeadReducer from "./reducers/botLeads";
import queryLogReducer from "./reducers/query_logs";
import commonReducer from "./reducers/common";
import pricePlan from "./reducers/pricePlans";
import showProcessReducer from './reducers/bspReducer';
import bankListReducer from './reducers/banksList';
import bankStatementReducer from './reducers/bankStatementReducer';
import uploadHistory from './reducers/uploadHistory';
import bankSelectedReducer from "./reducers/selectedBank";
import firstBankSelectedReducer from "./reducers/bankSelected";
import creditReducer from './reducers/credit';


const rootReducer = combineReducers({
  navbar,
  layout,
  shortPostContent,
  thirdPartyAuth,
  navbarContent,
  calenderPosts,
  document,
  botReducer,
  botLeadReducer,
  queryLogReducer,
  organisationReducer,
  commonReducer,
  pricePlan,
  showProcessReducer,
  bankListReducer,
  bankStatementReducer,
  uploadHistory,
  bankSelectedReducer,
  firstBankSelectedReducer,
  creditReducer,
});

export default rootReducer
