// ** React Imports
import Billing from '../../views/pages/Billing';
import Dashboard from '../../views/Dashboard';
import ContactUs from "../../views/pages/ContactUs";
import BankTemplateSelection from '../../views/BankStatementParser/BankingAPISelection';
import AnalyticsDashboard from '../../views/AnalyticsDashboard';
import Banks from '../../views/BankStatementParser/Banks';
import AccountSettings from '../../views/Profile/account-settings';
import DocumentIntelligence from '../../views/GenerativeAISolution/ChatBot';
import PreviousChatSessions from '../../views/GenerativeAISolution/PreviousChatSessions';
import ZohoAccess from '../../views/ThridPartyIntegrations/ZohoAccess';
import ApiDashboard from '../../views/ApiDashboard';
import BankParsing from '../../views/BankStatementParser/Banks';
import UploadStatement from '../../views/BankStatementParser/Banks/UploadSatement';
import AnalysisTable from '../../views/BankStatementParser/Banks/AnalysisTable';
import KnowledgeCenter from '../../views/KnowledgeCenter';

import FinancialStatamentAnalysis from '../../views/Ledgers/FinancialStatementAnalytics/Analytics';
import FinancialStatementHistory from '../../views/Ledgers/FinancialStatementAnalytics/History';
// Trial balance
import TrialBalanceAnalysis from '../../views/Ledgers/TrialBalance/Analytics';
import TrialBalanceHistory from '../../views/Ledgers/TrialBalance/History';
//Underwriting Analysis
import UnderwritingAnalysis from '../../views/Ledgers/Underwriting/Analytics';
import UnderwritingHistory from '../../views/Ledgers/Underwriting/History';
//HNAnalysis
import HNAnalysis from '../../views/Ledgers/HomeLoanUnderwriting/Analytics';
import HNHistory from '../../views/Ledgers/HomeLoanUnderwriting/History'
// Scanned PDF
import ScannedPDFHistory from '../../views/Ledgers/ScannedPDF/History';
import ScannedPDFAnalysis from '../../views/Ledgers/ScannedPDF/Analytics';
// Chatbot
import ChatBot from '../../views/ChatBot';
import ChatbotProfile from '../../views/ChatBot/ChatbotProfile';
import Ledgers from '../../views/Ledgers';
import Plans from '../../views/ProductsAndPlans';
import WhatsAppConversation from '../../views/ChatBot/WhatsappBot';
import DBIntelligence from '../../views/DBIntelligence';
import Charts from '../../views/components/Charts';
// Db Connect Session
import DBChatSession from '../../views/DBIntelligence/DBChatSession';
import DBHistory from '../../views/DBIntelligence/DBHistory';
import DBForm from '../../views/DBIntelligence/DBForm';
import CashflowAnalysis from '../../views/Ledgers/CashflowMonitoring/Analytics';
import CompanySelectionScreen from '../../views/Ledgers/CashflowMonitoring/Analytics/CompanySelection';
import CashFlowTable from '../../views/Ledgers/CashflowMonitoring/Analytics/CashFlowTable';
import CashFlowForecast from '../../views/Ledgers/CashflowMonitoring/Forecast';

const AppRoutes = [
  {
    element: <Ledgers />,
    path: '/dashboard',
    meta: { publicRoute: false }
  },
  {
    element: <DocumentIntelligence />,
    path: '/wizDoc',
    meta: { publicRoute: false }
  },
  {
    path: '/analytics-dashboard/:session',
    element: <AnalyticsDashboard />,
    meta: { publicRoute: false }
  },
  {
    path: '/financial-statement-analyser',
    element: <Banks />,
    meta: { publicRoute: true }
  },
  {
    path: '/wizDoc/:session',
    element: <DocumentIntelligence />,
    meta: { publicRoute: false }
  },
  {
    path: '/wizDoc-history',
    element: <PreviousChatSessions />,
    meta: { publicRoute: false }
  },
  {
    path: '/bank-statement-analysis',
    element: <Dashboard />,
    meta: { publicRoute: false }
  },
  {
    path: '/pages/account-settings',
    element: <AccountSettings />
  },
  {
    element: <Billing />,
    path: '/billing',
    meta: { publicRoute: false }
  },
  {
    element: <ContactUs />,
    path: '/support',
    meta: { publicRoute: false }
  },

  // Financial Statement Analytics
  {
    element: <FinancialStatamentAnalysis />,
    path: '/financial-statement-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <FinancialStatementHistory />,
    path: '/financial-statements',
    meta: { publicRoute: false }
  },

  // Scanned PDF
  {
    element: <ScannedPDFAnalysis />,
    path: '/scanned-pdf-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <ScannedPDFHistory />,
    path: '/scanned-pdfs',
    meta: { publicRoute: false }
  },

  // Trial Balance

  {
    element: <TrialBalanceAnalysis />,
    path: '/trial-balance-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <TrialBalanceHistory />,
    path: '/trial-balances',
    meta: { publicRoute: false }
  },

  {
    element: <UnderwritingAnalysis />,
    path: '/ai-underwriting/:document',
    meta: { publicRoute: false }
  },

  {
    element: <UnderwritingHistory />,
    path: '/ai-underwriting',
    meta: { publicRoute: false }
  },
  {
    element: <HNAnalysis />,
    path: '/ai-hnanalysis/:document',
    meta: { publicRoute: false }
  },
  {
    element: <HNHistory />,
    path: '/ai-hnanalysis',
    meta: { publicRoute: false }
  },
  
  // Third Party Apps
  {
    element: <ZohoAccess />,
    path: '/zoho-access',
    meta: { publicRoute: false }
  },
  {
    path: '/pages/account-settings',
    element: <AccountSettings />
  },
  {
    path: '/api-dashboard',
    element: <ApiDashboard />,
    meta: { publicRoute: true }
  },
  {
    path: '/financial-statement-analyser',
    element: <BankParsing />,
    meta: { publicRoute: true }
  },
  {
    path: '/financial-statement-analyser/upload-statement',
    element: <UploadStatement />,
    meta: { publicRoute: true }
  },
  {
    path: '/upload-history',
    element: <AnalysisTable />,
    meta: { publicRoute: true }
  },
  {
    path: '/upload-history/:statement_id',
    element: <AnalysisTable />,
    meta: { publicRoute: true }
  },
  {
    path: '/knowledge-center',
    element: <KnowledgeCenter />,
    meta: { publicRoute: true }
  },
  {
    path: '/wizBot',
    element: <ChatBot />,
    meta: { publicRoute: true }
  },
  {
    path: '/wizBot/:bot_id',
    element: <ChatbotProfile />,
    meta: { publicRoute: true }
  },
  {
    path: '/wizBot/:bot_id/:bot_tab',
    element: <ChatbotProfile />,
    meta: { publicRoute: true }
  },
  {
    path: '/products-and-plans',
    element: <Plans />,
    meta: { publicRoute: true }
  },
  {
    path: '/wizBot/whatsApp-bot/:number',
    element: <WhatsAppConversation />,
    meta: { publicRoute: true }
  },
  //Database Intelligence
  {
    path: '/database-intelligence',
    element: <DBIntelligence />,
    meta: { publicRoute: true }
  },
  {
    path: '/database-intelligence/connection/',
    element: <DBIntelligence />,
    meta: { publicRoute: true }
  },
  {
    path: '/database-intelligence/analytics/:session',
    element: <DBChatSession />,
    meta: { publicRoute: true }
  },
  {
    path: '/database-intelligence/previous-connections/',
    element: <DBHistory />,
    meta: { publicRoute: true }
  },

  {
    path: '/database-intelligence/:sample_db',
    element: <DBForm />,
    meta: { publicRoute: true }
  },

  //charts
  {
    path: '/database-intelligence/charts',
    element: <Charts />,
    meta: { publicRoute: true }
  },

  //CashFlow
  {
    path: '/cashflow-analysis/:session',
    element: <CashflowAnalysis />,
    meta: { publicRoute: false }
  },
  {
    path: '/cashflow-analysis/company-selection',
    element: <CompanySelectionScreen />,
    meta: { publicRoute: false }
  },

  {
    path: '/cashflow-analysis/analysis',
    element: <CashFlowTable />,
    meta: { publicRoute: false }
  },
  {
    path: '/cashflow-analysis/forecast',
    element: <CashFlowForecast />,
    meta: { publicRoute: false }
  },

]

export default AppRoutes;
