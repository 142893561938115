// ** React Imports
import { Fragment, useState } from 'react'

import { ArrowLeft, Grid } from "react-feather"
import { Link, useNavigate } from 'react-router-dom'

// ** Custom Components
import { connect } from 'react-redux'
import { setNavbarContent } from '../../../../redux/actions'
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import { colors } from '../../../../utility/constants/colors'
// import InsightAILogo from '@src/assets/images/logo/main-logo.png';
import InsightAILogo from '../../../../assets/images/logo/main-logo.svg';
import LeftArrow from '../../../../assets/icons/left-arrow.png';

import "./index.scss"



const ThemeNavbar = props => {
  const navigate = useNavigate()
  const isMobile = window.matchMedia('(max-width: 767px)').matches
  const [bookmarkOpen, setBookmarkOpen] = useState(false)

  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  let pageName = "InsightAI"
  let backButtonEnabled = false
  let headerBtnEnabled = false
  let headerBtnName = null
  if (props.navbarPayload && props.navbarPayload.pageName) {
    pageName = props.navbarPayload.pageName
    backButtonEnabled = props.navbarPayload.backButtonEnabled
    headerBtnEnabled = props.navbarPayload.headerBtnEnabled
    headerBtnName = props.navbarPayload.headerBtnName
  }

  const backBtnHandle = () => {
    navigate(-1)
  }

  const enableHeaderBtnClick = () => {
    props.setNavbarContent({
      ...props.navbarPayload,
      headerBtnClickEnabled: true
    })
  }

  const renderHeaderBtn = () => {
    if (headerBtnEnabled) {
      return (<div className="headerNavBtn" onClick={enableHeaderBtnClick}>
        {headerBtnName}
      </div>)
    }
  }

  const renderBookmarks = () => {
    if (bookmarkOpen) return (
      <div className='bookmark-wrapper d-flex align-items-center'>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
    )
  }

  const renderLogo = () => {
    // if (isMobile) {
      return (<img style={{ height: '100%', width: '100%', marginRight: '5px' }} src={InsightAILogo} className="logo navLogo" alt={''} />)
    // }
  }

  return (
    <Fragment>
      {/* <Link to='/dashboard' className='logo-wrapper' style={{ width: '120px' }}>
        {renderLogo()}
      </Link> */}

      {/* {renderBookmarks()} */}
      <div className='pageNameStyle' >
        {
          backButtonEnabled?
          <img src={LeftArrow} alt="back" 
              onClick={backBtnHandle} 
              className="backIcon" 
              size={25} 
              color={colors.black}/>:
          null
          // renderLogo()
        } 
        {pageName}
      </div>
      {renderHeaderBtn()}
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

const mapDispatchToProps = {
  setNavbarContent
};

const mapStateToProps = ({ navbarContent }) => {
  return {
    navbarPayload: navbarContent.payload,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar);
