// CreditAnalysisReport.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file
import { Bar, Pie, Doughnut } from 'react-chartjs-2'; // Import required chart components from react-chartjs-2

const CreditAnalysisReport = ({ creditData }) => {
  return (
    <div className="credit-container">
      {/* Top Summary Blocks */}
      {/* <div className="summary-blocks">
        <div className="summary-block">
          <h2>{creditData.creditScore}</h2>
          <p>Credit Score</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.defaultRisk}%</h2>
          <p>Default Risk</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.totalLoans}</h2>
          <p>Total Loans</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.recentInquiries}</h2>
          <p>Recent Credit Inquiries</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.creditUtilization}%</h2>
          <p>Credit Utilization Ratio</p>
        </div>
      </div> */}
      <div className="summary-blocks">
        <div className="summary-block">
          <h2>{74}</h2>
          <p>Financial Stability Risk</p>
        </div>
        <div className="summary-block">
          <h2>₹ 3,20,000</h2>
          <p>Credit Limit Recommendation</p>
        </div>
        <div className="summary-block">
          <h2>85%</h2>
          <p>Industry Risk</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.recentInquiries}</h2>
          <p>Recent Credit Inquiries</p>
        </div>
        <div className="summary-block">
          <h2>{creditData.creditUtilization}%</h2>
          <p>Credit Utilization Ratio</p>
        </div>
      </div>

      {/* Pie and Doughnut Charts Side by Side */}
      {/* <div className="charts-row">
        <div className="chart-block">
          <h3>Loan Types Breakdown</h3>
          <div className="chart-container">
            <Pie
              data={{
                labels: creditData.loanTypes.map((type) => type.name),
                datasets: [
                  {
                    data: creditData.loanTypes.map((type) => type.amount),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { position: 'bottom' },
                },
              }}
              height={200}
              width={200}
            />
          </div>
        </div>

        <div className="chart-block">
          <h3>Credit Utilization Ratio</h3>
          <div className="chart-container">
            <Doughnut
              data={{
                labels: ['Utilized', 'Available'],
                datasets: [
                  {
                    data: [creditData.creditUtilization, 100 - creditData.creditUtilization],
                    backgroundColor: ['#FF6384', '#36A2EB'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB'],
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { position: 'bottom' },
                },
                cutout: '70%', // Creates a doughnut effect for the gauge
              }}
              height={200}
              width={200}
            />
          </div>
        </div>
      </div> */}

      {/* Repayment History Bar Chart */}
      <div className="repayment-history-block">
        <h3>Repayment History Over Time</h3>
        <Bar
          data={{
            labels: creditData.repaymentHistory.map((entry) => entry.month),
            datasets: [
              {
                label: 'Repayment Amount (₹)',
                data: creditData.repaymentHistory.map((entry) => entry.amount),
                backgroundColor: '#4BC0C0',
                borderRadius: 5,
                barThickness: 12,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: { display: false },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Month',
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Repayment Amount (₹)',
                },
              },
            },
          }}
          height={80}
        />
      </div>
    </div>
  );
};

export default CreditAnalysisReport;
