import { useState } from 'react';
import './style.scss';
import { Button, InputGroup, Input } from "reactstrap";
import { Check } from 'react-feather';
import { ClipLoader } from 'react-spinners';
import ContactModal from '../../components/ContactModal';
import { showErrorToast, showSuccessToast } from '../../../utility/helper';
import { createOrderHandler, paymentCaptureHandler } from '../../../services/billing';
import { statusCode } from '../../../utility/constants/utilObject';
import { convertRupeesToPaise } from '../../../utility/Utils';
const PriceCard = ({ planDetails }) => {
    const [showInput, setShowInput] = useState(false);
    const [isApplying, setIsApplying] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [salesLoader, setSalesLoader] = useState(false);
    const [showOfferings, setShowOfferings] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [status, setStatus] = useState('PENDING');
    // const [paymentPayload, setPaymentPayload] = useState({
    //     "payment_response":{},
    //     "status": status
    // })

    // ['SUCCESS', 'CANCELLED', 'FAILED', 'PENDING']
    // Coupon Code
    const handleCodeInput = () => {
        setShowInput(prev => !prev);
    }

    const handleApplyCode = () => {
        setIsApplying(true);

        setTimeout(() => {
            setIsApplied(true);
            setIsApplying(false)
        }, 2000);
    }

    // Function to toggle modal
    const salesConfirmation = () => {
        setSalesLoader(true);
        const timeOut = setTimeout(() => {
            setSalesLoader(false);
            showSuccessToast('Thank you for selecting the Platinum plan! Our team will contact you soon.')

            clearTimeout(timeOut);
        }, 1000)
    }

    const handleOffering = () => {
        setShowOfferings(prev => !prev)
    }

    //RazorPay Payment

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    //payment
    const handlePayment = (plandetails) => {
        let planId = plandetails.id;
        let amountInPaise = convertRupeesToPaise(planDetails.amount)

        setSalesLoader(true)
        createOrderHandler(planId)
            .then(response => {
                setSalesLoader(false)

                if (response.status == statusCode.HTTP_200_OK) {

                    setOrderId(response.data.order_id);
                    localStorage.setItem('order_id', response.data.order_id)

                    loadScript('https://checkout.razorpay.com/v1/checkout.js')
                        .then(
                            (res) => {
                                console.log(res);
                                if (!res) {
                                    showErrorToast('Razorpay SDK failed to load. Are you online?');
                                    return;
                                }

                                const options = {
                                    key: 'rzp_test_rj517rqVzWF27X', // Replace with your Razorpay Key ID
                                    amount: amountInPaise, // Amount in paise
                                    currency: planDetails.currency,
                                    name: 'InsightAI',
                                    description: planDetails.description,
                                    image: 'https://prod-bot-resource.s3.ap-south-1.amazonaws.com/darkbot%402x.png',
                                    order_id: response.data.order_id, // Use the order ID from the response
                                    // callback_url: 'https://app.insightai.in/pages/account-settings',
                                    handler: function (response) {
                                        console.log({
                                            "razorpay_payment_id": "pay_PEm7x7DldBgXqG",
                                            "razorpay_order_id": "order_PEm7ptrLRYQ8lq",
                                            "razorpay_signature": "65a56c6fc3051fb96a5e63595df91b53d060d9084455cee997833d0f51c03461"
                                        })
                                        const payload = {
                                            payment_response: response,
                                            status: 'SUCCESS'
                                        }

                                        if (response) {
                                            paymentCaptureHandler(response.razorpay_order_id, response.razorpay_payment_id, payload)
                                            .then((res=>{
                                                console.log(res)
                                            }))
                                            .catch((err)=>{
                                                console.log(err)
                                            })
                                        }
                                    },
                                    prefill: {
                                        name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                                        email: localStorage.getItem('email'),
                                        // contact: '9000090000',
                                    },
                                    notes: {
                                        planDetails: planDetails.name,
                                    },
                                    theme: {
                                        color: '#44ace4',
                                    },
                                };

                                const rzp1 = new window.Razorpay(options);
                                rzp1.open();

                                rzp1.on('payment.failed', function (response) {
                                    console.error("Payment failed:", response.error);
                                    // Handle payment failure (e.g., notify the user)
                                    // alert("Payment failed: " + response.error.description);
                                });
                            }
                        )
                        .catch((error) => {
                            console.log('Error loading Razorpay SDK:', error);
                        });

                } else {
                    showErrorToast('Some Error ')
                }
            })
            .catch(err => {
                setSalesLoader(false);
                showErrorToast("We are currently unable to process your payment. Please try again later.");
            })
    }

    return (
        <div className={planDetails.is_active ? "basic-plan-card plan-card active-plan" : "basic-plan-card plan-card"}>
            <div className='plan-card__head'>
                {/* <span className='logo-icon'></span> */}
                <h2 className='plan-card__title'>{planDetails.name || ''}</h2>
                <span className='plan-card__text'>Credits: {planDetails.credits || ''}</span>
                <span>{planDetails.description || ''}</span>
                {/* <Button color="link" style={{ padding: '0', color: 'black' }} onClick={handleOffering}>Plan Details</Button>

                {
                    showOfferings &&
                    <div className='plan-card__offerings'>
                        <ul>
                            {planDetails.features.map((feature, index) => (
                                <li key={index}>
                                    {feature.product}
                                </li>
                            ))}
                        </ul>
                    </div>
                } */}

            </div>
            <div className='plan-card__price'>
                <span className='rate'>₹{planDetails.amount || '0'} </span>
                <span className='duration'>{' for ' + planDetails.period + ' days' || '/ Month'}</span>
            </div>
            <div className='plan-card__btn-wrapper'>
                <Button
                    color="primary"
                    outline={planDetails.is_active}
                    disabled={planDetails.name === "Trial"}

                    onClick={planDetails.planName === "Enterprise" ? salesConfirmation : () => handlePayment(planDetails || null)} // Open modal on click
                >
                    {salesLoader ? (
                        <ClipLoader size={14} color='black' />
                    ) : (
                        planDetails.planName === "Enterprise" ? 'Talk to Sales' : (!planDetails.is_active ? 'Buy Now' : 'Active Plan')
                    )}
                </Button>
            </div>

            {/* Coupon Code Section (Commented out) */}
            {/* <div className='coupon-wrapper'>
                <Button color="link" onClick={handleCodeInput} className='coupon-btn'>
                    {!showInput ? "Apply Coupon" : 'Hide Coupon'}
                </Button>
                {showInput && (
                    <InputGroup>
                        <Input placeholder="Enter Code" />
                        <Button color='primary' className="apply-btn" onClick={handleApplyCode}>
                            {isApplying ? <ClipLoader color="#fff" size={15} /> : (isApplied ? <Check /> : 'Apply')}
                        </Button>
                    </InputGroup>
                )}
            </div> */}
        </div>
    );
}

export default PriceCard;
