// ContactUs.js
import React, { useState } from "react";
import ContactUsImage from "../../../../src/assets/images/illustration/contact-customer-service.png";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import "./style.scss";
import { Mail } from "react-feather";
import { PhoneCall } from "react-feather";
import PageHeader from "../../../@core/components/PageHeader";
import { createContact } from "../../../services/user";
import toast from "react-hot-toast";
import { toastStyle } from "../../../utility/helper";
import { statusCode } from "../../../utility/constants/utilObject";
import SupportAccordion from "./supportAccordion";
import { ClipLoader } from "react-spinners";

const ContactUs = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    message: "",
    query_type: ""
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.query_type) {
      errors.query_type = "Query Type is required";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    if (Object.keys(errors).length === 0) {
      // Form is valid, you can submit it here
      formData.user = localStorage.getItem("id");
      createContact(formData).then(res => {
        if (res.status == statusCode.HTTP_200_OK) {
          setLoader(false);
          toast.success("Your query has been submitted, We will get back to you as soon as possible.", { ...toastStyle.success });
        }else {
          setLoader(false);
          toast.error("An unexpected error occurred. Please try again later.", { ...toastStyle.error });
        }
    
      }).catch(err => {
        setLoader(false);
        toast.error("Failed to submit your query. Please try again later.", { ...toastStyle.error });
      });

      // Clear the form data
      setFormData(initialFormData);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 700);
      // Form has errors, update the state
      setFormErrors(errors);
    }
  };

  return (<>
    <PageHeader
      pageName={`Support`}
      backButtonEnabled={false}
    />
    <div style={{ textAlign: "center", display: "block" }}>
    </div>
    <section id="landingContact" className="contact-us">
      <h2 className="contact-us__title">Help & Support</h2>

      <div className="contact-form">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="contact-form__inputs">
            <Label for="name">
              Your Name <span className="mandatory text-danger">*</span>
            </Label>
            <Input
              type="text"
              id="name"
              name="name"
              disabled={loader}
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {formErrors.name && <span className="text-danger">{formErrors.name}</span>}
          </FormGroup>
          <FormGroup className="contact-form__inputs">
            <Label for="query_type">
              Contact Type <span className="mandatory text-danger">*</span>
            </Label>
            <select
              id="query_type"
              name="query_type"
              disabled={loader}
              className="form-control"
              value={formData.query_type}
              onChange={handleChange}
              required
            >
              <option value="">Select Query Type</option>
              <option value="GENERAL_INQUIRY">General Inquiry</option>
              <option value="FEATURE_NOT_WORKING">Feature Not Working</option>
              <option value="FEEDBACK">Feedback</option>
              <option value="PAYMENT_RELATED_ISSUE">Payment Related Issue</option>
              <option value="NEW_FEATURE_REQUEST">New Feature Request</option>
              <option value="FEATURE_NOT_WORKING">Feature Not Working</option>
              <option value="OTHER">Other</option>
            </select>
            {formErrors.query_type && (
              <span className="text-danger">{formErrors.query_type}</span>
            )}
          </FormGroup>
          <FormGroup className="contact-form__inputs">
            <Label for="email">Your Email </Label>
            <Input
              type="email"
              id="email"
              name="email"
              disabled={loader}
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              style={{ marginTop: '0px', borderRadius: '0.357rem' }}
            />
          </FormGroup>
          <FormGroup className="contact-form__inputs">
            <Label for="mobile">Your Mobile</Label>
            <Input
              type="number"
              id="phone"
              name="phone"
              disabled={loader}
              placeholder="Your Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup className="contact-form__inputs">
            <Label for="message">
              Your Message <span className="mandatory text-danger">*</span>
            </Label>
            <Input
              type="textarea"
              id="message"
              name="message"
              rows="3"
              disabled={loader}
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
              style={{ marginTop: '0px', borderRadius: '0.357rem' }}
            />
            {formErrors.message && <span className="text-danger">{formErrors.message}</span>}
          </FormGroup>
          <Button disabled={loader} color="primary" type="submit">
           {loader ? <ClipLoader color="#ffffff" /> :"Send Message"} 
          </Button>
        </Form>
      </div>
      <div className="contact-us__text-box">
        <SupportAccordion />
        <div className="support-info">

          <div className="row gy-3 gx-md-4">
            <div className=" d-flex col-md-6 ">
              <div style={{ width: "18%" }}>
                <Mail size={24} color={"green"} />
              </div>
              <div>
                <p className="mb-0">Email</p>
                <h5 className="mb-0">
                  <a href="mailto:support@insightai.in" className="text-heading">support@insightai.in</a>
                </h5>
              </div>
            </div>
            <div className="d-flex col-md-6">
              <div style={{ width: "18%" }}>
                <PhoneCall size={24} color={"green"} />
              </div>
              <div>
                <p className="mb-0">Phone</p>
                <h5 className="mb-0"><a href="tel:+917768-944-118" className="text-heading">+917768 944
                  118</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
};

export default ContactUs;