import ResponseActions from '../WizBotQueryResponse/ResponseActions';
import './index.scss';
import React, { useState, useEffect } from 'react';

const mdToHtml = (src) => {

    const rx_lt = /</g;
    const rx_gt = />/g;
    const rx_space = /\t|\r|\uf8ff/g;
    const rx_escape = /\\([\\\|`*_{}\[\]()#+\-~])/g;
    const rx_hr = /^([*\-=_] *){3,}$/gm;
    const rx_blockquote = /\n *&gt; *([^]*?)(?=(\n|$){2})/g;
    const rx_list = /\n( *)(?:[*\-+]|((\d+)|([a-z])|[A-Z])[.)]) +([^]*?)(?=(\n|$){2})/g;
    const rx_listjoin = /<\/(ol|ul)>\n\n<\1>/g;
    const rx_highlight = /(^|[^A-Za-z\d\\])(([*_])|(~)|(\^)|(--)|(\+\+)|`)(\2?)([^<]*?)\2\8(?!\2)(?=\W|_|$)/g;
    const rx_code = /\n((```|~~~).*\n?([^]*?)\n?\2|((    .*?\n)+))/g;
    const rx_link = /((!?)\[(.*?)\]\((.*?)( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))/g;
    const rx_table = /\n(( *\|.*?\| *\n)+)/g;
    const rx_thead = /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/;
    const rx_row = /.*\n/g;
    const rx_cell = /\||(.*?[^\\])\|/g;
    const rx_heading = /(?=^|>|\n)([>\s]*?)(#{1,6}) (.*?)( #*)? *(?=\n|$)/g;
    const rx_para = /(?=^|>|\n)\s*\n+([^<]+?)\n+\s*(?=\n|<|$)/g;
    const rx_stash = /-\d+\uf8ff/g;

    const replace = (rex, fn) => {
        src = src.replace(rex, fn);
    }

    const element = (tag, content, options = {}) => {
        // return '<' + tag + '>' + content + '</' + tag + '>';
        const { style, className } = options;
        let styleString = style ? ` style="${Object.entries(style).map(([key, value]) => `${key}:${value}`).join(';')}"` : '';
        let classString = className ? ` class="${className}"` : '';
        return `<${tag}${classString}${styleString}>${content}</${tag}>`;
    }

    const blockquote = (src) => {
        return src.replace(rx_blockquote, function (all, content) {
            return element('blockquote', blockquote(highlight(content.replace(/^ *&gt; */gm, ''))));
        });
    }

    const list = (src) => {
        return src.replace(rx_list, function (all, ind, ol, num, low, content) {
            var entry = element('li', highlight(content.split(
                RegExp('\n ?' + ind + '(?:(?:\\d+|[a-zA-Z])[.)]|[*\\-+]) +', 'g')).map(list).join('</li><li>')));

            return '\n' + (ol
                ? '<ol start="' + (num
                    ? ol + '">'
                    : parseInt(ol, 36) - 9 + '" style="list-style-type:' + (low ? 'low' : 'upp') + 'er-alpha">') + entry + '</ol>'
                : element('ul', entry));
        });
    }

    const highlight = (src) => {
        return src.replace(rx_highlight, function (all, _, p1, emp, sub, sup, small, big, p2, content) {
            return _ + element(
                emp ? (p2 ? 'strong' : 'em')
                    : sub ? (p2 ? 's' : 'sub')
                        : sup ? 'sup'
                            : small ? 'small'
                                : big ? 'big'
                                    : 'code',
                highlight(content));
        });
    }

    const unesc = (str) => {
        return str.replace(rx_escape, '$1');
    }

    const stash = [];
    let si = 0;

    src = '\n' + src + '\n';

    replace(rx_lt, '&lt;');
    replace(rx_gt, '&gt;');
    replace(rx_space, '  ');

    // blockquote
    src = blockquote(src);

    // horizontal rule
    replace(rx_hr, '<hr/>');

    // list
    src = list(src);
    replace(rx_listjoin, '');

    // code
    replace(rx_code, function (all, p1, p2, p3, p4) {
        stash[--si] = element('pre', element('code', p3 || p4.replace(/^    /gm, '')));
        return si + '\uf8ff';
    });

    // link or image
    replace(rx_link, function (all, p1, p2, p3, p4, p5, p6) {
        stash[--si] = p4
            ? p2
                ? '<img src="' + p4 + '" alt="' + p3 + '"/>'
                : '<a href="' + p4 + '">' + unesc(highlight(p3)) + '</a>'
            : p6;
        return si + '\uf8ff';
    });

    replace(rx_table, function (all, table) {
        var sep = table.match(rx_thead)[1];
        const tableElement = element('table',
            table.replace(rx_row, function (row, ri) {
                return row == sep ? '' : element('tr',
                    row.replace(rx_cell, function (all, cell, ci) {
                        // Applying different styles or classes to headers and data cells
                        let options = {};
                        if (sep && !ri) { // This is a header row
                            options.className = 'custom-md-table-header'; // Add a class
                            options.style = { fontWeight: 'bold', backgroundColor: '#f0f0f0' }; // Add styles
                        } else { // Data rows
                            options.className = 'md-data-class'; // Add a class
                            options.style = { textAlign: 'left' }; // Add styles
                        }
                        return ci ? element(sep && !ri ? 'th' : 'td', unesc(highlight(cell || '')), options) : '';
                    }), { className: 'custom-md-table-row' } // Additional class for rows if needed
                );
            }), { className: 'custom-md-table', style: { width: '100%', borderCollapse: 'collapse' } } // Styles for the table
        );


        // New extra component that you want to append after the table
        const extraComponent = `<div class="response-action__cta" style={display: 'flex', justify-content: 'flex-start'}>
      <button class="btn btn-outline-primary" id="download" onclick="handleCSVDownload()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
      </button>
      <div class="tooltip bs-tooltip-bottom" id="download-tooltip">Download as CSV</div>
    </div>
  `;

        // Return the table followed by the extra component
        return '\n' + tableElement + '\n' + extraComponent;
    });

    // heading
    replace(rx_heading, function (all, _, p1, p2) { return _ + element('h' + p1.length, unesc(highlight(p2))) });

    // paragraph
    replace(rx_para, function (all, content) { return element('p', unesc(highlight(content))) });

    // stash
    replace(rx_stash, function (all) { return stash[parseInt(all)] });

    return src.trim()
};


const MarkdownParser = ({ session, texts, speed = 10, isTyping = true }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        if (texts.length === 0) return;

        if (!isTyping) {
            setDisplayedText(texts.join(''));
        } else {
            const currentText = texts[currentIndex % texts.length];
            if (!currentText) return;

            if (displayedText.length < currentText.length) {
                const timeoutId = setTimeout(() => {
                    setDisplayedText(currentText.slice(0, displayedText.length + 1));
                }, speed);
                return () => clearTimeout(timeoutId);
            } else if (currentIndex < texts.length - 1) {
                // Move to the next text when the current text is fully displayed
                const timeoutId = setTimeout(() => {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    setDisplayedText("");
                }, speed * 10); // Adjust the delay before moving to the next text
                return () => clearTimeout(timeoutId);
            }
        }
    }, [currentIndex, displayedText, speed, texts]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: mdToHtml(displayedText) }} />
        </>

    )
};

export default MarkdownParser;
