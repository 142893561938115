// OverallAnalysis.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file
import { Bar } from 'react-chartjs-2'; // Import required chart component from react-chartjs-2

const OverallAnalysis = ({ summaryData, kycData, irregularitiesData, monthlyBalanceData, monthlyTransactionData }) => {

  function formatText(text) {
    // Split the camel case text using regex
    const result = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    
    // Capitalize the first letter of each word
    return result
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return (
    <div className="overall-analysis-container">
      {/* Top Summary Blocks */}
      {/* <div className="summary-blocks">
        <div className="summary-block">
          <h2>{summaryData['Financial Stability Risk Score']}</h2>
          <p>Financial Stability Risk Score</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData['Credit Limit Recommendations']}</h2>
          <p>Credit Limit Recommendations</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData['Industry risk']}</h2>
          <p>Industry Risk </p>
        </div>

        <div className="summary-block">
          <h2>{summaryData['High Risk Alert']}</h2>
          <p>High Risk Alert</p>
        </div>
        <div className="summary-block">
          <h2>₹{summaryData['MONTHLY AVG BALANCE']}</h2>
          <p>MONTHLY AVG BALANCE</p>
        </div>
      </div> */}
      <h2 style={{ fontSize:'16px', fontWeight: 'bold' }}>Credit Risk</h2>
      <div className="summary-blocks">
        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>796</h2>
          {/* <h2>{summaryData.circularTxns}</h2> */}
          <p>Credit Score</p>
        </div>
        {/* <div className="summary-block">
          <h2>₹ 3,20,000</h2>
          <p>Credit Limit Recommendations</p>
        </div> */}
        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>No defaults or late payments</h2>
          {/* <h2>{summaryData.creditScore}</h2> */}
          <p>Credit History</p>
        </div>

        <div className="summary-block">
          <h2 style={{ color: "red", fontWeight: 'bold' }}>60%</h2>
          <p>Debt-to-Income Ratio (DTI)          </p>
        </div>
        {/* <div className="summary-block">
          <h2>{summaryData.transactions}</h2>
          <p>TRANSACTIONS<br />{summaryData.txnPeriod}</p>
        </div> */}
        {/* <div className="summary-block">
          <h2 style={{ color: "red", fontWeight: 'bold' }}>DECLINE</h2>
          <p>Overall Recommendation</p>
        </div> */}
      </div>

      <h2 style={{ fontSize:'16px', fontWeight: 'bold' }}>Income Stability Risk</h2>

      <div className="summary-blocks">
        <div className="summary-block">
          <h2 style={{ color: "grey", fontWeight: 'bold' }}>Frequent job changes</h2>
          {/* <h2>{summaryData.circularTxns}</h2> */}
          <p>Employment Status</p>
        </div>
        {/* <div className="summary-block">
          <h2>₹ 3,20,000</h2>
          <p>Credit Limit Recommendations</p>
        </div> */}
        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>EMI covers 25% of monthly income</h2>
          {/* <h2>{summaryData.creditScore}</h2> */}
          <p>Income Level </p>
        </div>

        <div className="summary-block">
          <h2 style={{ color: "grey", fontWeight: 'bold' }}>Private Sector Employee</h2>
          <p>Job Type</p>
        </div>
        {/* <div className="summary-block">
          <h2>{summaryData.transactions}</h2>
          <p>TRANSACTIONS<br />{summaryData.txnPeriod}</p>
        </div> */}
        {/* <div className="summary-block">
          <h2 style={{ color: "red", fontWeight: 'bold' }}>DECLINE</h2>
          <p>Overall Recommendation</p>
        </div> */}
      </div>

      <h2 style={{ fontSize:'16px', fontWeight: 'bold' }}>Property Risk Profile</h2>

      <div className="summary-blocks">
        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>Metro in well established area</h2>
          {/* <h2>{summaryData.circularTxns}</h2> */}
          <p>Location</p>
        </div>
        {/* <div className="summary-block">
          <h2>₹ 3,20,000</h2>
          <p>Credit Limit Recommendations</p>
        </div> */}
        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>Verified</h2>
          {/* <h2>{summaryData.creditScore}</h2> */}
          <p>Asset Status </p>
        </div>

        <div className="summary-block">
          <h2 style={{ color: "green", fontWeight: 'bold' }}>Verified</h2>
          <p>Document Check</p>
        </div>
        {/* <div className="summary-block">
          <h2>{summaryData.transactions}</h2>
          <p>TRANSACTIONS<br />{summaryData.txnPeriod}</p>
        </div> */}
        {/* <div className="summary-block">
          <h2 style={{ color: "red", fontWeight: 'bold' }}>DECLINE</h2>
          <p>Overall Recommendation</p>
        </div> */}
      </div>
      

      {/* KYC Details and Irregularities */}
      <div className="details-row">
        <div className="details-block">
          <h3>KYC Details</h3>
          <table className="details-table">
            <tbody>
              {Object.entries(kycData).map(([key, value], index) => (
                <tr key={index}>
                  <td className="details-field">{formatText(key)}</td>
                  <td className="details-value">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="details-block">
          <h3>Irregularities</h3>
          <table className="details-table">
            <thead>
              <tr>
                <th>Activity</th>
                <th>Incidences</th>
              </tr>
            </thead>
            <tbody>
              {irregularitiesData.map((item, index) => (
                <tr key={index}>
                  <td>{item.activity}</td>
                  <td>{item.incidences}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Monthly Average Balance and Monthly Transactions Comparison
      <div className="charts-row">
        <div className="chart-block">
          <h3>Month-wise Average Balance (₹)</h3>
          <Bar
            data={{
              labels: monthlyBalanceData.map((data) => data.month),
              datasets: [
                {
                  label: 'Average Balance (₹)',
                  data: monthlyBalanceData.map((data) => data.avgBalance),
                  backgroundColor: '#4BC0C0',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Average Balance (₹)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="chart-block">
          <h3>Monthly Deposits & Withdrawals (₹)</h3>
          <Bar
            data={{
              labels: monthlyTransactionData.map((data) => data.month),
              datasets: [
                {
                  label: 'Deposits',
                  data: monthlyTransactionData.map((data) => data.deposits),
                  backgroundColor: '#7CB342',
                  borderRadius: 5,
                  barThickness: 12,
                },
                {
                  label: 'Withdrawals',
                  data: monthlyTransactionData.map((data) => data.withdrawals),
                  backgroundColor: '#FF9800',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Amount (₹)',
                  },
                },
              },
            }}
          />
        </div>
      </div> */}
    </div>
  );
};

export default OverallAnalysis;
