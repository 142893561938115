import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { transactionData } from "../../Underwriting/testingData";
import { InfinitySpin } from "react-loader-spinner";
const DataTab = () => {
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    // Calculate the number of pages
    const totalPages = Math.ceil(transactionData.length / itemsPerPage);

    // Get data for the current page
    const currentData = transactionData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Handle page change
    const handlePageClick = (page) => {
        setCurrentPage(page);
    };
    return (
        <div className="data-table">
            <div className="transaction-table-wrapper" style={{ marginTop: '20px' }}>
                <table className="transaction-table">
                    <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Cheque/Ref nbr</th>
                            <th>Counterparty</th>
                            <th>Debit (₹)</th>
                            <th>Credit (₹)</th>
                            <th>Balance (₹)</th>
                            <th>Computed Balance (₹)</th>
                            <th>Category</th>
                            <th>Tags</th>
                            <th>UPI App</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.particulars}</td>
                                <td>{transaction.chequeRefNbr}</td>
                                <td>{transaction.counterparty}</td>
                                <td>{transaction.debit || '-'}</td>
                                <td>{transaction.credit || '-'}</td>
                                <td>{transaction.balance}</td>
                                <td>{transaction.computedBalance}</td>
                                <td>{transaction.category}</td>
                                <td>{transaction.tags}</td>
                                <td>{transaction.upiApp}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="pagination-wrapper">
                <Pagination>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <PaginationItem key={i} active={i === currentPage}>
                            <PaginationLink onClick={() => handlePageClick(i)}>
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                </Pagination>
            </div>
        </div>
    )
}

export default DataTab;