import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation} from 'react-router-dom';
import { Inbox, Link2, Layout, ExternalLink, X, Trash, Minimize2, Maximize2 } from 'react-feather';
import PageHeader from "../../@core/components/PageHeader";
import botImage from "../../assets/images/bot/darkbot@2x.png";
import AnalyticsDBDummy from "../../assets/icons/dashboard_sample.png";
import DBSchema from "./DBSchema";
import DBQueryResponse from "../components/DBQueryResponse";
import { Card } from "reactstrap";
import { fetchOrganisationData } from "../../redux/actions";
import { connect } from "react-redux";
import { 
  loadSessionMessagesHandler,
} from "../../services/documentQnA";
import { fetchPreviousChatHandler } from "../../services/dbConnect";
import {
  fetchSessionDbSchemaHandler,
  fetchDbQueryResponseHandler
} from "../../services/dbConnect";
import '../GenerativeAISolution/ChatBot/chatInterface.css';
import TypingEffect from '../components/typingEffect'
import CustomTabs from "../components/CustomTabs";
import MarkdownParser from "../components/MarkdownTypingEditor"
import { statusCode } from "../../utility/constants/utilObject";
import { fetchUploadedDocumentDetails } from "../../utility/docFunctions";
import { storeLastUrl } from "../../utility/Utils";
import { getCurrentDate, renderDocumentIcon, showErrorToast, showSuccessToast, toTitleCase, truncateStringToNPlace } from "../../utility/helper";
import ChartInChat from "../components/ChartsModal/ChartInChat";

const DBChatSession = () => {
  const { session } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const propData = location.state;

  const firstName = localStorage.getItem("first_name");

  // Data variable
  const [messages, setMessages] = useState([]); // These messages are loaded from th backend
  const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
  const [message, setMessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [sessionId, setSessionId] = useState(session);
  const [schema, setSchema] = useState(propData && propData.schema? propData.schema: null);
  const [connectionType, setConnectionType] = useState(propData && propData.connection_type? propData.connection_type: null);
  const fileInputRef = useRef(null);

  // Analytics DB
  const [analyticsDBPopup, setAnalyticsDBPopup] = useState(false);
  const [analyticsDbPopupClosedByUser, setAnalyticsDbPopupClosedByUser] = useState(false);
  const [analyticsQueryCollection, setAnalyticsQueryCollection] = useState([]);
  const [isPopupMinimized, setIsPopupMinimized] = useState(false);
  // Format: { query: "", response_id: "", query_id: "" }

  // Loaders
  const [documentLoader, setDocumentLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [userMessageLoader, setUserMessageLoader] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [isChatLoaded, setIsChatLoaded] = useState(true);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(true);
  const [isChatInputEnabled, setIsChatInputEnabled] = useState(true);

  // Reference Variables
  const documentUploadRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isUserInputFocused, setIsUserInputFocused] = useState(false);

   "Storing the URL to Local"
  "============================"

  useEffect(() => {
    storeLastUrl(location.pathname);

  }, [location]);


  "Initali Load of the document"
  "============================"
  useEffect(() => {
    if(connectionType === 'NEW'){

    }else{
      fetchSchema()
      loadChatMessages();
    }
    messageFormattor();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messagesList]);

  const isSessionIdValid = () => {
    return sessionId && sessionId !== 'new-session';
  }


  "Hanlders for schema"
  "============================"

  const fetchSchema = () => {
    fetchSessionDbSchemaHandler(sessionId)
    .then(response => {
      if(response.status == statusCode.HTTP_200_OK) {
        setSchema(response.data.schema);
      }else{
        showErrorToast('Error loading schema. Please refresh the page and try again');
      }
    })
  }

  "Hanlders for chat messages"
  "=========================="

  const randomAlphaNumeric = (length = 6) => {
    let s = '';
    Array.from({ length }).some(() => {
      s += Math.random().toString(36).slice(2);
      return s.length >= length;
    });
    return s.slice(0, length);
  };

  
  const scrollToBottom = () => {
    const scroll = chatContainerRef.current;
    if (scroll) {
      scroll.scrollTo({
        top: scroll.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottomInstant = () => {
    const scroll = chatContainerRef.current;
    if (scroll) scroll.scrollTop = scroll.scrollHeight;
  };

  const loadChatMessages = () => {
    fetchPreviousChatHandler(sessionId)
    .then(response => {
      setLoaderMessage(null);
      setIsChatLoaded(true);
      if(response.status == statusCode.HTTP_200_OK) {
        loadPreviousMessages(response.data);
      }else{
        addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.');
      }
    })
    .catch((error) => {
      setLoaderMessage(null);
      setIsChatLoaded(true);
      console.error('Error:', error);
      addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.');
    })
  }


  const handleSendMessage = async (e) => {
    e.preventDefault();
    const input = documentUploadRef.current.value;
    documentUploadRef.current.value = "";
    setIsUserInputFocused(false);
    if (input.trim() !== "") {
      addNewUserMessage(input.trim());
      if(isSessionIdValid()){
        fetchBotResponse(input.trim());
      }else{
        addNewBotMessage("Please upload a document first to get started. Thanks! 📄✨");
      }
    }
  };

  const handleSQLMessage = (sqlMessage) => {
    const input = sqlMessage;

    setIsUserInputFocused(false);
    if (input.trim() !== "") {
      addNewUserMessage(input.trim());
      if(isSessionIdValid()){
        fetchBotResponse(input.trim());
      }else{
        addNewBotMessage("Please upload a document first to get started. Thanks! 📄✨");
      }
    }
  };

  const fetchBotResponse = (query) => {
    fetchDbQueryResponseHandler({
      session: sessionId,
      user_query: query
    })
    .then((response) => {
      setLoaderMessage(null);
      if(response.status == statusCode.HTTP_200_OK) {
        addBotResponseToUser(response.data);
        if(analyticsQueryCollection.length < 3){
          const queryArray = [...analyticsQueryCollection];
          queryArray.push({ query: query, response_id: response.data.response_id, query_id: response.data.query_id });
          setAnalyticsQueryCollection(queryArray);
          setAnalyticsDBPopup(true);
        }
      }else{
        addBotResponseToUser("I am currently facing some issues. Please try again later.");
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoaderMessage(null);
      addBotResponseToUser("I am currently facing some issues. Please try again later.");
    });
  }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();  // Prevent the default behavior of a line break
      handleSendMessage(event);  // Replace this with your actual send message function
      documentUploadRef.current.value = "";
    }
  };

  const handleFocus = () => setIsUserInputFocused(true);

  const handleBlur = () => setIsUserInputFocused(false);

  const addBotMessageWithMarkdown = (message) => {
    setMessagesList([
      ...messagesList,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
             style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
               src={botImage}
               alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownRenderer markdownText={message} />
        </div>
      </div>
    ]);
  }

  const addNewBotMessageWithoutTyping = (botMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
              style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
                src={botImage}
                alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownParser texts={[botMessage]} isTyping={false}/>
          <ChartInChat />
        </div>
      </div>
    ]);
  }

  const addNewBotMessage = (botMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
              style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
                src={botImage}
                alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownParser texts={[botMessage]} />
        </div>
      </div>
    ]);
  }

  const addNewBotMessageWithTableResponse = (botMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
              style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
                src={botImage}
                alt={""} />
        </div>
        <div className="message bot-message">
          <DBQueryResponse response={botMessage} session={sessionId} handleSQLMessage={handleSQLMessage} />
        </div>
      </div>
    ]);
  }

  const addBotResponseToUser = (botMessage) => {
    addNewBotMessageWithTableResponse(botMessage);
  }

  const addOldUserMessage = (userMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()}>
        <div className={`message user-message`}>
          <div>{userMessage}</div>
        </div>
        <div className={"user-message-date"}>{getCurrentDate()}</div>
      </div>
    ]);
  }

  const addNewUserMessage = (userMessage) => {
    setMessagesList((prevMessages) => {
        const updatedMessages = [...prevMessages, 
          <div key={randomAlphaNumeric()}>
            <div className={`message user-message`}>
              <div>{userMessage}</div>
            </div>
            <div className={"user-message-date"}>{getCurrentDate()}</div>
          </div>]
        return updatedMessages;
    });
    
    scrollToBottomInstant();
    if(isSessionIdValid()) {
      setLoaderMessage('Please wait while I fetch the response for your query');
    }
  }

  const messageFormattor = () => {
    if(!connectionType && messagesList.length === 0 && isChatLoaded){
      addNewBotMessage(`Welcome back!!👋 ${firstName}`);
      setLoaderMessage('Please wait while I fetch your previous chat history');
    }else if(isSessionIdValid() && messagesList.length > 0){

    }else if(connectionType === 'NEW'){
      addNewBotMessage(`Welcome!!👋 ${firstName}. I am your personal assistant.`);
      const responseTimeout = setTimeout(() => {
        addNewBotMessage(`Ask any question to your database! 😇.`);
        clearTimeout(responseTimeout);
      }, 1500);
    }
  }

  const loadPreviousMessages = (loadedMessages) => {
    //Remove only the last message in the messageList
    loadedMessages.Data.forEach((messageItem)=>{
      if(messageItem.is_bot){ 

        // addNewBotMessageWithoutTyping(messageItem.message);
        if(messageItem.query_response){
          messageItem.data = JSON.parse(messageItem.query_response)
        }
        addNewBotMessageWithTableResponse(messageItem);
        

      }else{ addOldUserMessage(messageItem.message); }
    })
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
      clearTimeout(scrollTimeout);
    }, 500);
  }

  "Tab Panels"
  "=========================="

  const renderDocumentTabPanel = () => {
    if(schema){
      return <DBSchema 
        schemaData={schema}
      />
    }else{
      return (
        <div>Loading schema...</div>
      )
    }
  }

  const closeAnalyticsPopup = () => {
    setAnalyticsDBPopup(false);
    setAnalyticsDbPopupClosedByUser(true);
  }

  const removeQueryFromCollection = (index) => {
    const queryArray = [...analyticsQueryCollection];
    queryArray.splice(index, 1);
    setAnalyticsQueryCollection(queryArray);
  }

  const renderQueryInput = () => {
    const queryArray = [...analyticsQueryCollection]
    const queryDivAarray = []
    for(let i=0; i<3; i++){
      if(i < queryArray.length){
        queryDivAarray.push(
          <div key={i} className="analytics_db_query_container query_added">
            { truncateStringToNPlace(queryArray[i].query, 25)}
            <Trash onClick={()=>{removeQueryFromCollection(i)}} size={16} style={{ marginLeft: '10px', cursor: 'pointer' }} />
          </div>
        )
      }else{
        queryDivAarray.push(
          <div key={i} className="analytics_db_query_container">
            <div className="add_query">Add next query</div>
          </div>
        )
      }
    }
    return queryDivAarray
  }

  const resizePopup = () => {
    setIsPopupMinimized(!isPopupMinimized);
  }

  const renderAnalyticDashboardFloatingPopup = () => {
    if(!analyticsDbPopupClosedByUser && analyticsDBPopup){
      return (<div className={`analytics-floating-progress slide-in-right ${isPopupMinimized?'minimized':''}`}>
        <div className={`analytics-floating-progress__header ${isPopupMinimized?'minimized_header':''}`}>
            <button className="redirect_to_dashboard" disabled = {analyticsQueryCollection.length < 3} onClick={()=>{navigate('/analytics-dashboard/1')}}>
              View Dashboard <ExternalLink size={16} style={{ marginBottom: '5px' }} />
            </button>
            <div style={{display: 'flex', justifyContent: 'end'}}>
              {
                !isPopupMinimized?
                <div className="close_popup" onClick={resizePopup}>
                  <Minimize2 size={16}/>
                </div>:
                <div className="close_popup" onClick={resizePopup}>
                  <Maximize2 size={16}/>
                </div>
              }
              <div className="close_popup" onClick={closeAnalyticsPopup}>
                <X size={16}/>
              </div>
            </div>
        </div>
        {
          !isPopupMinimized?
          <div className="analytics-floating-progress__query_container">
            <div className="header"> 
              {
                analyticsQueryCollection.length === 3?
                "Your analytics dashboard is ready":
                `Just ${3 - analyticsQueryCollection.length} more queries and your analytics dashboard is ready`
              }
            </div>
            {renderQueryInput()}
            <div className="fade-in" style={{ position: "fixed", bottom: "15%", textAlign: 'center', width: '260px'}}>
              <img src={AnalyticsDBDummy} style={{maxHeight:'20vh', display: 'inline-block'}}/>
            </div>
          </div>:null
        }
      </div>)
    }
  }


  const handleAnalyticDBPopup = () =>{
    setAnalyticsDBPopup(prev => !prev);
    setAnalyticsDbPopupClosedByUser(prev => !prev)
  }
  
  return (
    <div>
      <PageHeader
        pageName={"Database Intelligence"}
        backButtonEnabled={false}
      />
      <div className="db-intelligience-chat">
          <div className='main-container   padding_0'>
            <div className="left-panel">
                <div className="left-panel__header">Schema</div>
                {renderDocumentTabPanel()}
            </div>
          <div className="chatbot-section live-chat right-panel">
          <div className="chatbot-section__top">
              <div style={{
                height:'18px',
                fontSize: '17px',
                fontWeight: 'bold',
              }}>{schema? toTitleCase(schema.database) : "Database"}</div>
              
              <div style={{display: 'flex'}}>
                {/* <div 
                  onClick={ handleAnalyticDBPopup }
                  // onClick={()=>{navigate('/database-intelligence')}}

                  className="sectionHeaderBtn" style={{marginRight:'10px'}}>
                    <Layout size={16} style={{ marginRight: '10px' }} />
                    Create Analytics Dashboard
                </div> */}
                <div 
                  onClick={()=>{navigate('/database-intelligence')}}
                  className="sectionHeaderBtn" style={{marginRight:'10px'}}>
                    <Link2 size={16} style={{ marginRight: '10px' }} />
                    Add New Database
                </div>
                <div 
                  onClick={()=>{navigate('/database-intelligence/previous-connections/')}}
                  className="sectionHeaderBtn">
                    <Inbox size={16} style={{ marginRight: '10px' }} />
                    Show Previous Connections
                </div>
              </div>
            </div>
            <div 
              ref={chatContainerRef}
              className="chat-pannel"
              style={{
                
                paddingTop: "15px",
              }}
            >
              { messagesList }
              { loaderMessage &&
                <div key={"9890809"} className={"bot-image-text-container"}>
                  <div className={"bot-image-container"}
                        style={{ background: "#90CAF9" }}>
                    <img className={"bot-image"}
                          src={botImage}
                          alt={""} />
                  </div>
                  <div className="message bot-message">
                    <TypingEffect 
                      texts={[loaderMessage]}
                      addLoader={true}
                    />
                  </div>
                </div>
              }
            </div>
            {renderAnalyticDashboardFloatingPopup()}
            <div className="chat-bot-input-wrapper">
                <form className={`chat-bot-input-container ${isUserInputFocused ? "focused" : ""}`}
                      onSubmit={handleSendMessage} onKeyPress={handleKeyPress}>
                  <input
                    className={"chat-bot-input"}
                    type="text"
                    placeholder="Ask any question pertaining to your document...."
                    disabled={!isChatInputEnabled}
                    autoFocus={true}
                    ref={documentUploadRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <button className="send-button" type={"submit"}>
                    <i color={"#7169e8"} className="arrow-icon"></i>
                  </button>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DBChatSession);