// ** Icons Import
import Dashboard from "../../assets/logo/fintech-dashboard.png";
import Knowledge from "../../assets/logo/Knowledge.png";
import Ledger from "../../assets/icons/ledger.png";
import Bot from "../../assets/images/bot/robot.png";
import Support from '../../assets/logo/support.png';
import DB from "../../assets/icons/DB.png";
import Profile from "../../assets/images/icons/user.png";
import Document from "../../assets/images/business_13558344.png";
import DatabaseConnect from "../../assets/icons/database_connect.png";



const navIcon = (source) => (
  <img src={source} style={{ width: "20px", height: "20px" }} alt={''}/>
)

let dashboard = [
  {
    id: 'cs-dashbaord',
    title: 'Dashboard',
    icon: navIcon(Dashboard),
    navLink: '/dashboard',
  },
  {
    id: 'ledger-and-books',
    title: 'WizDoc',
    icon: navIcon(Document),
    navLink: '/wizDoc/new-session'
  },
  {
    id: 'db-intelligence',
    title: 'DB Intelligence',
    icon: navIcon(DB),
    navLink: '/database-intelligence'
  },
  {
    id: 'search-query',
    title: 'WizBot',
    icon: navIcon(Bot),
    navLink: '/wizBot'
  },
  {
    id: 'support',
    title: 'Support',
    icon: navIcon(Support),
    navLink: '/support',
  },
  {
    id: 'profile',
    title: 'Profile',
    icon: navIcon(Profile),
    navLink: '/pages/account-settings',
  }
]

export default dashboard;
