import { useState } from 'react';
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, ButtonGroup } from "reactstrap";

const ContactModal = ({ open, toggle, title, submit }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Here you can handle the form submission, e.g., send the data to an API
        const formData = {
            name,
            email,
            phone,
            message
        };

        console.log('Form Submitted:', formData);

        // Reset form fields after submission (optional)
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');

        // Close the modal after submission
        toggle();
    };
    return (
        <>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader style={{ justifyContent: 'center', fontSize: '26px' }}>
                    Talk to our Executive
                </ModalHeader>
                <ModalBody style={{ padding: '30px 20px 50px' }}>
                    <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <FormGroup>
                            <Input id="name"
                                name="name"
                                placeholder="Your Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required // Making the field required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input id="email"
                               name="email"
                               placeholder="Your Email"
                               type="email"
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               required
                                style={{ marginTop: '0', borderRadius: '0.357rem', border: '1px solid #d8d6de' }}

                            />
                        </FormGroup>
                        <FormGroup>
                            <Input id="phone"
                               name="phone"
                               placeholder="Your Mobile number"
                               type="text"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value)}
                               required />
                        </FormGroup>
                        <FormGroup>
                            <Input id="user-msg"
                                name="user-msg"
                                placeholder="Your Message"
                                type="textarea"
                                style={{ marginTop: '0', borderRadius: '0.357rem', border: '1px solid #d8d6de' }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </FormGroup>

                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            <Button color="primary" type="submit" style={{marginRight:'5px'}}>Submit</Button>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ContactModal;