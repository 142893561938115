import React, { useState, useEffect } from "react";
import "./style.scss";
import { useNavigate, Link } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Card,
    Nav, NavItem, NavLink, TabContent, TabPane, Progress, Tooltip
} from 'reactstrap';
import { FaTelegram, FaWhatsapp, FaGlobe } from "react-icons/fa";
import { listAllChatBotHandler } from "../../services/chatBot";
import PageHeader from "../../@core/components/PageHeader";
import CustomeTable from "../components/CustomTable";
import { showErrorToast, showSuccessToast } from "../../utility/helper";
import { statusCode } from "../../utility/constants/utilObject";
import { set } from "lodash";
import { X } from "react-feather";
import WhatsAppTable from "./WhatsappBot/whatsAppTable";
import Banner from "../components/banner";
import ContactModal from "../components/ContactModal";

const ChatBot = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('1');
    const [tooltipOpen, setTooltipOpen] = useState(false);


    // Data loaders
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const allowedColumns = ['serialNo', 'name', 'created_at'];
    const handleNewBot = () => { setShowModal(true) }
    const [showModal, setShowModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const handleWebBot = () => {
        navigate('/wizBot/new-bot');
        setShowModal(false);
    }
    const handleWhatsAppBot = () => {
        // navigate('/wizBot/whatsApp-bot');
        setShowContactModal(true);
        setShowModal(false);
    }

    useEffect(() => {
        fetchAllUserBots();
    }, []);

    const renderBotModal = () => {
        if (showModal) {
            return (
                <Modal style={{ zIndex: '100' }} isOpen={showModal} className="bot-modal">
                    <ModalHeader>Select Bot Type
                        {/* <Button color="link"><X /></Button> */}
                    </ModalHeader>

                    <ModalBody>
                        <button className="bot-btn web" onClick={handleWebBot}><div className="bot-btn__wrapper"><FaGlobe /> Website Bot</div></button>
                        <button className="bot-btn wtsapp" onClick={handleWhatsAppBot} ><div className="bot-btn__wrapper"><FaWhatsapp /> WhatsApp Bot</div></button>
                        <button className="bot-btn tele" disabled><div className="bot-btn__wrapper"><FaTelegram /> Telegram Bot</div></button>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    const fetchAllUserBots = () => {
        setLoading(true);
        listAllChatBotHandler()
            .then((response) => {
                setLoading(false);
                if (response.status == statusCode.HTTP_200_OK) {
                    setData(response.data.data);
                    // showSuccessToast("Loaded all Bots successfully.")
                } else {
                    setData([]);
                    // showErrorToast("Failed to load Bots.")
                }
            })
            .catch((error) => {
                setLoading(false);
                setData([]);
                showErrorToast("Failed to load Bots.")
            })

    }

    // Function to toggle active tab
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    //progress bar
    const maxStorage = 100; // Maximum storage in MB
    const usedStorage = 80; // Used storage in MB
    const remainingStorage = maxStorage - usedStorage;

    return (
        <div>
            <PageHeader
                pageName={"ChatBot Dashboard"}
                backButtonEnabled={false}
            />
            {/* <Banner /> */}
            <Card className="chatBotContainer">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                        width: '100%',
                        marginBottom: '15px'
                    }}>
                        <div className="tab-Wrapper">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '1' ? 'active' : ''}
                                        onClick={() => toggleTab('1')}
                                    >
                                        WebsiteBot
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <div id="WhatsAppBotTooltipWrapper">
                                        <NavLink
                                            className={activeTab === '2' ? 'active' : ''}
                                            onClick={() => toggleTab('2')}
                                            disabled
                                            style={{ pointerEvents: 'none', opacity: 0.5 }}
                                        >
                                            WhatsAppBot
                                        </NavLink>
                                    </div>
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen}
                                        target="WhatsAppBotTooltipWrapper"
                                        toggle={toggleTooltip}
                                    >
                                        This feature is for Premium Users!
                                    </Tooltip>
                                </NavItem>
                            </Nav>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '30%',
                            justifyContent: 'flex-end'
                        }}>
                            {/* <div className="progress-wrapper" style={{
                                width: '50%',
                            }}>
                                <span style={{
                                    fontSize: '12px'
                                }}>Your availble storage: {remainingStorage}MB / {maxStorage}MB</span>
                                <Progress
                                    className=""
                                    color={usedStorage >= '90' ? "danger" : "primary"}
                                    style={{
                                        width: '100%',
                                        height: '5px',
                                        marginTop: '8px',
                                        marginBottom: '16px'
                                    }}
                                    value={(usedStorage / maxStorage) * 100}
                                />
                            </div> */}
                            <Button color="primary" onClick={handleNewBot}> Create New Bot</Button>

                        </div>

                    </div>
                    <TabContent activeTab={activeTab} className="chatBot-profile__tabcontent">
                        <TabPane tabId="1" className="chatBot-profile__tabpane">
                            <CustomeTable
                                data={data}
                                loading={loading}
                                allowedColumns={allowedColumns}
                                singleRedirectionLink='/wizBot/'
                                errResponse='No Bot found.'
                                loadingText='Loading your Bots'
                                redirectionLink="/wizBot/new-bot"
                                redirectTitle="Click here to create a new Bot."
                            />
                        </TabPane>

                        <TabPane tabId="2" className="chatBot-profile__tabpane">
                            <WhatsAppTable />
                        </TabPane>

                    </TabContent>

                </div>

            </Card >
            {renderBotModal()}
            <ContactModal open={showContactModal} toggle={()=>setShowContactModal(prev => !prev)}/>
        </div >
    )
}

export default ChatBot;