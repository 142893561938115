import React, { useEffect, useRef, useState } from "react";
import * as XLSX from 'xlsx';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from "reactstrap";
import { fetchOrganisationData } from "../../../../redux/actions";
import PageHeader from "../../../../@core/components/PageHeader";
import CustomTabs from "../../../components/CustomTabs";
import { connect } from "react-redux";
import MultiStepLoader from "../../../components/MultiStepLoader";
import { statusCode } from "../../../../utility/constants/utilObject";
import { BarLoader, ClipLoader } from "react-spinners";
import {
  uploadStatementHandler,
  fetchSingleStatementAnalysisHandler,
  fetchStatementAnalysisHandler
} from "../../../../services/ledger";
import RenderMD from "../../../components/MarkdownTypingEditor/indexRaw";
import Pusher from "pusher-js";
import { generateAlphanumId } from "../../../../utility/helper";
import { showErrorToast } from "../../../../utility/helper";
import "./index.scss";
import SidebarDashboard from '../../../Dashboard/Sidebar';

import { initiateNewSockerConnection } from "../../../../utility/socket";

const STATEMENT_ANALYSIS_CATEGORIES = {
  "BALANCE_SHEET": "BALANCE_SHEET",
  "INCOME_STATEMENT": "INCOME_STATEMENT",
  "CASH_FLOW": "CASH_FLOW",
}


const FinancialStatamentAnalysis = (props) => {
  const { state } = useLocation();
  const { document } = useParams();
  const navigate = useNavigate();

  const file = state && state.file ? state.file : null;

  // Data Variables
  const [multiLoading, setMultiloading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [socketId, setSocketId] = useState(null);
  const [statementId, setStatementId] = useState(document ? document : null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Statement analysis dataset
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [incomeStatementData, setIncomeStatementData] = useState(null);
  const [cashFlowData, setCashFlowData] = useState(null);

  // Pusher Variables
  const [pusher, setPusher] = useState(null);
  const [channel, setChannel] = useState(null);
  const [analyisStatus, setAnalysisStatus] = useState(null);
  const [balanceSheetStatus, setBalanceSheetStatus] = useState(null);
  const [incomeStatementStatus, setIncomeStatementStatus] = useState(null);
  const [cashFlowStatus, setCashFlowStatus] = useState(null);

  // Loader variable
  const [isLoading, setIsLoading] = useState({
    "BALANCE_SHEET": false,
    "INCOME_STATEMENT": false,
    "CASH_FLOW": false,
  });
  const [activeStep, setActiveStep] = useState(0);

  const reloadWithId = (id) => {
    navigate(`/financial-statement-analytics/${id}`)
  }

  useEffect(() => {
    if (document == 'new-analysis') {
      if (!file) {
        showErrorToast('No file found');
      } else {
        setMultiloading(true)
        const socketId = generateAlphanumId('insig', 10);
        setSocketId(socketId);
        
        initiateSockerConnection(socketId);
        // initiateNewSockerConnection(socketId, [
        //   {
        //     event: "analysis",
        //     callback: (data) => {
        //       console.log('Analysis event received:', data);
        //       // Your logic here
        //     }
        //   },
        //   {
        //     event: "balance_sheet",
        //     callback: (data) => {
        //       console.log('Balance sheet event received:', data);
        //       // Your logic here
        //     }
        //   },
        //   {
        //     event: "cash_flow",
        //     callback: (data) => {
        //       console.log('Cash flow event received:', data);
        //       // Your logic here
        //     }
        //   },
        //   {
        //     event: "income_statement",
        //     callback: (data) => {
        //       console.log('Income statement event received:', data);
        //       // Your logic here
        //     }
        //   }
        // ]);
        

        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('document', file.file);
        formData.append('data_type', 'FINANCIAL_STATEMENT');
        formData.append('document_type', 'PDF');

        uploadStatementHandler(formData, socketId)
          .then((response) => {
            if (response.status == statusCode.HTTP_201_CREATED) {
              console.log('response', response)

              setStatementId(response.data.id);
              reloadWithId(response.data.id)
              nextStep();
            } else {
              showErrorToast('Error uploading file');
              setMultiloading(false);
            }
          }).catch((err) => {
            showErrorToast('Error uploading file');
            setMultiloading(false);
          });
      }
    } else {
      setMultiloading(false);
      fetchPastStatamentAnalysis(document);
    }
  }, []);

  const getStatementId = (message) => {
    const messageSplit = message.split(':');
    if (messageSplit.length > 1) {
      return messageSplit[1];
    }
  }

  const fetchPastStatamentAnalysis = (statementId) => {
    setIsLoading(prevState => ({
      ...prevState,
      "BALANCE_SHEET": true,
      "INCOME_STATEMENT": true,
      "CASH_FLOW": true,
    }));
    fetchStatementAnalysisHandler(statementId)
      .then((response) => {
        if (response.status == statusCode.HTTP_200_OK) {
          if (response.data.balance_sheet) {
            setBalanceSheetData(response.data.balance_sheet);
            setIsLoading(prevState => ({
              ...prevState,
              "BALANCE_SHEET": false,
            }));
          } else {
            setBalanceSheetData('NO_DATA');
          }

          if (response.data.income_statement) {
            setIncomeStatementData(response.data.income_statement);
            setIsLoading(prevState => ({
              ...prevState,
              "INCOME_STATEMENT": false,
            }));
          } else {
            setIncomeStatementData('NO_DATA');
          }

          if (response.data.cash_flow) {
            setCashFlowData(response.data.cash_flow);
            setIsLoading(prevState => ({
              ...prevState,
              "CASH_FLOW": false,
            }));
          } else {
            setCashFlowData('NO_DATA');
          }
        } else {
          showErrorToast('Error fetching analysis');
          setIsLoading(prevState => ({
            ...prevState,
            "BALANCE_SHEET": false,
            "INCOME_STATEMENT": false,
            "CASH_FLOW": false,
          }));
        }
      }).catch((err) => {
        showErrorToast('Error fetching analysis');
        setIsLoading(prevState => ({
          ...prevState,
          "BALANCE_SHEET": false,
          "INCOME_STATEMENT": false,
          "CASH_FLOW": false,
        }));
      });
  }

  const fetchStatementAnalysis = (statementId, analysisType) => {
    if (!statementId) {
      showErrorToast('No statement found');
      return;
    }
    setIsLoading(prevState => ({
      ...prevState,
      "BALANCE_SHEET": true,
      "INCOME_STATEMENT": true,
      "CASH_FLOW": true,
    }));
    fetchSingleStatementAnalysisHandler(statementId)
      .then((response) => {
        if (response.status == statusCode.HTTP_200_OK) {
          if (analysisType == STATEMENT_ANALYSIS_CATEGORIES.BALANCE_SHEET) {
            setBalanceSheetData(response.data.data);
            setIsLoading(prevState => ({
              ...prevState,
              "BALANCE_SHEET": false,
            }));
          } else if (analysisType == STATEMENT_ANALYSIS_CATEGORIES.INCOME_STATEMENT) {
            setIncomeStatementData(response.data.data);
            setIsLoading(prevState => ({
              ...prevState,
              "INCOME_STATEMENT": false,
            }));
          } else if (analysisType == STATEMENT_ANALYSIS_CATEGORIES.CASH_FLOW) {
            setCashFlowData(response.data.data);
            setIsLoading(prevState => ({
              ...prevState,
              "CASH_FLOW": false,
            }));
          }
        } else {
          showErrorToast('Error fetching analysis');
          setIsLoading(prevState => ({
            ...prevState,
            "BALANCE_SHEET": false,
            "INCOME_STATEMENT": false,
            "CASH_FLOW": false,
          }));
        }
        closeSocketConnection();
      }).catch((err) => {
        showErrorToast('Error fetching analysis');
        setIsLoading(prevState => ({
          ...prevState,
          "BALANCE_SHEET": false,
          "INCOME_STATEMENT": false,
          "CASH_FLOW": false,
        }));
      });
  }

  const initiateSockerConnection = (socketId) => {
    const pusherInstance = new Pusher('56fd8b1ba5df4b243794', { cluster: 'ap2' });
    const channelInstance = pusherInstance.subscribe(socketId);
    console.log('im called', channelInstance)

    channelInstance.bind('analysis', (data) => {
      console.log('data', data)
      channelInstance.unbind('analysis');
      setAnalysisStatus(true);
      nextStep();
    });
    channelInstance.bind('balance_sheet', (data) => {
      console.log('data 2', data)

      setBalanceSheetStatus(true);
      nextStep();
      fetchStatementAnalysis(getStatementId(data), STATEMENT_ANALYSIS_CATEGORIES.BALANCE_SHEET);
      channelInstance.unbind('balance_sheet');
      const closeTimeout = setTimeout(() => {
        setMultiloading(false);
        closeSocketConnection();
        clearTimeout(closeTimeout);
      }, 500);
    });
    channelInstance.bind('cash_flow', (data) => {

      setCashFlowStatus(true);
      nextStep();
      fetchStatementAnalysis(getStatementId(data), STATEMENT_ANALYSIS_CATEGORIES.CASH_FLOW);
      channelInstance.unbind('cash_flow');
      const closeTimeout = setTimeout(() => {
        setMultiloading(false);
        closeSocketConnection();
        clearTimeout(closeTimeout);
      }, 500);
    });
    channelInstance.bind('income_statement', (data) => {
      console.log("income_statement", data);
      setIncomeStatementStatus(true);
      nextStep();
      fetchStatementAnalysis(getStatementId(data), STATEMENT_ANALYSIS_CATEGORIES.INCOME_STATEMENT);
      channelInstance.unbind('income_statement');
      const closeTimeout = setTimeout(() => {
        setMultiloading(false);
        closeSocketConnection();
        clearTimeout(closeTimeout);
      }, 500);
    });
    setPusher(pusherInstance);
    setChannel(channelInstance);
  }

  const closeSocketConnection = () => {
    if (analyisStatus && balanceSheetStatus && incomeStatementStatus && cashFlowStatus) {
      try {
        pusher.unsubscribe(socketId);
        pusher.disconnect();
      } catch (err) {
        console.log(err);
      }
    }
  }

  const nextStep = () => { setActiveStep(prevStep => (prevStep < 2 ? prevStep + 1 : prevStep)) };

  const renderPageLoader = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>Loading data...</div>
          <BarLoader width={200} color={"#039BE5"} />
        </div>
      </div>
    )
  }

  const renderNoDataPage = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>No data available</div>
        </div>
      </div>
    )
  }

  const renderPanelContainer = () => {
    if (multiLoading) {
      return (
        <div style={{ marginTop: "200px" }}>
          <MultiStepLoader stepNumber={activeStep} />
        </div>
      )
    } else {
      switch (activeTab) {
        case 0:
          if (balanceSheetData && balanceSheetData == 'NO_DATA') {
            return renderNoDataPage();
          } else if (balanceSheetData) {
            return (<RenderMD src={balanceSheetData} />)
          } else {
            return renderPageLoader();
          }
        case 1:
          if (cashFlowData && cashFlowData == 'NO_DATA') {
            return renderNoDataPage();
          } else if (cashFlowData) {
            return (<RenderMD src={cashFlowData} />)
          } else {
            return renderPageLoader();
          }
        case 2:
          if (incomeStatementData && incomeStatementData == 'NO_DATA') {
            return renderNoDataPage();
          } else if (incomeStatementData) {
            return (<RenderMD src={incomeStatementData} />)
          } else {
            return renderPageLoader();
          }
      }
    }
  }

  //Excel Download
  const handleExcelDownload = () => {
    // Sample data
    const data = [
      { name: 'John', age: 28, email: 'john@example.com' },
      { name: 'Jane', age: 32, email: 'jane@example.com' },
      { name: 'Jack', age: 24, email: 'jack@example.com' },
    ];

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(balanceSheetData);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'analysis.xlsx');

  }

  return (
    <Card>
      <PageHeader
        pageName={"Fiannacial Statement"}
        backButtonEnabled={true}
      />
      <div className='container-fluid vh-85'>
        <div className='page-header' style={{ border: '0px' }}>
          <div className='tab-container'>
            {
              !multiLoading &&
              <CustomTabs
                tabs={["Balance Sheet", "Cash Flow Statement", "Income Statement"]}
                onTabSelect={setActiveTab}
                isLoading={isLoading}
              />
            }
          </div>
          {
            !multiLoading &&
            <div className='search-container' style={{ textAlign: 'end' }}>
              <Button color="success" onClick={handleExcelDownload} style={{ marginRight: '6px' }}>Download As Excel</Button>
              <Button color="primary" onClick={() => { setSidebarOpen(true) }}>Chat</Button>
            </div>
          }
        </div>
        <div className='page-container no-scroll-bar'
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '0px',
            height: '65vh',
          }}
        >
          {renderPanelContainer()}
        </div>
      </div>
      <SidebarDashboard 
        open={sidebarOpen} 
        title="Fiannacial Statement" 
        documentId={document} 
        toggleSidebar={() => { setSidebarOpen(false) }} />
    </Card>
  );
}

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FinancialStatamentAnalysis);