import Pusher from "pusher-js";


export const initiateNewSockerConnection = (socketId, eventHandlers) => {
    const pusherInstance = new Pusher('56fd8b1ba5df4b243794', { cluster: 'ap2' });
    const channelInstance = pusherInstance.subscribe(socketId);

    // Loop through each event object in the array and bind the event to its callback
    eventHandlers.forEach(({ event, callback }) => {
        channelInstance.bind(event, (data) => {
            callback(data);  
            channelInstance.unbind(event);  // Unbind after the event is handled

            const closeTimeout = setTimeout(() => {
                closeSocketConnection(pusherInstance, channelInstance);
                clearTimeout(closeTimeout);
            }, 500);
        });
    });
}
const closeSocketConnection = (pusherInstance, channelInstance) => {
    try{
        if (channelInstance) {
            channelInstance.unsubscribe(); // Unsubscribe from the channel
            console.log('Channel unsubscribed:', channelInstance);
        }
    
        if (pusherInstance) {
            pusherInstance.disconnect();  // Disconnect Pusher instance
            console.log('Pusher instance disconnected:', pusherInstance);
        }
    }catch{}
};
