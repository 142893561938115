import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { MessageSquare } from 'react-feather'
import { Card, Button } from "reactstrap";
import { fetchOrganisationData } from "../../../../redux/actions";
import PageHeader from "../../../../@core/components/PageHeader";
import CustomTabs from "../../../components/CustomTabs";
import { connect } from "react-redux";
import MultiStepLoader from "../../../components/MultiStepLoader";
import { statusCode } from "../../../../utility/constants/utilObject";
import { BarLoader } from "react-spinners";
import {
  uploadUWStatementHandler,
  fetchlendingStatementAnalysisHandler,
  fetchMasterLedgerHandler
} from "../../../../services/underwriting";
import RenderMD from "../../../components/MarkdownTypingEditor/indexRaw";
import Pusher from "pusher-js";
import { generateAlphanumId } from "../../../../utility/helper";
import { showErrorToast } from "../../../../utility/helper";
import SidebarDashboard from '../../../Dashboard/Sidebar'
import "./index.scss";
// Subcomponent
import { 
  kycData, transactionData, suspiciousActivities, monthlyData, paymentInstrumentsData, creditData,
  summaryData, kycData1, irregularitiesData, monthlyBalanceData, monthlyTransactionData
} from "../testingData";
import KYCInfo from '../SubComponent/KYCInfo';
import TransactionInfo from '../SubComponent/Transactions';
import AMLTransaction from '../SubComponent/AML';
import CreditAnalysisReport from '../SubComponent/CreditReport';
import OverallAnalysis from "../SubComponent/Summary";


const STATEMENT_ANALYSIS_CATEGORIES = {
  "USER_KYC": "USER_KYC",
  "TRANSACTIONS": "TRANSACTIONS",
  "AML": "AML",
  "CREDIT_REPORT": "CREDIT_REPORT"
}


const UnderwritingAnalysis = (props) => {
  const { state } = useLocation();
  const { document } = useParams();
  const navigate = useNavigate();

  let [caseName, file, documentType, entity] = state ? [state.caseName, state.file, state.documentType, state.entity] : [null, null, null, null];
  
  // Data Variables
  const [masterLedgerId, setMasterLedgerId] = useState(document ? document : null);
  const [masterLedgerData, setMasterLedgerData] = useState(null);
  const [uwCaseName, setUWCaseName] = useState(caseName? caseName: null);
  const [multiLoading, setMultiloading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [socketId, setSocketId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Data Container Variables
  const [summaryDataObject, setSummaryDataObject] = useState(null);
  const [transactionDataObject, setTransactionDataObject] = useState(null);
  const [suspiciousActivitiesObject, setSuspiciousActivitiesObject] = useState(null);
  const [creditDataObject, setCreditDataObject] = useState(null);
  const [kycDataObject, setKycDataObject] = useState(null);


  // Pusher Variables
  const [pusher, setPusher] = useState(null);
  const [channel, setChannel] = useState(null);

  let kycStatus = false;
  let transactionStatus = false;
  let amlStatus = false;

  const [isLoading, setIsLoading] = useState({
    'Trial Balance': false,
    'Analysis': false
  });


  // Loader variable
  const [activeStep, setActiveStep] = useState(0);

  const reloadWithId = (messageData) => {
    const masterId = getMasterLedgerId(messageData);
    console.log('reloading with id', masterId);
    window.location.href = `/ai-underwriting/${masterId}`;
  }

  useEffect(() => {
    if (document == 'new-analysis') {
      setMultiloading(true)

      if (!file) {
        showErrorToast('No file found');
      } else {
        setMultiloading(true)
        const socketId = generateAlphanumId('insightai', 10);
        setSocketId(socketId);
        initiateSockerConnection(socketId);

        const formData = new FormData();
        formData.append('name', caseName);
        file.forEach((file) => {
          formData.append('files', file); // Each file is appended with the same key 'files'
        });
      
        // Append each document type
        documentType.forEach((docType) => {
          formData.append('document_types', docType); // Append each document type individually
        });
      
        // Append each entity
        entity.forEach((entity_item) => {
          formData.append('file_entity', entity_item); // Append each entity individually
        });

        uploadUWStatementHandler(formData, socketId)
          .then((response) => {
            console.log(response);
            if (response.status == statusCode.HTTP_201_CREATED) {
              console.log('uploaded file: ', response.data);
              setMasterLedgerId(response.data.id);
              nextStep();
            } else {
              showErrorToast('Error uploading file');
              setMultiloading(false);
            }
          }).catch((err) => {
            showErrorToast('Error uploading file');
            setMultiloading(false);
          });
      }
    } else {
      setMultiloading(false);
      fetchStatementAnalysis(document);
    }
  }, []);

  const fetchStatementAnalysis = () => {
    if (!masterLedgerId) {
      showErrorToast('No statement found');
      return;
    }

    // Fetch Master Ledger Data
    fetchMasterLedgerHandler(masterLedgerId)
    .then((response) => {
      if (response.status == statusCode.HTTP_200_OK) {
        setMasterLedgerData(response.data);
        setUWCaseName(response.data.name);
      }
    }).catch((err) => {});

    // Fetch KYC Data
    fetchlendingStatementAnalysisHandler(masterLedgerId, STATEMENT_ANALYSIS_CATEGORIES.USER_KYC)
    .then((response) => {
      if (response.status == statusCode.HTTP_200_OK) {
        setKycDataObject(response.data);
      } else {
        setKycDataObject('NO_DATA');
      }
    }).catch((err) => {
      setKycDataObject('NO_DATA');
    });

    fetchlendingStatementAnalysisHandler(masterLedgerId, STATEMENT_ANALYSIS_CATEGORIES.TRANSACTIONS)
    .then((response) => {
      if (response.status == statusCode.HTTP_200_OK) {
        setTransactionDataObject(response.data);
        setSummaryDataObject(response.data);
      } else {
        setTransactionDataObject('NO_DATA');
        setSummaryDataObject('NO_DATA');
      }
    }).catch((err) => {
      setTransactionDataObject('NO_DATA');
      setSummaryDataObject('NO_DATA');
    });

    fetchlendingStatementAnalysisHandler(masterLedgerId, STATEMENT_ANALYSIS_CATEGORIES.AML)
    .then((response) => {
      if (response.status == statusCode.HTTP_200_OK) {
        setSuspiciousActivitiesObject(response.data);
      } else {
        setSuspiciousActivitiesObject('NO_DATA');
      }
    }).catch((err) => {
      setSuspiciousActivitiesObject('NO_DATA');
    });

    fetchlendingStatementAnalysisHandler(masterLedgerId, STATEMENT_ANALYSIS_CATEGORIES.CREDIT_REPORT)
    .then((response) => {
      if (response.status == statusCode.HTTP_200_OK) {
        setCreditDataObject(response.data);
      } else {
        setCreditDataObject('NO_DATA');
      }
    }).catch((err) => {
      setCreditDataObject('NO_DATA');
    });
  }

  const getMasterLedgerId = (message) => {
    const messageSplit = message.split(':');
    if (messageSplit.length > 1) {
      return messageSplit[1];
    }
  }

  const initiateSockerConnection = (socketId) => {
    const pusherInstance = new Pusher('56fd8b1ba5df4b243794', { cluster: 'ap2' });
    const channelInstance = pusherInstance.subscribe(socketId);
    channelInstance.bind('kyc', (data) => {
      console.log('kyc', data);
      channelInstance.unbind('kyc');
      kycStatus = true;
      closeSocketConnection(data);
    });
    channelInstance.bind('transaction', (data) => {
      console.log("transaction", data);
      channelInstance.unbind('transaction');
      transactionStatus = true;
      closeSocketConnection(data);
    });
    channelInstance.bind('aml', (data) => {
      console.log("aml", data);
      channelInstance.unbind('aml');
      amlStatus = true;
      closeSocketConnection(data);
    });
    setPusher(pusherInstance);
    setChannel(channelInstance);
  }

  const closeSocketConnection = (data) => {
    console.log('closing socket connection: ', data);
    if (kycStatus && transactionStatus && amlStatus) {
      nextStep();
      // setMultiloading(false);
      reloadWithId(data);
      // try {
      //   pusher.unsubscribe(socketId);
      //   pusher.disconnect();
        
      // } catch (err) {
      //   console.log(err);
      // }
    }
  }

  const nextStep = () => { setActiveStep(prevStep => (prevStep < 2 ? prevStep + 1 : prevStep)) };

  const renderPageLoader = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>Loading data...</div>
          <BarLoader width={200} color={"#039BE5"} />
        </div>
      </div>
    )
  }

  const renderNoDataPage = () => {
    return (
      <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div>No data available</div>
        </div>
      </div>
    )
  }

  /**
   * Function for each category
   * 
   */
  const processSummarySheet = (kycData, amlData, creditData) => {
    // Extract KYC information
    const kycData1 = {
      'Account Holders': kycData?.kyc_analysis?.accountHolder || '-',
      'Account Number': kycData?.kyc_analysis?.accountNumber || '-',
      'Bank Name': kycData?.kyc_analysis?.bankName || '-',
      'Account Type': kycData?.kyc_analysis?.accountType || '-',
      'Email': kycData?.kyc_analysis?.email || '-',
      'Phone Number': kycData?.kyc_analysis?.phoneNumber || '-',
      'Statement From': kycData?.kyc_analysis?.statementFrom || '-',
      'Statement To': kycData?.kyc_analysis?.statementTo || '-',
      'Txn Start Date': kycData?.kyc_analysis?.txnStartDate || '-',
      'Txn End Date': kycData?.kyc_analysis?.txnEndDate || '-',
    };
  
    // Extract AML irregularities
    const irregularitiesData = amlData?.aml_analysis?.aml_irregularities
      ? [
          { activity: 'Big deposit followed by withdrawals on same or next day', incidences: amlData?.aml_analysis?.aml_irregularities['Big deposit followed by withdrawals on same or next day'] || 0 },
          { activity: 'Multiple deposits followed by big withdrawal on same or next day', incidences: amlData?.aml_analysis?.aml_irregularities['Multiple deposits followed by big withdrawal on same or next day'] || 0 },
          { activity: 'Multiple Cash/ ATM deposits on same day', incidences: amlData?.aml_analysis?.aml_irregularities['Multiple Cash/ ATM deposits on same day'] || 0 },
          { activity: 'High value spending', incidences: amlData?.aml_analysis?.aml_irregularities['High value spending'] || 0 },
          { activity: 'International wire transfers', incidences: amlData?.aml_analysis?.aml_irregularities['International wire transfers'] || 0 },
          { activity: 'ATM withdrawals above 20,000', incidences: amlData?.aml_analysis?.aml_irregularities['ATM withdrawals above 20,000'] || 0 },
        ]
      : [];
  
    // Extract Summary Data
    const summaryData = {
      'Financial Stability Risk Score': creditData ? creditData.creditScore || '-' : '-',
      'Credit Limit Recommendations': creditData ? creditData.creditUtilization || '-' : '-',
      'Industry risk': creditData && creditData.defaultRisk ? `${creditData.defaultRisk}%` || '-' : '-',
      'High Risk Alert': creditData ? creditData.recentInquiries || '-' : '-',
      'MONTHLY AVG BALANCE': amlData ? amlData.financial_statistics?.daily_avg_balance || '-' : '-',
    };
  
    return {
      kycDataOverviews: kycData1,
      irregularitiesDataOverviews: irregularitiesData,
      summaryDataObject: summaryData,
    };
  };

  const processKYCData = (data) => {
    if(data){
      return [data.kyc_analysis]
    }
  }

  const processTransactionData = (inputData) => {
    // Extracting relevant sections from input data
    const transactions = inputData?.transaction_analysis?.transactions || [];
    const monthlyAvgBalances = inputData?.transaction_analysis?.monthly_avg_balances || [];;
    const monthlyDepositsWithdrawals = inputData?.transaction_analysis?.monthly_deposits_withdrawals || [];
  
    const transactionData = transactions.map((transaction) => ({
      particulars: transaction.particulars  || "",
      chequeRefNbr: transaction.chequeRefNbr  || "",
      counterparty: transaction.counterparty || "",
      debit: transaction.debit || "",
      credit: transaction.credit || "",
      balance: transaction.balance || "",
      computedBalance: transaction.computedBalance || "",
      category: transaction.category || "",
      tags: transaction.tags || "",
      upiApp: transaction.upiApp || "",
    }));
  
    // Transforming month-wise deposits and withdrawals data
    const monthWiseDepositsWithdrawals = monthlyDepositsWithdrawals.map((item) => ({
      month: item.month,
      deposits: item.deposits,
      withdrawals: item.withdrawals,
    }));
  
    // Transforming average monthly balance data
    const avgMonthlyBalance = monthlyAvgBalances.map((item) => ({
      month: item.month,
      avgBalance: item.avgBalance,
    }));
  
    // Returning the transformed data
    return {
      transactionData,
      monthWiseDepositsWithdrawals,
      avgMonthlyBalance,
    };
  };
  

  const processAMLData = (inputData) => {
    // Extracting relevant sections from input data
    const amlIrregularities = inputData.aml_analysis.aml_irregularities;
    const monthlyDepositsWithdrawals = inputData.aml_analysis.monthly_deposits_withdrawals;
    const paymentModes = inputData.aml_analysis.payment_modes;
    const amlScores = inputData.aml_analysis.aml_scores;
    const financialStatistics = inputData.aml_analysis.financial_statistics;
  
    // Transforming suspicious activities data
    const suspiciousActivities = Object.keys(amlIrregularities).map((key) => ({
      name: key,
      count: amlIrregularities[key],
    }));
  
    // Transforming monthly deposits and withdrawals data
    const monthlyData = monthlyDepositsWithdrawals.map((item) => ({
      month: item.month,
      deposits: item.deposits,
      withdrawals: item.withdrawals,
    }));
  
    // Transforming payment modes data
    const paymentModesData = {
      mode: paymentModes.mode,
      deposits: paymentModes.deposits,
      withdrawals: paymentModes.withdrawals,
    };
  
    // Combining AML scores and financial statistics
    const aml_analytics = {
      overall_score: amlScores.overall_score,
      normalized_score: amlScores.normalized_score,
      daily_avg_balance: financialStatistics.daily_avg_balance,
      max_balance: financialStatistics.max_balance,
      min_balance: financialStatistics.min_balance,
      days_gap_between_max_min: financialStatistics.days_gap_between_max_min,
      total_transactions: financialStatistics.total_transactions,
      aml_score: financialStatistics.aml_score,
    };
  
    // Returning the transformed data
    return {
      suspiciousActivities,
      monthlyData,
      paymentModesData,
      aml_analytics,
    };
  };

  const processCreditData = (data) => {

  }

  const renderPanelContainer = () => {
    if (multiLoading) {
      return (
        <div style={{ marginTop: "100px" }}>
          <MultiStepLoader stepNumber={activeStep} />
        </div>
      )
    } else {
      switch (activeTab) {
        case 0:
          if (
            kycDataObject && kycDataObject == 'NO_DATA' &&
            transactionDataObject && transactionDataObject == 'NO_DATA' &&
            suspiciousActivitiesObject && suspiciousActivitiesObject == 'NO_DATA' &&
            creditDataObject && creditDataObject == 'NO_DATA'
          ) {
            return renderNoDataPage();
          } else if (kycDataObject && transactionDataObject && suspiciousActivitiesObject && creditDataObject) {
            const {
              kycDataOverviews, irregularitiesDataOverviews, summaryDataObject
            } = processSummarySheet(kycDataObject, suspiciousActivitiesObject, creditDataObject);
            return (
              <OverallAnalysis
                summaryData={summaryDataObject}
                kycData={kycDataOverviews}
                irregularitiesData={irregularitiesDataOverviews}
                monthlyBalanceData={monthlyBalanceData}
                monthlyTransactionData={monthlyTransactionData}
              />
            )
          } else {
            return renderPageLoader();
          }
        case 1:
          if (kycDataObject && kycDataObject == 'NO_DATA') {
            return renderNoDataPage();
          } else if (kycDataObject) {
            const processedKyc = processKYCData(kycDataObject);
            return (
              <KYCInfo kycData={processedKyc} />
            ) 
          } else {
            return renderPageLoader();
          }
        case 2:
          if (transactionDataObject && transactionDataObject == 'NO_DATA') {
            return renderNoDataPage();
          } else if (transactionDataObject) {
            const {transactionData,
              monthWiseDepositsWithdrawals,
              avgMonthlyBalance } = processTransactionData(transactionDataObject);
            return (
            <TransactionInfo 
              transactions={transactionData} 
              monthlyBalanceData={avgMonthlyBalance}
              monthlyTransactionData={monthWiseDepositsWithdrawals}
              />
            )
          } else {
            return renderPageLoader();
          }
        case 3:
          if (suspiciousActivitiesObject && suspiciousActivitiesObject == 'NO_DATA') {
            return renderNoDataPage();
          } else if (suspiciousActivitiesObject) {
            const { suspiciousActivities, monthlyData, paymentModesData, aml_analytics } = processAMLData(suspiciousActivitiesObject);
            return (
              <AMLTransaction
                suspiciousActivities={suspiciousActivities}
                monthlyData={monthlyData}
                paymentInstrumentsData={paymentModesData}
                aml_analytics={aml_analytics}
              />
            )
          } else {
            return renderPageLoader();
          }
        case 4:
          if (creditDataObject && creditDataObject == 'NO_DATA') {
            return renderNoDataPage();
          } else if (creditDataObject) {
            return (<CreditAnalysisReport creditData={creditData} />)
          } else {
            return renderPageLoader();
          }
        
      }
    }
  }

  return (
      
      <div className='container-fluid vh-85'>
        <PageHeader
          key={uwCaseName}
          pageName={`AI Underwriting ${uwCaseName? ' | ' + uwCaseName:''}`}
          backButtonEnabled={true}
        />
        <div className='page-header' style={{ border: '0px' }}>
          <div className='tab-container'>
            {
              !multiLoading &&
              <CustomTabs
                tabs={["Risk Dashboard", "KYC", "Bank St. Analysis", "AML", "Credit Report"]}
                onTabSelect={setActiveTab}
                isLoading={isLoading}
              />
            }
          </div>
          {
            !multiLoading &&
            <div className='search-container' style={{ textAlign: 'end', marginTop: '-10px' }}>
              <Button color="primary" onClick={() => { setSidebarOpen(true) }}> 
                <MessageSquare/> Query Analytics
              </Button>
            </div>
          }
        </div>
        <div className='page-container no-scroll-bar'
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '0px',
            height: '80vh',
          }}
        >
          {renderPanelContainer()}
        </div>
        <SidebarDashboard 
          key={uwCaseName}
          open={sidebarOpen} 
          title={`AI Underwriting ${uwCaseName? ' | ' + uwCaseName:''}`}
          documentId={masterLedgerId} 
          toggleSidebar={() => { setSidebarOpen(false) }} />
      </div>
      
  );
}

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(UnderwritingAnalysis);