import './style.scss';
import { useState, useEffect } from "react";
import { Progress, Button, ButtonGroup, Table } from "reactstrap";
import PageHeader from '../../@core/components/PageHeader';
import { dummyPlans, dummyYearlyPlans } from '../../utility/testDB/dummyPlans';
import PriceCard from './PriceCards';
import TransactionIcon from '../../assets/icons/transaction.png';

import { planRequest } from '../../services/billing';
import { statusCode } from '../../utility/constants/utilObject';

const Plans = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [value, setValue] = useState('monthly');
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false)
    const [plans, setPlans] = useState(null);

    const totalCredits = 10000;  // Total available credits
    const usedCredits = 9000;    // Credits used

    const getCreditUsageDetails = (used, total) => {
        const percentageUsed = (used / total) * 100;
        let progressColor = 'primary';
        // if (percentageUsed >= 100) {
        //     progressColor = 'danger';
        // } else if (percentageUsed >= 80) {
        //     progressColor = 'warning';
        // }
        return {
            percentageUsed: percentageUsed.toFixed(0),
            progressColor
        };
    };
    const { percentageUsed, progressColor } = getCreditUsageDetails(usedCredits, totalCredits);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleValidation = (e) => {
        setValue(e.target.value);
    }

    useEffect(() => {
        setLoader(true);
        planRequest()
            .then(response => {
                setLoader(false);
                console.log(response);

                if (response.status == statusCode.HTTP_200_OK) {
                    setPlans(response.data)
                } else {
                    setError(true);
                }
            })
            .catch(err => {
                setLoader(false);
                setError(true);
            })
    }, [])

    return (
        <div>
            <PageHeader
                pageName={"Products & Plans"}
                backButtonEnabled={false}
            />
            <div className='product-plans'>
                <div className='padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div className='product-plans__header-wrapper'>
                        <div className='product-plans__header'>
                            <h2>Plans & Billing</h2>
                            <p>Manage your plan and billing history here.</p>
                        </div>
                        {/* <div className='plans-info'>
                            <Table bordered className='plans-info__detail'>
                                <tbody>
                                    <tr>
                                        <td><strong>Plan</strong></td>
                                        <td>Silver</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Credits Used</strong></td>
                                        <td>{usedCredits}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Credits Remaining</strong></td>
                                        <td>{totalCredits - usedCredits}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2'>
                                            <Progress color={progressColor} value={percentageUsed} style={{ height: '1rem', width: '100%' }}>
                                                {percentageUsed}%
                                            </Progress>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div> */}
                    </div>
                    <hr className='section-divider' />
                    {
                        loader ?
                            <div className="loader-wrapper">
                                <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8"
                                    data-testid="oval-svg">
                                    <g fill="none" fillRule="evenodd">
                                        <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                            <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" strokeWidth="2"></circle>
                                            <path d="M20 0c0-9.94-8.06-20-20-20">
                                                <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s"
                                                    repeatCount="indefinite"></animateTransform>
                                            </path>
                                        </g>
                                    </g>
                                </svg>

                            </div>
                            : error ?
                                <h2 className="error-msg">No Plans Found</h2>
                                :
                                <>
                                    <div className='validation-toggle__wrapper'>
                                        <ButtonGroup className='validation-toggle'>
                                            <Button color='primary' outline onClick={handleValidation} value="monthly" className={value === 'monthly' ? 'active toggle-tab' : 'toggle-tab'}>Monthly</Button>
                                            <Button color='primary' outline onClick={handleValidation} value="yearly" className={value === 'yearly' ? 'active toggle-tab' : 'toggle-tab'}>Yearly</Button>
                                        </ButtonGroup>
                                    </div>
                                    {/* <h4 className="pricing-header">Pricing Plans</h4> */}
                                    <div className="plan-wrapper">
                                        {
                                            plans && (value === 'monthly' ? plans : dummyYearlyPlans).map((plan, i) => {
                                                return (
                                                    <PriceCard planDetails={plan} key={i} />
                                                )
                                            })
                                        }
                                    </div>

                                    {/* History */}

                                    <h4 className="pricing-header">Billing History</h4>
                                    <div className='history-wrapper'>

                                        <ul className='history-wrapper__list'>
                                            <li>
                                                <div className='history-wrapper__list-info'>
                                                    <img src={TransactionIcon} />
                                                    <span>Billing/Transaction Name</span>
                                                </div>
                                                <span className='history-wrapper__date'>24 Oct</span>

                                            </li>

                                            <li>
                                                <div className='history-wrapper__list-info'>
                                                    <img src={TransactionIcon} />
                                                    <span>Billing/Transaction Name</span>
                                                </div>
                                                <span className='history-wrapper__date'>24 Oct</span>

                                            </li>
                                        </ul>
                                    </div>
                                </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Plans;
