import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, FormText, Button, ButtonGroup, Spinner, Alert, Row, Col } from "reactstrap";
import Script from "./Script";
import { connect } from 'react-redux';
import { chatBotAction } from '../../../../redux/actions/chatBot';
import { FaComment, FaSearch, FaPaperclip, FaMicrophone } from "react-icons/fa";
import Botimg from '../../../../assets/images/bot/darkbot@2x.png';
import { useNavigate } from "react-router";
import './style.scss';
import { fetchBotById } from "../../../../services/chatBot";

import { createChatBot, editChatBot, deleteChatBot } from "../../../../services/chatBot";
import { statusCode } from "../../../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast } from "../../../../utility/helper";
import { ClipLoader } from "react-spinners";
import CustomConfirmationModal from "../../../components/confirmationModel";

//Form Input and Submission

const ProfileForm = ({
    error,
    botScript,
    chatbotId,
    botType = 'WEBSITE'
}) => {
    const [formData, setFormData] = useState({
        name: "",
        logo_url: "",
        background: "#0C6980",
        bot_type: "WEBSITE",
        bot_purpose: "WEBSITE",
    });

    const [logoPreview, setLogoPreview] = useState(null);
    const [isLogoUploaded, setIsLogoUploaded] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [oldScript, setOldScript] = useState("");
    const [script, setScript] = useState('');
    const [isBotCreated, setIsBotCreated] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "logo_url") {
            const file = files[0];
            if (file) {
                if (file.size > 512 * 1024) {
                    setErrors({
                        ...errors,
                        logo_url: "Logo must not exceed 512 KB."
                    });
                    setIsLogoUploaded(false);
                    setLogoPreview(null);
                    return;
                }

                const img = new Image();
                img.onload = () => {
                    if (img.width !== img.height) {
                        setErrors({
                            ...errors,
                            logo_url: "Logo must be a square image."
                        });
                        setIsLogoUploaded(false);
                        setLogoPreview(null);
                    } else {
                        setFormData({
                            ...formData,
                            [name]: file
                        });
                        setLogoPreview(URL.createObjectURL(file));
                        setIsLogoUploaded(true);
                        setErrors({
                            ...errors,
                            logo_url: null
                        });
                    }
                };
                img.onerror = () => {
                    setErrors({
                        ...errors,
                        logo_url: "Invalid image file."
                    });
                };
                img.src = URL.createObjectURL(file);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleResetLogo = () => {
        setFormData({
            ...formData,
            logo_url: ""
        });
        setLogoPreview(null);
        setIsLogoUploaded(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Name is required.";
        } else if (formData.name.length > 60) {
            newErrors.name = "Name should not exceed 60 characters.";
        }

        if (!formData.logo_url) {
            newErrors.logo_url = "Logo is required.";
        }

        if (!formData.background) {
            newErrors.background = "Background Color is required.";
        }
        if (!formData.bot_type) {
            newErrors.bot_type = "Bot type is required.";
        }
        if (!formData.bot_purpose) {
            newErrors.bot_purpose = "Bot Purpose is required.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formDataObj = new FormData();
            formDataObj.append('name', formData.name);
            formDataObj.append('background', formData.background);
            formDataObj.append('bot_type', formData.bot_type);
            formDataObj.append('logo_url', formData.logo_url);
            formDataObj.append('bot_purpose', formData.bot_purpose);

            setIsLoading(true);
            createChatBot(formDataObj)
                .then((response) => {
                    console.log(response)
                    if (response.status == statusCode.HTTP_201_CREATED) {
                        showSuccessToast('Bot created successfully');
                        navigate(`/wizBot/${response?.data?.data?.id}`);
                        setIsBotCreated(true);
                        setIsEditing(false);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    setIsLoading(false);
                    showErrorToast('Failed to create Bot');
                });
        }
    };

    useEffect(() => {
        if (chatbotId !== 'new-bot') {
            setIsEditing(false);
            const fetchBot = async () => {
                try {
                    const response = await fetchBotById(chatbotId);
                    if (response?.data?.data) {
                        setFormData({
                            name: response?.data?.data?.name,
                            background: response?.data?.data?.background,
                            logo_url: response?.data?.data?.logo_url,
                            bot_type: response?.data?.data?.bot_type,
                            bot_purpose: response?.data?.data?.bot_purpose,
                        });
                        setOldScript(response?.data?.data?.script);
                        setLogoPreview(response?.data?.data.logo_url);
                        setIsLogoUploaded(true);
                        setIsBotCreated(true);
                    }
                } catch (error) {
                    setErrors(error);
                } finally {
                    setIsLoading(false);
                }
            }

            fetchBot();
        } else {
            setIsEditing(true);
        }
    }, [chatbotId]);
    //bot edit
    const handleEdit = () => {
        if (isEditing) {
            // Call API to save edits
            saveEdits();

        } else {
            // Enable editing
            setIsEditing(true);
        }

    }

    const saveEdits = () => {
        // Your API call here
        if (validateForm()) {
            setIsLoading(true);

            editChatBot(chatbotId, formData)
                .then(res => {
                    if (res.status == statusCode.HTTP_200_OK) {
                        setIsLoading(false);
                        showSuccessToast('Bot Updated successfully');
                        setIsEditing(false);

                    }
                })
                .catch(err => {
                    setIsLoading(false);
                    showErrorToast('Failed to update Bot');
                    setIsEditing(false);

                })
        }
    };

    const handleDeleteBot = () => {
        console.log('Bot Deleted');
        setDeleteLoader(true);
        deleteChatBot(chatbotId)
            .then(response => {
                console.log("response", response);
                setShowConfirmationModal(false);
                if (response.status == statusCode.HTTP_200_OK) {
                    setDeleteLoader(false);
                    showSuccessToast('ChatBot and All related data deleted successfully !');
                    navigate("/wizBot/");
                } else {
                    showErrorToast('Failed to Delete ChatBot');
                    setDeleteLoader(false);

                }


            })
            .catch((err) => {
                showErrorToast('Failed to Delete ChatBot');
                setShowConfirmationModal(false);
                setDeleteLoader(false);

            })


    }

    return (
        <>
            {
                deleteLoader &&
                <div className="loader-overlay">
                    <ClipLoader color="#0C6980" />
                </div>
            }

            <div style={{ height: '70vh' }} className="profileform ">
                <div className="form-wrapper" style={{ width: '50%' }}>
                    <Form onSubmit={handleFormSubmit}>
                        <FormGroup className="form-group">
                            <Label for="botName">Name :</Label>
                            <Input
                                id="botName"
                                name="name"
                                placeholder="Enter Your Bot Name"
                                value={formData.name}
                                onChange={handleChange}
                                maxLength="60"
                                readOnly={!isEditing}
                            />
                            {errors.name && <FormText color="danger">{errors.name}</FormText>}
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Label for="botLogo">Logo :</Label>
                            {!isLogoUploaded && (
                                <Input
                                    id="botLogo"
                                    name="logo_url"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                            )}
                            {logoPreview && (
                                <div>
                                    <img src={logoPreview} alt="Logo Preview" style={{ width: '64px', height: '64px', marginTop: '0', marginRight: '20px' }} />
                                    {isEditing && <Button color="danger" onClick={handleResetLogo} style={{ marginLeft: '10px' }}>Reset</Button>}
                                </div>
                            )}
                            {errors.logo_url && <FormText color="danger">{errors.logo_url}</FormText>}
                            {isLogoUploaded || isEditing &&
                                <FormText>
                                    Upload your logo. Only square images up to 512 KB can be uploaded.
                                </FormText>
                            }
                        </FormGroup>
                        <FormGroup className="form-group color-picker">
                            <Label for="botColor">Background Color :</Label>
                            <Input
                                id="botColor"
                                name="background"
                                placeholder="color placeholder"
                                type="color"
                                value={formData.background}
                                onChange={handleChange}
                                style={{
                                    width: '150px',
                                    padding: '0px',
                                    border: '0px',
                                    height: '25px',
                                    borderRadius: '10px'
                                }}
                                disabled={!isEditing}
                            />
                            {
                                isEditing && <FormText style={{ marginTop: '0px' }}>
                                    Click to change the background color
                                </FormText>
                            }
                            {errors.background && <FormText color="danger">{errors.background}</FormText>}
                        </FormGroup>

                        <FormGroup tag="fieldset" className="confirmation-fields">
                            <Row>
                                <Col md={3}>
                                    <FormGroup check>
                                        <Input
                                            name="bot_purpose"
                                            type="radio"
                                            value="SERVICE"
                                            checked={formData.bot_purpose === 'SERVICE'}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                        {' '}
                                        <Label for="SERVICE" check>
                                            Customer Service
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup check>
                                        <Input
                                            name="bot_purpose"
                                            type="radio"
                                            value="PRODUCT"
                                            checked={formData.bot_purpose === 'PRODUCT'}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                        {' '}
                                        <Label for="PRODUCT" check>
                                            Product Search
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        {
                            !isBotCreated ?
                                <Button color="primary" type="submit" disabled={isLoading}>
                                    {isLoading ?
                                        <>
                                            <span style={{ marginRight: '5px' }}>Creating your Bot...</span> <ClipLoader size={"sm"} color="#f1f1f1" />
                                        </> : 'Create Website Bot'}
                                </Button>
                                :
                                <>
                                    <ButtonGroup>
                                        <Button className="edit-bot" color={isEditing ? 'success' : "primary"} disabled={isLoading} style={{ marginRight: '10px' }} onClick={handleEdit}>
                                            {isEditing ? (isLoading ? <ClipLoader size={"sm"} color="#f1f1f1" /> : 'Save Edits') : 'Edit Bot'}
                                        </Button>
                                        <Button className="delete-bot" color="danger" onClick={() => setShowConfirmationModal(true)}>Delete Bot</Button>
                                    </ButtonGroup>
                                    <Alert style={{ marginTop: '15px', padding: '10px' }}>
                                        You need to change the script after a successful bot update.
                                    </Alert>
                                </>
                        }
                    </Form>
                    {(script || oldScript) && <Script script={script || oldScript || ''} />}
                </div>
                <div className="bot-preview">
                    <div className="bot-chat">
                        <div className="bot-chat__top" style={{ backgroundColor: formData.background }}>
                            <span className="comment"><FaComment /> Chat</span>
                            <span className="help"><FaSearch />HelpDesk</span>
                        </div>
                        <div className="bot-chat__body">
                            <div className="user-msg"><span style={{ color: '#fff', backgroundColor: formData.background }}>Hey Bot</span></div>
                            <div className="bot-msg">
                                <span className="bot-msg__logo" style={{ display: 'inline-block', marginRight: '8px' }}>
                                    <img src={logoPreview || Botimg} alt="Logo Preview" style={{ width: '30px', height: '30px' }} />
                                </span>
                                <span className="bot-msg__msg" style={{ display: 'inline-block', borderColor: formData.background }}>Hello! How can I assist you today?</span>
                            </div>
                        </div>
                        <div className="bot-chat__bottom">
                            <span className="input">So, what can I help you with?</span>
                            <div className="bot-chat__bottom__attach">
                                <span className="clip"> <FaPaperclip /> <FaMicrophone /></span>
                                <span className="powered-by">Powered By <img src={Botimg} alt="/" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="bot-icon">
                        <span className="bot-icon__logo" style={{ backgroundColor: formData.background }}>
                            <img src={logoPreview || Botimg} alt="Logo Preview" />
                        </span>
                    </div>
                </div>

                <CustomConfirmationModal
                    header="Important!"
                    content="Are you certain you want to proceed with deleting this bot? Kindly note that you are limited to three attempts."
                    onConfirm={handleDeleteBot}
                    onCancel={() => setShowConfirmationModal(false)}
                    visible={showConfirmationModal}
                />
            </div>
        </>

    );
}


export default ProfileForm;

