// ** React Imports
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { onLogout } from "../../../../../utility/helper";
import User from '../../../../../assets/images/icons/user.png';
import Power from '../../../../../assets/images/icons/shutdown.png';

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setGroupOpen, menuHover } = props;
  const [isPlanActivated, setIsPlanActivated] = useState(false);

  const navigate = useNavigate();

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  useEffect(() => {
    let planStatus = localStorage.getItem('is_plan_activated');
    setIsPlanActivated(planStatus === 'true' ? true : false);
  }, []);

  const logoutUser = () => {
    onLogout();
    navigate("/");
  };

  return !menuCollapsed ?
    (<div className="navbar-header custom-nav"
      style={{ width: "100%", height: "7.45rem", border: "1px" }}>
      {/* <NavLink to='/pages/account-settings' className="custom-nav-btn">
        <img src={User} alt="" style={{width: '30px', height: '30px'}} />
        <span style={{marginLeft: '4%'}}>Account</span>
      </NavLink>
      <NavLink to='' onClick={logoutUser} className="custom-nav-btn">
        <img src={Power} alt="" style={{width: '30px', height: '30px'}} />
        <span style={{marginLeft: '4%'}}>Logout</span>
      </NavLink> */}

      {/* <div style={{
        marginTop: "4%",
        fontSize: "14px",
        width: "100%",
        fontWeight: "400",
        color: "#5C596B"
      }}>{localStorage.getItem("credit_available") || "Few"} {isPlanActivated ? 'credits available!' : 'credits left in your free trial!'}
      </div> */}
      {/* <Button onClick={() => navigate("/pricing")} style={{
        width: "75%",
        marginLeft: "6%",
        marginTop: "5%",
        fontSize: "14px",
        fontWeight: "500",
        color: "#5C596B"
      }} color={"primary"}>Upgrade Plan</Button> */}
    </div>) : <> </>;
};

export default VerticalMenuHeader;
