// TransactionInfo.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file
import { Bar } from 'react-chartjs-2'; // Import required chart component from react-chartjs-2

const TransactionInfo = ({ transactions ,monthlyBalanceData, monthlyTransactionData, kycData }) => {
  return (
    <div className="transaction-container">
      {/* {kycData.map((data, index) => (
          <h2 className="kyc-title">{data.description}</h2>
      ))} */}
      {/* Monthly Average Balance and Monthly Transactions Comparison */}
      <div className="charts-row">
        <div className="chart-block">
          <h3>Month-wise Average Balance (₹)</h3>
          <Bar
            data={{
              labels: monthlyBalanceData.map((data) => data.month),
              datasets: [
                {
                  label: 'Average Balance (₹)',
                  data: monthlyBalanceData.map((data) => data.avgBalance),
                  backgroundColor: '#4BC0C0',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Average Balance (₹)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="chart-block">
          <h3>Monthly Deposits & Withdrawals (₹)</h3>
          <Bar
            data={{
              labels: monthlyTransactionData.map((data) => data.month),
              datasets: [
                {
                  label: 'Deposits',
                  data: monthlyTransactionData.map((data) => data.deposits),
                  backgroundColor: '#7CB342',
                  borderRadius: 5,
                  barThickness: 12,
                },
                {
                  label: 'Withdrawals',
                  data: monthlyTransactionData.map((data) => data.withdrawals),
                  backgroundColor: '#FF9800',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Amount (₹)',
                  },
                },
              },
            }}
          />
        </div>
      </div>
      <div className="transaction-table-wrapper">
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Cheque/Ref nbr</th>
              <th>Counterparty</th>
              <th>Debit (₹)</th>
              <th>Credit (₹)</th>
              <th>Balance (₹)</th>
              <th>Computed Balance (₹)</th>
              <th>Category</th>
              <th>Tags</th>
              <th>UPI App</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.particulars}</td>
                <td>{transaction.chequeRefNbr}</td>
                <td>{transaction.counterparty}</td>
                <td>{transaction.debit || '-'}</td>
                <td>{transaction.credit || '-'}</td>
                <td>{transaction.balance}</td>
                <td>{transaction.computedBalance}</td>
                <td>{transaction.category}</td>
                <td>{transaction.tags}</td>
                <td>{transaction.upiApp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionInfo;
