import React, { useState, useEffect } from 'react';
import { ClockLoader } from "react-spinners";
import Checkbox from '@mui/material/Checkbox';
import './index.scss'; // Ensure you have the correct CSS path

const MultiStepDataLoader = ({ stepNumber = 0, steps = ['Uploading', 'Analysing', 'Processing'], title, updateProcedureStatus }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [activeLoader, setActiveLoader] = useState(0);

  const [checked, setChecked] = useState([false, false, false, false]);
  const [procedure, setProcedure] = useState(['KYC', 'Transactions', 'AML', 'Credit Report']);

  // console.log(stepNumber)
  // useEffect(() => {
  //   const checkboxes = checked.map((_, index) => {
  //     return setTimeout(() => {
  //       setChecked(prevChecked => {
  //         const newChecked = [...prevChecked];
  //         newChecked[index] = true;
  //         return newChecked;
  //       });
  //     }, index * 1000); // 3 seconds interval for each checkbox
  //   });

  //   // Cleanup timeouts when the component unmounts
  //   return () => checkboxes.forEach(timer => clearTimeout(timer));
  // }, []);

  useEffect(() => {
    if (stepNumber >= 0 && stepNumber < steps.length) {
      setCurrentStep(stepNumber);
      const loaderTimer = setTimeout(() => {
        if (stepNumber < steps.length) {
          setActiveLoader(stepNumber);
        }
      }, 800);

      return () => clearTimeout(loaderTimer);
    }
  }, [stepNumber, steps.length]);

  return (
    <div className="container">
      <h2 style={{ marginBottom: '30px' }}> {title || 'Analysing your Data'}</h2>

      <div className="progress-bar-container">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={`circle ${index === activeLoader ? 'active' : ''} ${index < currentStep ? 'active' : ''}`}>
              {index < currentStep ? <i className="large-icon fa fa-check"></i> : (index === activeLoader ? <ClockLoader size={50} color="#FFFFFF" /> : null)}
            </div>
            {index < steps.length - 1 && (
              <div className={`progress-bar ${index < currentStep ? 'filled' : ''}`}></div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="labels">
        {steps.map((label, index) => (
          <div key={index} className="label">{label}</div>
        ))}
      </div>

      <div className='analysis-status'>
        {checked.map((isChecked, index) => (
          <div
            className={`analysis-status__item ${isChecked ? 'fade-in' : 'hidden'}`}
            key={index}
          >
            <div className="round">
              <input
                type="checkbox"
                checked={isChecked}
                id={`checkbox-${index}`} // Unique id for each checkbox
                readOnly // Avoid controlled input warnings
              />
              <label htmlFor={`checkbox-${index}`}></label>
            </div>
            <span>{procedure[index]}</span> {/* Show single procedure for each checkbox */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiStepDataLoader;
