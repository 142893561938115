import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Archive, File, Layers, PlusCircle, Upload, DollarSign, ChevronRight, Edit2, ArrowRight, Check } from "react-feather";
import { ClipLoader } from "react-spinners";
import PageHeader from "../../../@core/components/PageHeader";
import botImage from "../../../assets/images/bot/darkbot@2x.png";
import { FiCommand, FiTrash, FiTrash2 } from "react-icons/fi";
import { Card, Spinner, CardBody, Button, Modal, ModalBody, ModalHeader, ModalFooter, Progress, InputGroup, Input } from "reactstrap";
import { fetchOrganisationData } from "../../../redux/actions";
import { connect } from "react-redux";
import { submitBotSearchMessage } from "../../../services/onboarding";
import {
  uploadDataFile,
  loadSessionDocumentHandler,
  loadSessionMessagesHandler,
  fetchQueryResponseHandler,
  deleteDocumentHandler,
  storageDetailsHandler,
  updateSessionHandler,
} from "../../../services/documentQnA";
import './chatInterface.css'; //
import TypingEffect from '../../components/typingEffect'
import CustomTabs from "../../components/CustomTabs";
import ZohoAuth from "../../components/ZohoAuth";
import UrlDataIntegrations from "../../components/UrlDataIntegrations";
import SourceContainer from "./SourceContainer";
import { botConversation, markdownConversation } from "../../../utility/testDB/conversation";
import MarkdownParser from "../../components/MarkdownTypingEditor"
import { statusCode } from "../../../utility/constants/utilObject";
import { fetchUploadedDocumentDetails } from "../../../utility/docFunctions";
import { getCurrentDate, renderDocumentIcon, showErrorToast, showSuccessToast, truncateStringToNPlace } from "../../../utility/helper";
import { storeLastUrl, toCamelCase, getDomainName } from "../../../utility/Utils";
// Assets load
import AnalyzeDoc from '../../../assets/icons/analyze_document.png';
import AnalysisImg from '../../../assets/icons/database_connect.png';
import SampleDatabase from '../../../assets/icons/database_full.png';
import Web from '../../../assets/icons/internet.png';

import DBSample from "../../DBIntelligence/DBSample";
import NotificationModel from "../../Dashboard/NotificationModel";
import WebsiteModal from "../../components/modal/WebsiteModal";
import WizBotQueryResponse from "../../components/WizBotQueryResponse";
import ChartInChat from "../../components/ChartsModal/ChartInChat";
import { chartsData } from "../../../utility/testDB/dummyChart";
import { initiateNewSockerConnection } from "../../../utility/socket";
import ConfirmationModal from "../../components/ConfirmationModal";
import ResponseActions from "../../components/WizBotQueryResponse/ResponseActions";


const DocumentIntelligence = (props) => {
  const { session } = useParams();
  // const session = 2;
  const navigate = useNavigate();
  const firstName = localStorage.getItem("first_name");

  // Data variable
  const [messages, setMessages] = useState([]); // These messages are loaded from th backend
  const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
  const [message, setMessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [sessionId, setSessionId] = useState(session);
  const [sessionName, setSessionName] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [analyticsTypsOptionsVisible, setAnalyticsTypsOptionsVisible] = useState(false);
  const fileInputRef = useRef(null);

  // Integration variables
  const [zohoAuthModal, setZohoAuthModal] = useState(false);
  const [urlDataModal, setUrlDataModal] = useState(null);
  const [fileIdForDeletion, setFileIdForDeletion] = useState(null);

  // Loaders
  const [documentLoader, setDocumentLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [userMessageLoader, setUserMessageLoader] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [isChatLoaded, setIsChatLoaded] = useState(true);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(true);
  const [isChatInputEnabled, setIsChatInputEnabled] = useState(true);
  const [websiteModalShow, setWebsiteModalShow] = useState(false);
  const [docDeleteLoader, setDocDeleteLoader] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLoader, setEditingLoader] = useState(false);
  const [uploadDocModal, setUploadDocModal] = useState(false)


  // Bot variables
  const { fetchOrganisationData, organisationData } = props;

  // Reference Variables
  const documentUploadRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isUserInputFocused, setIsUserInputFocused] = useState(false);
  const location = useLocation();
  // const [uploadFileType, setUploadFileType] = useState(null);
  const [pendingFile, setPendingFile] = useState(null);
  const [url, setUrl] = useState(false)
  let uploadFileType = "PDF"

  //Sample DB
  const [showSampleDB, setShowSampleDB] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [docModal, setDocModal] = useState(false);

  //storage

  const [totalStorage, setTotalStorage] = useState(localStorage.getItem('totalStorage'))
  const [availableStorage, setAvailableStorage] = useState(localStorage.getItem('availableStorage'))

  // ** Function to toggle notificationbar
  const toggleNotification = () => setNotificationOpen(!notificationOpen);

  const userType = localStorage.getItem('user_type');

  useEffect(() => {
    if (userType) {
      setNotificationOpen(true);

      setTimeout(() => {
        setNotificationOpen(false);
      }, [5000])
    }
    localStorage.removeItem('user_type');
  }, [userType])

  "Sample DB"
  "============================"
  const toggleSmapleDB = () => {
    setShowSampleDB(prev => !prev)
  }

  "Storing the URL to Local"
  "============================"

  useEffect(() => {
    storeLastUrl(location.pathname);

  }, [location]);

  "Initali Load of the document"
  "============================"
  useEffect(() => {
    if (sessionId && sessionId !== 'new-session') {
      console.log("Loading session documents");
      setIsDocumentLoaded(false);
      loadSessionDocuments();
      loadChatMessages();
    } else {
      setAnalyticsTypsOptionsVisible(true);
    }
    messageFormattor();
  }, []);

  const isSessionIdValid = () => {
    return sessionId && sessionId !== 'new-session';
  }

  useEffect(() => {
    scrollToBottom();
  }, [messagesList]);

  "Hanlders for document uplaod"
  "============================"

  const handleFileUpload = (file) => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }
    const formData = new FormData();

    if (url) {
      const fileName = getDomainName(file);
      setDocumentLoader(true);
      formData.append('url', file);
      if (sessionId == 'new-session' && sessionName) {
        formData.append('name', sessionName);
      } else {
        formData.append('name', fileName);
      }
      formData.append('document_type', 'WEBSITE_URL');
      formData.append('size', 0);

      if (isSessionIdValid()) { formData.append('session', sessionId) }

      let loaderMessage = 'Please wait while I analyse the Website';
      setLoaderMessage(loaderMessage);
      const loaderTimeout = setTimeout(() => {
        scrollToBottomInstant();
        clearTimeout(loaderTimeout);
      }, 500);
    } else {

      const fileDetails = fetchUploadedDocumentDetails(file);

      setDocumentLoader(true);
      formData.append('document', file);
      if (sessionId == 'new-session' && sessionName) {
        formData.append('name', sessionName);
      } else {
        formData.append('name', file.name);
      }

      if (fileDetails.fileCategory == "PDF") {
        formData.append('document_type', uploadFileType);
      } else {
        formData.append('document_type', fileDetails.fileCategory);
      }

      formData.append('size', Math.ceil(fileDetails.fileSizeInKB));
      if (isSessionIdValid()) { formData.append('session', sessionId) }

      let loaderMessage = 'Please wait while I upload the document';
      if (fileDetails.fileSizeInKB >= 2000) {
        loaderMessage = 'Please wait while I upload the document (This may take a while)';
      }
      setLoaderMessage(loaderMessage);
      const loaderTimeout = setTimeout(() => {
        scrollToBottomInstant();
        clearTimeout(loaderTimeout);
      }, 500);
    }

    uploadDataFile(formData)
      .then(response => {
        if (response.status == statusCode.HTTP_201_CREATED) {
          setFileList([...fileList, { ...response.data }]);
          if (!isSessionIdValid()) {
            setSessionId(response.data.session);
          }
          setSessionName(response?.data?.name || 'new-session');
          if(url){
            showSuccessToast('Website added successfully');
            addNewBotMessage('Website added successfully. You can now start querying on this Website.');
          }else{
            showSuccessToast('File uploaded successfully');
            addNewBotMessage('Document uploaded successfully. You can now start querying on this document.');
          }
          
          if(response?.data?.session_socket_id){
            const sessionName = response?.data?.name || 'new-session'
            socketListenerForLargeFiles(response?.data?.session_socket_id, sessionName)
          }else{
            setSessionName(response?.data?.name || 'new-session');
            showSuccessToast('File uploaded successfully');
            addNewBotMessage('Document uploaded successfully. You can now start querying on this document.');
            setDocumentLoader(false);
            setLoaderMessage(null);
          }
        } else {
          showErrorToast('Error uploading file');
          addNewBotMessage('Error uploading file. Please try again');
          setDocumentLoader(false);
          setLoaderMessage(null);
        }
      })
      .catch((error) => {
        setLoaderMessage(null);
        addNewBotMessage('Error uploading file. Please try again');
        setDocumentLoader(false);
        setLoaderMessage(null);
      });
  };

  const socketListenerForLargeFiles = (session_socket_id, sessionName) => {
    setDocumentLoader(true);
    initiateNewSockerConnection(session_socket_id, [
      {
        event: "file_loaded",
        callback: (data) => {
          if(data == 'success'){
            showSuccessToast('File uploaded successfully');
            addNewBotMessage('Document uploaded successfully. You can now start querying on this document.');
            setDocumentLoader(false);
            setLoaderMessage(null);
            setSessionName(sessionName);
          }else{
            showErrorToast('Error uploading file');
            addNewBotMessage('Error uploading file. Please try again');
            setDocumentLoader(false);
            setLoaderMessage(null);
          }
        }
      }]
    )
  }

  const loadFile = (e) => {
    const file = e.target.files[0];
    const fileDetails = fetchUploadedDocumentDetails(file);

    if (fileDetails?.fileCategory == 'UNKNOWN' || fileDetails.fileType == 'UNKNOWN') {
      showErrorToast('File type not supported. Please upload a valid document');
      return;
    }

    if (fileDetails?.fileCategory === 'PDF' || fileDetails.fileType === 'PDF') {
      setPendingFile(file);
      setUploadDocModal(true);
      return;
    }

    if (fileDetails?.fileCategory == 'UNKNOWN' || fileDetails.fileType == 'WEBP') {
      showErrorToast('Only PNG and JPEG image supported. Please upload a valid image file');
      return;
    }

    handleFileUpload(file);
  }

  const onModalConfirm = () => {
    uploadFileType = "SCANNED_PDF";
    setUploadDocModal(false);

    if (pendingFile) {
      handleFileUpload(pendingFile);
      setPendingFile(null);
    }
  };
  const onModalClose = () => {
    uploadFileType = "PDF";
    setUploadDocModal(false);

    if (pendingFile) {
      handleFileUpload(pendingFile);
      setPendingFile(null);
    }
  };

  const initLoadDocument = () => {
    setAnalyticsTypsOptionsVisible(false)

    if (fileList.length == 5) {
      addNewBotMessage('You have reached the maximum limit of 5 documents. You may use a new session to upload more documents.');
      const scrollTimeout = setTimeout(() => {
        scrollToBottomInstant();
        clearTimeout(scrollTimeout);
      }, 0);
    } else {
      fileInputRef.current.click();
    }
  }

  const uplaodDocumentModal = (
    <Modal className="document-confirmation-modal" isOpen={uploadDocModal} toggle={() => setUploadDocModal(false)} style={{ maxWidth: '500px', borderRadius: '16px' }}>
      {/* <ModalHeader>Confirm Your Document Type</ModalHeader> */}
      <ModalBody style={{ color: 'black', paddingTop: '25px', fontSize: '22px', textAlign: 'center' }}>
        Please confirm: Is it a scanned PDF?
        <span style={{ color: 'grey', display: 'block', fontSize: '13px', textAlign: 'center', marginTop: '10px' }}>
          Scanned PDFs often contain images or non-selectable text. Confirming the type helps ensure accurate processing.
        </span>


        <div style={{ marginBlock: '30px', display: 'flex', justifyContent: 'center', borderRadius: '16px' }}>
          <Button color="primary" style={{ marginRight: '10px' }} onClick={() => {
            onModalConfirm();
          }}>Yes</Button>
          <Button onClick={() => { onModalClose() }}>No</Button>
        </div>
      </ModalBody>
      {/* <ModalFooter>
        
      </ModalFooter> */}
    </Modal>
  );

  const loadSessionDocuments = () => {
    loadSessionDocumentHandler(sessionId)
      .then(response => {
        if (response.status == statusCode.HTTP_200_OK) {
          console.log('test', response)
          setSessionName(response?.data?.session_name || response?.data[0]?.name)
          setFileList(response?.data?.documents || response?.data);
          setIsDocumentLoaded(true);
        } else {
          showErrorToast('Error loading documents. Please refresh the page and try again');
        }
      })
  }

  const handleDeleteDocument = () => {
    if (fileIdForDeletion) {
      setDocDeleteLoader(true);
      deleteDocumentHandler(sessionId, fileIdForDeletion)
        .then(response => {
          setDocDeleteLoader(false);
          setFileIdForDeletion(null);

          if (response.status == statusCode.HTTP_200_OK) {
            showSuccessToast('File Deleted successfully');
            setFileList(fileList.filter(file => file.id !== fileIdForDeletion))
          } else {
            showErrorToast('Error deleting file');
          }


        })
        .catch(err => {
          console.log(err)
          showErrorToast('Error while deleting documents. Please refresh the page and try again');
          setDocDeleteLoader(false);
        })

    }
  }

  const handleDOcumentDelete = () => {
    setShowConfirmation(prev => !prev);
  }
  const renderDocument = () => {
    if (!isDocumentLoaded) {
      return (
        <div>Loading Docuement...</div>
      )
    } else if (isDocumentLoaded && (!fileList || fileList.length === 0)) {
      return (
        <div style={{ textAlign: 'center', marginBlock: '50px', fontWeight: '700' }}>No document available</div>
      )
    } else if (isDocumentLoaded && fileList && fileList.length > 0) {
      return (
        <>
          {
            fileList.map((file, index) => {
              return (
                <li key={index} className="document-item">
                  {renderDocumentIcon(file.document_type)} <span className="document-item__file-name">{file.name}</span>
                  <button className="document-item__delete-btn" onClick={() => {
                    setFileIdForDeletion(file.id)
                    handleDOcumentDelete()
                  }}>{docDeleteLoader ? <ClipLoader size={16} /> : <FiTrash2 />}</button>
                </li>
              )
            })
          }
        </>
      )

    }
  }
  const renderSingleDocument = () => {
    if (!isDocumentLoaded) {
      return (
        <div>Loading Docuement...</div>
      )
    } else if (isDocumentLoaded && (!fileList || fileList.length === 0)) {
      return (
        <div style={{ textAlign: 'left', fontWeight: '700' }}>Upload Documents</div>
      )
    } else {
      return (
        <div className="document-list-mobile">
          {
            fileList.map((file, index) => {
              return (
                <div className="document-list-mobile__item">{renderDocumentIcon(file.document_type)} {file.name}</div>
              )
            })
          }
        </div>
      )

    }
  }
  "Hanlders for chat messages"
  "=========================="

  const randomAlphaNumeric = (length = 6) => {
    let s = '';
    Array.from({ length }).some(() => {
      s += Math.random().toString(36).slice(2);
      return s.length >= length;
    });
    return s.slice(0, length);
  };


  const scrollToBottom = () => {
    const scroll = chatContainerRef.current;
    if (scroll) {
      scroll.scrollTo({
        top: scroll.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottomInstant = () => {
    const scroll = chatContainerRef.current;
    if (scroll) scroll.scrollTop = scroll.scrollHeight;
  };

  const loadChatMessages = () => {
    loadSessionMessagesHandler(sessionId)
      .then(response => {
        setLoaderMessage(null);
        setIsChatLoaded(true);
        if (response.status == statusCode.HTTP_200_OK) {
          loadPreviousMessages(response.data);
        } else {
          addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.');
        }
      })
      .catch((error) => {
        setLoaderMessage(null);
        setIsChatLoaded(true);
        console.error('Error:', error);
        addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.', showCTA = "false");
      })
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const input = documentUploadRef.current.value;
    documentUploadRef.current.value = "";
    setIsUserInputFocused(false);
    if (input.trim() !== "") {
      addNewUserMessage(input.trim());
      if (isSessionIdValid()) {
        fetchBotResponse(input.trim());
      } else {
        addNewBotMessage("Please upload a document first to get started. Thanks! 📄✨");
      }
    }
  };


  const processMarkdownString = (str) => {
    console.log("Str: ", str)
    let trimmedStr = str.trim();
    if (trimmedStr.startsWith("```markdown") && trimmedStr.endsWith("```")) {
      return trimmedStr.slice(11, -3).trim();
    }
    return trimmedStr;
  }


  const fetchBotResponse = (query) => {
    if (!isSessionIdValid()) {
      addNewBotMessage("You have not uploaded any document yet. Please upload a document first.");
      return;
    }

    fetchQueryResponseHandler({
      session: sessionId,
      query: query
    })
      .then((response) => {
        setLoaderMessage(null);
        if (response.status == statusCode.HTTP_200_OK) {
          const botMessage = processMarkdownString(response.data.message);
          console.log("Returned message: ", botMessage)
          addBotResponseToUser({ message: botMessage });
        } else {
          addBotResponseToUser("I am currently facing some issues. Please try again later.");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoaderMessage(null);
        addBotResponseToUser("I am currently facing some issues. Please try again later.");
      });
  }
  const handleSQLMessage = (sqlMessage) => {
    const input = sqlMessage;
    console.log(input, 'inut')
    setIsUserInputFocused(false);
    if (input.trim() !== "") {
      addNewUserMessage(input.trim());
      if (isSessionIdValid()) {
        fetchBotResponse(input.trim());
      } else {
        addNewBotMessage("Please upload a document first to get started. Thanks! 📄✨");
      }
    }
  };
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();  // Prevent the default behavior of a line break
      handleSendMessage(event);  // Replace this with your actual send message function
      documentUploadRef.current.value = "";
    }
  };

  const handleFocus = () => setIsUserInputFocused(true);

  const handleBlur = () => setIsUserInputFocused(false);

  const addBotMessageWithMarkdown = (message) => {
    setMessagesList([
      ...messagesList,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownRenderer markdownText={message} />
        </div>
      </div>
    ]);
  }

  const addNewBotMessageWithoutTyping = (botMessage, showCTA) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#222222" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownParser session={sessionId} texts={[botMessage]} isTyping={false} />
          {showCTA ? <ResponseActions session={session} /> : ''}
        </div>
      </div>
    ]);
  }

  const addNewBotMessage = (botMessage, showCTA) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#222222" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          {/* <TypingEffect texts={[botMessage]}/> */}
          <MarkdownParser session={sessionId} texts={[botMessage]} />
          {showCTA ? <ResponseActions session={session} /> : ''}
        </div>
      </div>
    ]);
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
      clearTimeout(scrollTimeout);
    }, 500);
  }
  const addNewBotMessageWithTableResponse = (botMessage) => {

    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          <WizBotQueryResponse
            key={botMessage}
            response={botMessage}
            session={sessionId}
            handleSQLMessage={handleSQLMessage}
          />
        </div>
      </div>
    ]);
    const scrollTimeout = setTimeout(() => {
      scrollToBottomInstant();
      clearTimeout(scrollTimeout);
    }, 500);
  }
  const addNewBotMessageWithChartResponse = (botMessage) => {

    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message" style={{ width: "100%" }}>
          <ChartInChat chartsData={chartsData} />
        </div>
      </div>
    ]);

    const scrollTimeout = setTimeout(() => {
      scrollToBottomInstant();
      clearTimeout(scrollTimeout);
    }, 500);
  }

  const addBotResponseToUser = (botMessage) => {
    // setMessagesList(messagesList.slice(0, messagesList.length - 1));
    // addNewBotMessage(botMessage);
    if (botMessage && botMessage.table) {
      addNewBotMessageWithTableResponse(botMessage.table);
    } else if (botMessage && botMessage.chart) {
      addNewBotMessageWithChartResponse(botMessage.chart);
    } else if (botMessage && botMessage.message) {
      addNewBotMessage(botMessage.message, true);
      // addNewBotMessageWithTableResponse(botMessage.message)
    } else {
      addNewBotMessage(botMessage, true)
    }

    const scrollTimeout = setTimeout(() => {
      scrollToBottomInstant();
      clearTimeout(scrollTimeout);
    }, 500);
  }

  const addOldUserMessage = (userMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()}>
        <div className={`message user-message`}>
          <div>{userMessage}</div>
        </div>
        <div className={"user-message-date"}>{getCurrentDate()}</div>
      </div>
    ]);
  }

  const addNewUserMessage = (userMessage) => {
    setMessagesList((prevMessages) => {
      const updatedMessages = [...prevMessages,
      <div key={randomAlphaNumeric()}>
        <div className={`message user-message`}>
          <div>{userMessage}</div>
        </div>
        <div className={"user-message-date mobile-hidden"}>{getCurrentDate()}</div>
      </div>]
      return updatedMessages;
    });

    scrollToBottomInstant();

    if (isSessionIdValid()) {
      setLoaderMessage('Please wait while I fetch the response for your query');
    }
  }

  const messageFormattor = () => {
    if (isSessionIdValid() && messagesList.length === 0 && isChatLoaded) {
      addNewBotMessage(`Welcome back!!👋 ${firstName}`, false);
      setLoaderMessage('Please wait while I fetch your previous chat history');
    } else if (isSessionIdValid() && messagesList.length > 0) {
      // addNewBotMessage(`Welcome back!!👋 ${firstName}. Please wait while I fetch your previous chat history...`);
    } else if (!isSessionIdValid()) {
      addNewBotMessage(`Welcome!!👋 ${firstName}. I am your personal assistant.`, false);
      const responseTimeout = setTimeout(() => {
        addNewBotMessage(`Please upload your documents, and I can help you query on that knowledge base 😇.`, false);
        clearTimeout(responseTimeout);
      }, 1500);
    }
  }

  const loadPreviousMessages = (loadedMessages) => {
    //Remove only the last message in the messageList
    loadedMessages.forEach((messageItem) => {
      if (messageItem.is_bot) {

        // addNewBotMessageWithoutTyping(messageItem.message);
        // if (messageItem.query_response) {
        //   messageItem.data = JSON.parse(messageItem.query_response)
        // }
        // addNewBotMessageWithTableResponse(messageItem);
        if (messageItem && messageItem.table) {
          addNewBotMessageWithTableResponse(messageItem.table);

        } else if (messageItem && messageItem.chart) {
          addNewBotMessageWithChartResponse(messageItem.message,);
        } else {

          addNewBotMessageWithoutTyping(messageItem.message, true);
          // addNewBotMessageWithTableResponse(messageItem.message);

        }
      } else { addOldUserMessage(messageItem.message); }
    })
    const scrollTimeout = setTimeout(() => {
      scrollToBottomInstant();
      clearTimeout(scrollTimeout);
    }, 500);
  }

  //Mobile screen
  const renderDocumentMobileTabPanel = () => {
    if (selectedTab === 0) {
      return (
        <>
          <span className="document-list" >
            {renderSingleDocument()}
          </span>

          {/* Action Buttons */}
          <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
          {
            !documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}><Upload size={16} /></button>
          }
          {
            documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>...</button>
          }

          {/* Navigations */}
          {/* <button
              onClick={() => window.location.href = '/wizDoc/new-session'}
              style={{
                marginTop: '5px',
                width: '49%',
                background: '#90CAF9',
                color: '#283593',
                cursor: 'pointer'
              }} className="upload-btn">New</button> */}
          <Link
            to='/wizDoc-history'
            style={{
              width: '64px',
              background: '#90CAF9',
              color: '#283593',
              cursor: 'pointer',
              borderRadius: '5px',
              textAlign: 'center',
              marginLeft: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}><Archive size={16} /></Link>
        </>
      )
    } else if (selectedTab === 1) {
      return (
        <SourceContainer
          onClickSource={(index) => {
            switch (index) {
              case 0:
                setUrlDataModal('Website');
                break;
              case 1:
                setUrlDataModal('Youtube');
                break;
              case 2:
                setZohoAuthModal(true);
                break;
              case 3:
                break;
            }
          }}
        />
      )
    }
  }

  const closeUrlDataModel = () => {
    setUrlDataModal(null);
  }

  const renderAnalysisSelectOption = () => {
    if (analyticsTypsOptionsVisible) {
      return (<div
        style={{
          position: "absolute",
          top: "40%",
          left: "10",
          // width: "100%",
          right: '20px',
          left: '20px',
          height: "30%",
        }}
        className="analysis-wrapper"
      >
        <Card className="analysis-wrapper__card" onClick={() => {
          initLoadDocument();
          setAnalyticsTypsOptionsVisible(false);
        }}>
          <CardBody>
            <div className='card-img'>
              <img src={AnalyzeDoc} />
            </div>
            <h2>Upload Document</h2>
          </CardBody>
        </Card>

        <Card className="analysis-wrapper__card" onClick={() => { navigate('/database-intelligence') }}>
          <CardBody>
            <div className='card-img'>
              <img src={AnalysisImg} />
            </div>
            <h2>Connect Your Database</h2>
          </CardBody>
        </Card>

        <Card className="analysis-wrapper__card" onClick={toggleSmapleDB}>
          <CardBody>
            <div className='card-img'>
              <img src={SampleDatabase} />
            </div>
            <h2><span style={{ display: 'block', marginTop: '5px' }}>Try our samples!</span></h2>
          </CardBody>
        </Card>
        <Card className="analysis-wrapper__card" onClick={() => {
          setUrl(true)
          setWebsiteModalShow(true);
          setAnalyticsTypsOptionsVisible(false);
        }}>
          <CardBody>
            <div className='card-img'>
              <img src={Web} />
            </div>
            <h2>Add Webiste</h2>
          </CardBody>
        </Card>

        <DBSample isOpen={showSampleDB} toggle={toggleSmapleDB} />
      </div>)
    }
  }
  //Document Modal
  const toggleModal = () => {
    setDocModal(!docModal);
  };

  useEffect(() => {
    if (sessionId != "new-session") {
      storageDetailsHandler(sessionId)
        .then(response => {
          if (response.status == statusCode.HTTP_200_OK) {
            localStorage.setItem('totalStorage', response?.data?.total_storage)
            localStorage.setItem('availableStorage', response?.data?.available_storage)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  const getCreditUsageDetails = (available, total) => {
    // Calculate the percentage of credits used
    const percentageUsed = ((total - available) / total) * 100;

    // Determine the color based on the percentage used
    let progressColor = 'success'; // Default color
    if (percentageUsed >= 100) {
      progressColor = 'danger'; // Red for 100% usage
    } else if (percentageUsed >= 80) {
      progressColor = 'warning'; // Yellow for usage above 80%
    }

    return {
      percentageUsed: percentageUsed.toFixed(0),
      progressColor
    };
  };
  // Get usage details
  const { percentageUsed, progressColor } = getCreditUsageDetails(availableStorage, totalStorage);

  const documentModal = (
    <Modal isOpen={docModal} toggle={toggleModal} style={{ maxWidth: '700px' }}>
      <ModalHeader >Documents List</ModalHeader>
      <ModalBody>
        <ul className="document-list">
          {renderDocument()}
        </ul>
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'space-between' }}>
        <div className="storage-details">
          <div className='storage-details__progress' style={{ alignItems: 'center', display: 'flex', width: '100%' }}>
            {percentageUsed || 0}%
            <Progress
              color={progressColor}
              value={percentageUsed || 0}
              style={{ width: '80%', marginLeft: '10px' }}
            >
            </Progress>
          </div>
          <span style={{ marginBlock: '6px 10px', display: 'inline-block' }}>You have used {percentageUsed || 0}% of total credits. <Button color="link" style={{ padding: 0, textDecoration: 'underline' }} onClick={() => navigate('/products-and-plans')}>
            Click here to upgrade!
          </Button></span>
        </div>
        <Button onClick={toggleModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );

  // Toggle editing mode
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  useEffect(() => {
    // Load the session name from localStorage when the component mounts
    const storedSession = localStorage.getItem('new_session');
    if (storedSession) {
      setSessionName(storedSession);
    }
  }, []);

  const handleEnterKeyPress = (e) => {
    if (sessionId == 'new-session') {
      setIsEditing(false);
      showSuccessToast('Session name has been saved successfully! Please upload your document.');
    } else {
      // Call the API or handle the update logic when Enter is pressed
      const payload = {
        name: sessionName
      }
      setEditingLoader(true)
      updateSessionHandler(sessionId, payload)
        .then((response) => {
          console.log(response)
          setEditingLoader(false)
          setIsEditing(false);

          if (response.status == statusCode.HTTP_200_OK) {
            localStorage.setItem('new_session', response.data.new_name)
            setSessionName(response.data.new_name)
            showSuccessToast('Session name updated successfully');

          } else {
            showErrorToast('Cannot update session name at this moment')
          }

        })
        .catch(err => {
          setEditingLoader(false)
          console.log(err)
        })
    }
  };


  return (
    <div className="document-intelligence">
      <PageHeader
        pageName={"WizDoc"}
        backButtonEnabled={false}
      // buttons=[{name: '', title: '', callback:''}]
      />
      <div className="blogPreviewContainer">
        <div className='main-container vh-85 padding_0'>
          <div className="mobile-bar">
            {renderDocumentMobileTabPanel()}
          </div>
          {/* <div className="left-panel">
            <div className="left-panel__header">Document</div>
            <div className="left-panel__body">
              <ul className="document-list">
                {renderDocument()}
              </ul>
            </div>
            {renderDocumentTabPanel()}
          </div> */}
          <div className="chatbot-section live-chat right-panel" style={{ width: '100%' }}>
            <div className="chatbot-section__top">
              <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: '600', color: 'black' }}>WizDoc <ChevronRight size={32} />
                {isEditing ? (

                  <InputGroup>
                    <Input
                      type="text"
                      value={sessionName}
                      onChange={(e) => setSessionName(e.target.value)}
                      autoFocus
                      style={{ width: '150px', padding: '8px 10px' }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleEnterKeyPress();
                        }
                      }}

                    />
                    <Button color="primary" onClick={handleEnterKeyPress} style={{ padding: '8px 10px' }}>
                      {
                        editingLoader ?
                          <ClipLoader size={16} />
                          :
                          <Check size={16} />
                      }

                    </Button>
                  </InputGroup>
                ) : (
                  <span style={{ marginLeft: '10px', fontSize: '20px' }}>
                    {toCamelCase(sessionName ? sessionName : 'new-session')}
                    <Edit2
                      size={16}
                      style={{ marginLeft: '8px', cursor: 'pointer' }}
                      onClick={toggleEdit}
                    />

                  </span>
                )}
              </h2>
              <div className="chatbot-section__btn-wrapper">
                <Button color="primary"
                  onClick={toggleModal}
                  style={{
                    fontWeight: '700',
                    marginRight: '5px'
                  }}><File size={16} /> Show Uploaded Documents {`(${fileList?.length || 0})`} </Button>
                {documentModal}
                <Button color="primary"
                  onClick={initLoadDocument}
                  style={{
                    fontWeight: '700',
                    marginRight: '5px'
                  }}><Upload size={16} /> Upload Document </Button>
                <Button color="primary"
                  onClick={() => window.location.href = '/wizDoc/new-session'}
                  style={{
                    fontWeight: '700',
                    marginRight: '5px'
                  }}><PlusCircle size={16} /> New </Button>
                <Button color="primary"
                  onClick={() => navigate('/wizDoc-history')}
                  style={{
                    fontWeight: '700',
                    marginRight: '5px'
                  }}><Layers size={16} /> History </Button>

                <Button color="success"
                  onClick={() => navigate('/products-and-plans')}
                  style={{
                    fontWeight: '700',
                  }}><DollarSign size={16} /> Upgrade</Button>
              </div>
            </div>
            <div
              ref={chatContainerRef}
              className="chat-pannel"
            >
              {messagesList}
              {renderAnalysisSelectOption()}
              {loaderMessage &&
                <div key={"9890809"} className={"bot-image-text-container"}>
                  <div className={"bot-image-container"}>
                    <img className={"bot-image"}
                      src={botImage}
                      alt={""} />
                  </div>
                  <div className="message bot-message">
                    <TypingEffect
                      texts={[loaderMessage]}
                      addLoader={true}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="chat-bot-input-wrapper">
              <form className={`chat-bot-input-container ${isUserInputFocused ? "focused" : ""}`}
                onSubmit={handleSendMessage} onKeyPress={handleKeyPress}>
                <input
                  className={"chat-bot-input"}
                  type="text"
                  placeholder="Ask any question pertaining to your document...."
                  disabled={!isChatInputEnabled}
                  autoFocus={true}
                  ref={documentUploadRef}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <button className="send-button" type={"submit"}>
                  <i color={"#7169e8"} className="arrow-icon"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {uplaodDocumentModal}


      <WebsiteModal
        websiteModalShow={websiteModalShow}
        toggle={() => setWebsiteModalShow(false)}
        handleUrl={handleFileUpload}
      />
      {
        zohoAuthModal &&
        <ZohoAuth
          onClose={() => setZohoAuthModal(false)}
        />
      }
      {
        urlDataModal &&
        <UrlDataIntegrations
          urlType={urlDataModal}
          onClose={closeUrlDataModel}
        />
      }
      {notificationOpen ?
        <NotificationModel open={notificationOpen} toggleNotification={toggleNotification} /> : <></>}
      <ConfirmationModal
        open={showConfirmation}
        // toggle={handleDOcumentDelete} 
        onConfirm={() => {
          handleDeleteDocument()
          setShowConfirmation(false)
        }}
        onCancel={() => {
          setShowConfirmation(false)
          setFileIdForDeletion(null)
        }}
        title={'Are you sure you want to delete this document? This action cannot be undone.'}
      />
    </div>
  );
};

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DocumentIntelligence);