import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PageHeader from "../../../../@core/components/PageHeader";
import CustomTabs from "../../../components/CustomTabs";
import { InfinitySpin } from "react-loader-spinner";
import { statusCode } from "../../../../utility/constants/utilObject";
import { runCombinedForecastHandler } from "../../../../services/cashflowMonitoring";

import invoiceData from './testData/invoices.json';
import expensesData from './testData/expenses.json';
import purchaseOrdersData from './testData/purchase_orders.json';
import salesOrderData from './testData/sales_orders.json';
import billsData from './testData/bills.json';
import { formatText } from "../../../../utility/helper";


// Import JSON data
// import invoiceData from "./testData/invoice.json";
// import expensesData from "./testData/expenses.json";
// import purchaseOrdersData from "./testData/purchase_orders.json";
// import salesOrderData from "./testData/sales_order.json";
// import billsData from "./testData/bills.json";

const CashFlowTable = () => {
    const location = useLocation();
    const [multiLoading, setMultiloading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [loader, setLoader] = useState(false);
    const { state } = location;
    const [receivedData, setReceivedData] = useState(state.data);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    function formatAnalysisDate(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            return `${months[date.getMonth()]} ${date.getFullYear()}`;
        }
    }

    // Local JSON data mapping
    const dataMapping = {
        0: invoiceData,
        1: billsData,
        2: salesOrderData,
        3: purchaseOrdersData,
        4: expensesData
    };

    const currentData = dataMapping[activeTab]?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Calculate the number of pages
    const totalPages = Math.ceil(dataMapping[activeTab]?.length / itemsPerPage);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleForecast = () => {
        setLoader(true);
        runCombinedForecastHandler({
            session: 1,
            time_period: 24
        })
            .then(response => {
                if (response.status == statusCode.HTTP_200_OK) {
                    const data = response.data;
                    navigate("/cashflow-analysis/forecast", {
                        state: { data },
                    });
                    setLoader(false);
                }
            })
            .catch(error => {
                setLoader(false);
                console.error('Error during forecast:', error);
            });
    };

    const renderNoDataPage = () => {
        return (
            <div style={{ marginTop: "200px", width: '100%', textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                    <div>No data available</div>
                </div>
            </div>
        )
    }
    console.log(currentData)
    const renderPanelContainer = () => {
        if (multiLoading) {
            return (
                <div style={{ marginTop: "100px" }}>
                    <MultiStepLoader stepNumber={activeStep} />
                </div>
            )
        } else {
            if (currentData.length === 0) {
                return renderNoDataPage();
            } else {
                return (
                    <>
                        <div className="transaction-table-wrapper" style={{ marginTop: '20px' }}>
                            <table className="transaction-table">
                                <thead>
                                    <tr>
                                        {Object.keys(currentData[0]).map((key) => (
                                            <th key={key}>{formatText(key)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((item, index) => (
                                        <tr key={index}>
                                            {Object.values(item).map((value, i) => (
                                                <td key={i}>{typeof value === 'string' ? formatText(value) : value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <div className="pagination-wrapper">
                            <Pagination>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <PaginationItem key={i} active={i === currentPage}>
                                        <PaginationLink onClick={() => handlePageClick(i)}>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                            </Pagination>
                        </div>
                    </>
                )
            }
        }
    }

    return (
        <>
            <PageHeader
                pageName={"Cashflow Analysis & Forecasting | Session Id"}
                backButtonEnabled={true}
            />
            <div className='container-fluid vh-85'>
                <div className='page-container no-scroll-bar' style={{ padding: '20px' }}>

                    {loader ? (
                        <div className="loading-wrapper">
                            <InfinitySpin color="#0C6980" height="100px" />
                            <span>Preparing Forecast...</span>
                        </div>
                    ) : (
                        <div className="cashflow-table">
                            <div className="cashflow-table__banner">
                                <div className="cashflow-table__content">
                                    <h1 className="cashflow-table__title">{receivedData?.results?.forecast_session?.company || ''}</h1>
                                    <p>Analysis of books from {formatAnalysisDate(receivedData?.results?.forecast_session?.start_date || '')} - {formatAnalysisDate(receivedData?.results?.forecast_session?.end_date || '')}</p>
                                </div>
                                <div className="analysis-dropdown">
                                    <Button color="success" onClick={handleForecast}>Run Forecast</Button>
                                </div>
                            </div>
                            <div className='tab-container' style={{ marginTop: '20px' }}>
                                {!multiLoading && (
                                    <CustomTabs
                                        tabs={["Invoices", "Bills", "Sales Orders", "Purchase Orders", "Expenses"]}
                                        tabStatus={[true, true, true, true, true]}
                                        onTabSelect={setActiveTab}
                                        iisLoading={[false, false, false, false, false]}
                                        isCompleted={[true, false, false, false, false]}
                                    />
                                )}
                            </div>
                            <div className='page-container no-scroll-bar'
                                style={{
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '0px',
                                    height: '80vh',
                                }}
                            >
                                {renderPanelContainer()}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default CashFlowTable;
