import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
    creditChecker: "analytics/session/check_credits/",
    creditTest: "billing/plan/check_plan_api/",
}



export const creditCheckerHandler = () => {
    return REQUESTS.get(API_ROUTES.creditChecker, localStorage.getItem('token'))
};

export const creditCheckTest = () => {
    return REQUESTS.get(API_ROUTES.creditTest, localStorage.getItem('token'))
};