import { useState } from "react";
import { Card, Form, FormGroup, Input, Label, Row, Col, Button } from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { fetchBookDataHandler } from "../../../../services/cashflowMonitoring";
import PageHeader from "../../../../@core/components/PageHeader";
import { statusCode } from "../../../../utility/constants/utilObject";

const CompanySelectionScreen = () => {
    const navigate = useNavigate(); // Create navigate function
    const location = useLocation();
    const { state } = location;
    const [loader, setLoader] = useState(false);
    const [receivedData, setReceivedData] = useState(state?.data)
    const [formData, setFormData] = useState({
        company: "",
        startDate: "",
        endDate: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value // Update the specific field in the formData object
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.values(formData).length) {
            setLoader(true);

            const payload = {
                forecast_session: receivedData.forecast_id || '',
                start_date: formData.startDate,
                end_date: formData.endDate,
                company: formData.company
            }

            fetchBookDataHandler(payload)
                .then(response => {
                    setLoader(false);
                    if (response.status == statusCode.HTTP_200_OK) {
                        const data = response.data;
                        navigate("/cashflow-analysis/analysis", {
                            state: {data},
                        });
                    }
                })
                .catch(error => {
                    setLoader(false);

                })
        } else {
            console.log('Form is not completed !')
        }


        // const timeout = setTimeout(() => {
        //     navigate("/cashflow-analysis/analysis", {
        //         state: formData
        //     });
        //     clearTimeout(timeout);
        // }, 3000);


    }
    return (
        <>
            <PageHeader
                pageName={`Cashflow Analysis & Forecasting | Connection Id: ${receivedData.zoho_connection_id}`}
                backButtonEnabled={true}
            />
            <div className='container-fluid vh-85'>
                <div className='page-container no-scroll-bar' style={{ padding: '20px' }}>
                    {
                        loader ?
                            <div className="loading-wrapper">
                                <InfinitySpin color="#0C6980" height="100px" />
                                <span>Analysing your books...</span>
                            </div>
                            :
                            <div className="company-selection-form">
                                <Form>
                                    <FormGroup>
                                        <div className="template-container-header" style={{ textAlign: 'center', marginBottom: '15px' }}>
                                            Select Company
                                        </div>
                                        <Input
                                            id="company-select"
                                            name="company"
                                            type="select"
                                            value={formData.company}
                                            onChange={handleChange}
                                        >
                                            <option value="">--Select Company--</option>
                                            {
                                                receivedData.companies && receivedData.companies.map((company, index) => {
                                                    return (
                                                        <option value={company.name}>{company.name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                    </FormGroup>
                                    <div className="template-container-header" style={{ textAlign: 'center', marginBottom: '15px' }}>
                                        Fetch Books From
                                    </div>
                                    <FormGroup>
                                        <Row>
                                            {/* Start Date */}
                                            <Col md={6}>
                                                <Label for="startDate">Start Date</Label>
                                                <Input
                                                    id="startDate"
                                                    name="startDate"
                                                    placeholder="Start Date"
                                                    type="date"
                                                    value={formData.startDate}
                                                    onChange={handleChange}
                                                />
                                            </Col>

                                            {/* End Date */}
                                            <Col md={6}>
                                                <Label for="endDate">End Date</Label>
                                                <Input
                                                    id="endDate"
                                                    name="endDate"
                                                    placeholder="End Date"
                                                    type="date"
                                                    value={formData.endDate}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <Button color="success" onClick={handleSubmit}>Proceed</Button>
                                </Form>
                            </div>
                    }

                </div>
            </div>

        </>
    )
}

export default CompanySelectionScreen;