// AMLTransaction.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file
import { Bar } from 'react-chartjs-2'; // Import Bar component from react-chartjs-2

const AMLTransaction = ({ suspiciousActivities, monthlyData, paymentInstrumentsData, aml_analytics }) => {

  console.log(suspiciousActivities, monthlyData, paymentInstrumentsData, aml_analytics)
  const formatToTwoDecimals = (value) => {
    // Check if the value is a number
    if (typeof value === 'number') {
      // Return the value formatted to 2 decimal places
      return parseFloat(value.toFixed(2));
    }
    return 0; // Return 0 if not a number or if value is invalid
  };


  return (
    <div className="aml-container">
      {/* Top Summary Blocks */}
      <div className="summary-blocks">
        <div className="summary-block">
          {/* <h2>{aml_analytics.overall_score}</h2> */}
          <h2>23</h2>

          <p>AML Risk Score</p>
        </div>
        <div className="summary-block">
          {/* <h2>{formatToTwoDecimals(aml_analytics.daily_avg_balance)}</h2> */}
          <h2>{formatToTwoDecimals(2334)}</h2>

          <p>Daily Avg Balance</p>
        </div>
        <div className="summary-block">
          <h2>{aml_analytics?.max_balance || 0}</h2>
          <p>Max Balance</p>
        </div>
        <div className="summary-block">
          <h2>{aml_analytics?.min_balance || 0}</h2>
          <p>Min Balance</p>
        </div>
        <div className="summary-block">
          <h2>{Math.abs(aml_analytics?.days_gap_between_max_min || 0)}</h2>
          <p>Days Gap b/w Max & Min Balance</p>
        </div>
        <div className="summary-block">
          <h2>{aml_analytics?.total_transactions || 0}</h2>
          <p>Transactions</p>
        </div>
      </div>

      {/* Content Blocks */}
      <div className="content-blocks">
        {/* Suspicious Activities Table */}
        <div className="suspicious-activities-block">
          <h3>Suspicious Activities</h3>
          <table className="suspicious-activities-table">
            <thead>
              <tr>
                <th>Activity</th>
                <th>Incidences</th>
              </tr>
            </thead>
            <tbody>
              {suspiciousActivities.map((activity, index) => (
                <tr key={index}>
                  <td>{activity.name}</td>
                  <td>{activity.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Comparative Bar Graph for Monthly Deposits vs Withdrawals */}
        <div className="comparative-bar-graph">
          <h3>Monthly Deposits & Withdrawals (₹)</h3>
          <Bar
            data={{
              labels: monthlyData.map((data) => data.month),
              datasets: [
                {
                  label: 'Deposits',
                  data: monthlyData.map((data) => data.deposits),
                  backgroundColor: '#7CB342',
                  borderRadius: 5,
                  barThickness: 10,
                },
                {
                  label: 'Withdrawals',
                  data: monthlyData.map((data) => data.withdrawals),
                  backgroundColor: '#FF9800',
                  borderRadius: 5,
                  barThickness: 10,
                },
              ],
            }}
            options={{
                responsive: true,
                plugins: {
                    legend: { position: 'top' },
                },
                scales: {
                    x: {
                    title: {
                        display: true,
                        text: 'Month',
                    },
                    // Add space between the bars
                    barPercentage: 0.6,  // Adjust this value to control the width of bars relative to the category width
                    categoryPercentage: 0.8,  // Adjust this value to control the spacing between categories
                    },
                    y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Amount (₹)',
                    },
                    },
                },
            }}
          />
        </div>
      </div>

      {/* Comparative Graph by Payment Instrument */}
      <div className="full-width-graph">
        <h3>Deposits vs Withdrawals by Payment Instrument</h3>
        <Bar
          data={{
            labels: ['UPI', 'ATM', 'CASH', 'Cheque', 'IMPS', 'NEFT', 'RTGS', 'NACH'],
            datasets: [
              {
                label: 'Deposits',
                data: paymentInstrumentsData.deposits,
                backgroundColor: '#7CB342',
                borderRadius: 5,
                barThickness: 10,
              },
              {
                label: 'Withdrawals',
                data: paymentInstrumentsData.withdrawals,
                backgroundColor: '#FF9800',
                borderRadius: 5,
                barThickness: 10,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Payment Instrument',
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Amount (₹)',
                },
              },
            },
          }}
          height={80} // Adjusted height to make the block half in size
        />
      </div>
    </div>
  );
};

export default AMLTransaction;
