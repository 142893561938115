import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from 'reactstrap';
import './index.scss';
import { showErrorToast } from '../../../utility/helper';

const ZohoBooksAuth = ({ onClose }) => {
  const [clientId] = useState('1000.QE7XXKA8AHEP55JX6B2U6F6FA7P97N'); // Your Client ID
  const [redirectUri] = useState('https://app.insightai.in/zoho-access'); // Your Redirect URI
  // const [redirectUri] = useState('https://grand-prawn-purely.ngrok-free.app/zoho-access'); // Your Redirect URI
  const [state] = useState('testing'); // State parameter for security
  const [scope, setScope] = useState(''); // Scopes needed

  useEffect(() => {
    // Set the required scopes for Zoho Books
    const requiredScopes = [
      'ZohoBooks.fullaccess.READ', // Full access to Zoho Books Read

    //   For more granular access, you can use the following scopes
      'ZohoBooks.invoices.READ',
    //   'ZohoBooks.organizations.READ',
    //   'ZohoBooks.settings.READ',

    //   'ZohoBooks.customerpayments.READ',
    //   'ZohoBooks.vendorpayments.READ',

    //   'ZohoBooks.purchaseorders.READ',
    //   'ZohoBooks.salesorders.READ',
    //   'ZohoBooks.expenses.READ',
    //   'ZohoBooks.banktransactions.READ',
    //   'ZohoBooks.estimates.READ',
    //   'ZohoBooks.bills.READ',
    ];

    // Prepare the scope string
    const scopeString = requiredScopes.join(',');

    // Set the scope state
    setScope(scopeString);

    // Prepare the authentication URL
    if (clientId && redirectUri && scopeString) {
      const url = new URL('https://accounts.zoho.in/oauth/v2/auth');
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('client_id', clientId);
      url.searchParams.append('redirect_uri', redirectUri);
      url.searchParams.append('state', state);
      url.searchParams.append('scope', scopeString);
      url.searchParams.append('access_type', 'offline');

      // Log the URL for debugging (optional)
      console.log(url.toString());

      // Redirect to the constructed URL
      window.location.href = url.toString();
    } else {
      showErrorToast('Client ID, Redirect URI, or Scopes are missing');
    }
  }, [clientId, redirectUri, scope, state]);

  return (
    <div className="zoho-auth-container">
      {/* Full-screen loader with overlay */}
      <div className="zoho-auth-overlay">
        <Modal isOpen centered backdrop="static">
          <ModalHeader>Connect your Zoho Account</ModalHeader>
          <ModalBody className="text-center">
            <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader spinner */}
            <div className="mt-3">Preparing the authentication process...</div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default ZohoBooksAuth;