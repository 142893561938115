import { useState, useEffect } from "react";
import "./index.scss";
import { Table, Button, UncontrolledTooltip } from 'reactstrap';
import { PieChart, Download } from "react-feather";
import { postUserFeedbackHandler } from "../../../services/dbConnect";
import { ClipLoader } from "react-spinners";


const ResponseActions = ({ session, table }) => {
    const [feedbackMessage, setFeedbackMessage] = useState('Was this useful?');
    const [feedbackLoader, setFeedbackLoader] = useState(false);
    const [rSelected, setRSelected] = useState(null);
    const [visible, setVisible] = useState(true);


    // State variables to store extracted paragraph and table data
    const [tableData, setTableData] = useState({ headers: [], rows: [] });

    // Feedback Button handler
    const handleButtonClick = (response) => {
        setRSelected(response);
        setFeedbackMessage(response === 1 ? 'Thank you for your feedback!' : 'We appreciate your feedback!');
        const payload = { session, feedback: response === 1 ? "True" : "False" };
        setFeedbackLoader(true)
        postUserFeedbackHandler(payload)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            });
        setTimeout(() => setVisible(false), 2000);
    };

    useEffect(() => {
        if (rSelected !== null) {
            console.log(`User response: ${rSelected === 1 ? 'Useful' : 'Not useful'}`);
        }
    }, [rSelected]);

    //Function for csv download
    const handleCSVDownload = () => {
        const { headers, rows } = tableData;

        if (rows.length === 0 || headers.length === 0) {
            return;
        }

        // Convert the headers and rows into CSV format
        const csvContent = [
            headers.join(','), // Join headers with commas
            ...rows.map(row => row.join(',')) // Join each row with commas
        ].join('\n'); // New line after each row

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a download link and trigger download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'tableData.csv');
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div className="response-action">
                {/* <div className="response-actions__cta">
                    <Button outline color="primary" id="download" onClick={handleCSVDownload}>
                        <Download />
                    </Button>
                    <UncontrolledTooltip
                        placement='bottom'
                        target='download'
                    >
                        Download as CSV
                    </UncontrolledTooltip>
                </div> */}

                <div className="response-action__feedback">
                    <span style={{ marginRight: '10px', fontSize: '12px' }}>{feedbackMessage}</span>
                    {
                        visible && (
                            feedbackLoader ?
                                <span><ClipLoader size={16} color="#44ace4" /></span>
                                :
                                <>
                                    <Button color="primary" outline onClick={() => handleButtonClick(1)} active={rSelected === 1}>
                                        👍
                                    </Button>
                                    <Button color="primary" outline onClick={() => handleButtonClick(2)} active={rSelected === 2}>
                                        👎
                                    </Button>
                                </>
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default ResponseActions;