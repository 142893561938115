import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
  uploadeUWStatement: "analytics/lending_statement/process_lending_statement/?ref={0}",
  fetchStatementAnalysis: "analytics/lending_statement/fetch_analysis_data/?id={0}&data_type={1}",
  fetchMasterLedgerData: "analytics/lending_statement/{0}",
  listAllLendingStatements: "analytics/lending_statement/fetch_all_statement/",

  // Conversation
  getLedgerConversation: "analytics/lending_conversation/get_document_conversation/?statement_id={0}",
  fetchResponseToLedgerQuery: "analytics/lending_conversation/fetch_statement_query_response/",
};

export const uploadUWStatementHandler = (payload, socketId) => {
  return REQUESTS.postFile(API_ROUTES.uploadeUWStatement.format(socketId), localStorage.getItem("token"), payload);
}

export const fetchlendingStatementAnalysisHandler = (statementId, analysisType) => {
  return REQUESTS.get(API_ROUTES.fetchStatementAnalysis.format(statementId, analysisType), localStorage.getItem("token"));
}

export const fetchMasterLedgerHandler = (statementId) => {
  return REQUESTS.get(API_ROUTES.fetchMasterLedgerData.format(statementId), localStorage.getItem("token"));
}

export const listAllLendingStatementsHandler = () => {
  return REQUESTS.get(API_ROUTES.listAllLendingStatements, localStorage.getItem("token"));
}

// Ledger Query Functionalities
export const fetchLendingConversationHandler = (statementId) => {
  return REQUESTS.get(API_ROUTES.getLedgerConversation.format(statementId), localStorage.getItem("token"));
}

export const fetchResponseToLendingQueryHandler = (payload) => {
  return REQUESTS.post(API_ROUTES.fetchResponseToLedgerQuery, localStorage.getItem("token"), payload);
}