import React, { useState, useRef, useEffect } from 'react';
import { ClipLoader } from "react-spinners";
import { CheckCircle } from 'react-feather';

import './index.scss';

const CustomTabs = ({
    tabs,
    onTabSelect,
    // tabStatus,
    verticalJustify,
    customStyle = {},
    customTabStyle = {},
    customSelectedTabStyle = {},
    isLoading = [],
    isCompleted = []
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (onTabSelect) {
            onTabSelect(index);
        }
    };
    const getTabContainerStyle = () => {
        switch (verticalJustify) {
            case 'full':
                return { width: '100%' };
            case 'scroll':
                return { overflowX: 'auto', width: '100%' };
            case 'left':
                return { justifyContent: 'flex-start' };
            case 'right':
                return { justifyContent: 'flex-end' };
            default:
                return {};
        }
    };
    return (
        <div className='custom-tabs' style={{ display: 'flex',  marginBottom: '2rem', borderTop: '1px solid rgb(216, 214, 222)', borderBottom: '1px solid rgb(216, 214, 222)', ...getTabContainerStyle('left'), ...customStyle }}>
            {tabs && tabs.map((tab, index) => (
                <div
                    key={index}
                    onClick={() => handleTabClick(index)}
                    style={{
                        padding: '11px 20px',
                        cursor: 'pointer',
                        color: activeTab === index ? '#0C6980' : '#6e6b7b',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderBottom: activeTab === index ? '1px solid #0C6980' : '',
                        width: '100%',
                        textAlign: 'center',
                        ...customTabStyle,
                        ...(activeTab === index ? customSelectedTabStyle : {}),
                    }}
                    className='custom-tabs__tab'
                >
                    {tab}
                    {/* {
                        isLoading && isLoading[tab] &&(
                            <ClipLoader size={20} color={activeTab === index ? "#ffffff" : "#0C6980"} />
    
                        )
                    } */}

                    {
                        isLoading[index] ? (
                            <ClipLoader size={20} color={"#0C6980"} />
                        ) : isCompleted[index] && (
                            <CheckCircle size={20} color={"#28c76f"} style={{ marginLeft: '10px' }} />
                        )
                    }



                </div>
            ))}
        </div>
    );
};

export default CustomTabs;