import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import { Card, Table, Button, Input, Spinner } from "reactstrap";
import PageHeader from "../../../@core/components/PageHeader";
import { connect } from "react-redux";
import { Search, Edit } from "react-feather";
import { loadAllSessionsHandler } from "../../../services/documentQnA";
import { statusCode } from "../../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast } from "../../../utility/helper";

import CustomeTable from "../../components/CustomTable";
import moment from "moment";

const PreviousChatSessions = (props) => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadAllSessionsHandler().
            then((response) => {
                if (response.status == statusCode.HTTP_200_OK) {
                    // Sort the response in the reverse order of creation date
                    response.data.session.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                    setSessions(response.data.session);
                    setFilteredSessions(response.data.session);
                    showSuccessToast("Sessions loaded successfully");
                } else {
                    setSessions([]);
                    setFilteredSessions([]);
                    // showErrorToast("Failed to load sessions");
                }
                setLoading(false);
            })
            .catch((error) => {
                setSessions([]);
                setFilteredSessions([]);
                setLoading(false);
                // showErrorToast("Failed to load sessions");
            });
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSessions, setFilteredSessions] = useState(sessions);
    const [editSessionId, setEditSessionId] = useState(null);
    const [editedName, setEditedName] = useState('');

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = sessions.filter(session =>
            session.name.toLowerCase().includes(value) ||
            session.date.includes(value)
        );
        setFilteredSessions(filtered);
    };

    const handleRowClick = (id) => {
        // This function redirects to a URL with the session ID
        window.location.href = `/wizDoc/${id}`;
    };

    // Functions to edit session name
    const startEditing = (id, name) => {
        setEditSessionId(id);
        setEditedName(name);
    };

    const stopEditing = () => {
        setEditSessionId(null);
        setEditedName('');
    };

    const saveChanges = (id) => {
        const updatedSessions = sessions.map(session =>
            session.id === id ? { ...session, name: editedName } : session
        );
        setSessions(updatedSessions);
        stopEditing();
    };

    //columns
    const allowedColumns = ['serialNo', 'name', 'created_at'];

    return (
        <div>
            <PageHeader
                pageName={"Previous Sessions"}
                backButtonEnabled={false}
            />
            <div className="blogPreviewContainer">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                        width: '100%',
                    }}>
                        <h3 className="mobile-hidden">List of previous sessions</h3>
                        <div style={{
                            position: 'relative',
                            width: '300px'
                        }}>
                            <Search
                                size={18}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '15px',
                                    transform: 'translateY(-50%)'
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search sessions..."
                                style={{
                                    padding: '8px 16px 8px 20px', // Left padding is increased for icon
                                    fontSize: '13px',
                                    width: '100%',
                                    borderRadius: '20px',
                                    border: '1px solid #ccc' // Adjust color as needed
                                }}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    {/* Table data */}
                    <div style={{ height: 'calc(85vh - 80px)', overflow: 'auto', width: '100%' }}>
                        <CustomeTable
                            loading ={loading}
                            data={filteredSessions || []}
                            allowedColumns ={allowedColumns}
                            errResponse='No chat sessions found.'
                            loadingText='Loading your chat session'
                            singleRedirectionLink = '/wizDoc/'
                            redirectionLink="/wizDoc/new-session"
                            redirectTitle="Click here to create a new session."
                        />

                        
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    // fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
    return {
        organisationData: organisationReducer.organisationData
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousChatSessions);