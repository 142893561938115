import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
  // Session APIs
  uploadFile: "analytics/session/load_session_document/",
  fetchResponse: "analytics/session/fetch_query_answer/",
  loadAllSessions: "analytics/session/fetch_all_sessions/",
  updateSession: "analytics/session/session-name/?session_id=81",


  // Document APIs
  loadSessionDocument: "analytics/document/fetch_documents_by_session?session_id={0}",
  deleteDocument: "analytics/session/delete-document/?session_id={0}&document_id={1}",

  // Message APIs
  loadSessionMessagea: "analytics/messages/fetch_chat_by_session?session_id={0}",

  //Storage APIs
  loadStorageDetails: "analytics/session/storage-details?session_id={0}",
};

export const loadAllSessionsHandler = () => {
  return REQUESTS.get(API_ROUTES.loadAllSessions, localStorage.getItem("token"));
}

export const uploadDataFile = (payload) => {
  return REQUESTS.postFile(API_ROUTES.uploadFile, localStorage.getItem("token"), payload);
};

export const loadSessionDocumentHandler = (sessionId) => {
  return REQUESTS.get(API_ROUTES.loadSessionDocument.format(sessionId), localStorage.getItem("token"));
}

export const loadSessionMessagesHandler = (sessionId) => {
  return REQUESTS.get(API_ROUTES.loadSessionMessagea.format(sessionId), localStorage.getItem("token"));
}

export const fetchQueryResponseHandler = (payload) => {
  return REQUESTS.post(API_ROUTES.fetchResponse, localStorage.getItem("token"), payload);
}

export const deleteDocumentHandler = (sessionId, document_id) => {
  console.log(sessionId, document_id)
  return REQUESTS.delete(API_ROUTES.deleteDocument.format(sessionId, document_id), localStorage.getItem("token"));
}

export const storageDetailsHandler = (sessionId) => {  
  return REQUESTS.get(API_ROUTES.loadStorageDetails.format(sessionId), localStorage.getItem("token"));
}

export const updateSessionHandler = (sessionId, payload) => {  
  return REQUESTS.patch(API_ROUTES.updateSession.format(sessionId), localStorage.getItem("token"), payload);
}
