// ** React Imports
import { Outlet } from "react-router-dom";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import navigation from "@src/navigation/vertical";

const VerticalLayout = props => {
  return (
    <>
      {/* <VerificationBanner /> */}
      <Layout menuData={navigation} {...props}>
        <Outlet />
      </Layout>
    </>
  );
};

export default VerticalLayout;
