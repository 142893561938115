import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Card } from "reactstrap";
import { UploadCloud } from 'react-feather';
import PageHeader from "../../../../@core/components/PageHeader";

import ZohoBooksAuth from "../../../components/ZohoBooksAuth";

import ZohoLogo from "../../../../assets/logo/zoho_logo.png";
import TallyLogo from "../../../../assets/logo/tally_logo.png";
import PremiumIcon from "../../../../assets/icons/premium.png";
import SAPIcon from "../../../../assets/icons/sap.jpeg";
import UploadIcon from "../../../../assets/icons/upload_doc.png";

import DocUploadPanel from "./DocUploadPanel";
import "./index.scss";

const CashflowAnalysis = (props) => {
  const navigate = useNavigate();

  const [zohoAuthModal, setZohoAuthModal] = useState(false);

  const handleZoho = () => {
    // navigate('/zoho-access');
    setZohoAuthModal(true);
  };

  // Array of integrations with additional details
  const integrations = [
    {
      id: 1,
      name: "Zoho Books",
      description: "Connect with Zoho and get predictive analytics for your sales data.",
      icon: ZohoLogo,
      onClick: handleZoho,
    },
    {
      id: 4,
      name: "Upload Document",
      description: "Upload your financial documents and get detailed analysis.",
      icon: UploadIcon,
      onClick: () => console.log('Upload document clicked'),
    },
    {
      id: 2,
      name: "Tally",
      description: "Connect Tally and automate your financial statements effortlessly.",
      icon: TallyLogo,
      premium: true,
    },
    {
      id: 3,
      name: "SAP",
      description: "Integrate with SAP and get real-time insights into your business operations.",
      icon: SAPIcon,
      premium: true,
    },
  ];

  return (
    <Card>
      <PageHeader
        pageName={"Cashflow Analysis & Forecasting"}
        backButtonEnabled={true}
      />
      <div className='container-fluid vh-85 cashflow'>
        <div className='page-container no-scroll-bar' style={{ padding: '20px' }}>
          <div className="template-container-header">
            Connect your Books
          </div>

          <div className="template-container-items-list">
            {integrations.map((integration) => (
              <div
                key={integration.id}
                className={`template-container-item ${integration.premium ? 'premium' : ''}`}
                onClick={integration.onClick || null}
                style={{ cursor: integration.onClick ? 'pointer' : 'default' }}
              >
                <div className="image-container">
                  <img className="image-item" src={integration.icon} alt={integration.name} />
                </div>
                <div className="header">{integration.name}</div>
                <div className="description">{integration.description}</div>
                {integration.premium && (
                  <div className="overlay">
                    <button className="action-btn">
                      <img src={PremiumIcon} alt='/' /> Premium
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="template-container-header">
            Upload Documents
          </div>

          <div className="uploaded-documents-placeholder">
            <p>Your uploaded documents will appear here.</p>
          </div>

          {/* <DocUploadPanel /> */}
        </div>
      </div>

      {zohoAuthModal && (
        <ZohoBooksAuth onClose={() => console.log('Auth modal closed')} />
      )}
    </Card>
  );
};

export default CashflowAnalysis;
